import React, { useLayoutEffect, useEffect, useState, useRef, useContext, useCallback } from "react";
import { IconArrowLeft, IconInfoCircle, IconListSearch, IconSearch, IconSwitchVertical } from "@tabler/icons-react";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import Arrow, { DIRECTION } from 'react-arrows';
import { DateTime } from "luxon";

import { animatePageTransition, formatStatus, get_nsfw, hashCode, is_mobile, is_too_wide, log, openPage, parseAnimes, parseMangas, parseSeason, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import { UserContext } from "../Contexts/UserContext";
import { database } from "../Database";
import { IconInfoSquareRounded } from "@tabler/icons-react";
import Image from "../Components/Image";

function AnimeView({ isAnime, data, onClick }) {
    const [status, setStatus] = useState(null);
    const [color, setColor] = useState(null);

    useEffect(() => {
        //log(data);
        (async () => {
            if (data) {
                if (isAnime) {
                    const anime = await database.animes.get(data.id);
                    if (anime) {
                        const { background, text } = formatStatus(anime.status);
                        setColor(background);
                        setStatus({ text: text, episodes: `${anime.num_episodes_watched} / ${data.episodes} Episodes` });
                    }
                } else {
                    const anime = await database.mangas.get(data.id);
                    if (anime) {
                        /*const { background, text } = formatStatus(anime.status);
                        setColor(background);
                        setStatus({ text: text, episodes: `${anime.num_episodes_watched} / ${data.episodes} Episodes` });*/
                    }
                }
            }
        })();
    }, [data]);

    if (data) {
        return (
            <div
                className="flex items-center w-full h-full p-4 bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow hover:shadow-lg md:rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                <Image
                    className="flex-none h-full w-22 object-cover bg-gray-200 dark:bg-gray-700 rounded-md"
                    style={{ width: "90px" }}
                    src={data.cover}
                    alt="cover"
                />
                <div className="flex-1 flex flex-col ml-4">
                    <p className="font-bold text-sm sm:text-base md:text-sm lg:text-base text-justifye text-ellipsis-2 text-wrap-balance">{data.title}</p>
                    <p className="mt-1 text-xs sm:text-sm md:text-xs lg:text-sm text-gray-500 dark:text-gray-400 truncate">
                        {data.subtitle}
                        {status && <>⠀<span className="inline-block font-medium text-white rounded-md shine" style={{ backgroundColor: color }}>⠀{status.text}⠀</span></>}
                    </p>
                </div>
            </div>
        );
    } else {
        return (<></>);
    }
}

function ListView({ isAnime, query }) {
    const navigate = useNavigate();
    const location = useLocation();

    const userContext = useContext(UserContext);

    const [width, setWidth] = useState(is_too_wide() ? 1280 : window.innerWidth);
    const [columns, setColumns] = useState(is_mobile() ? 1 : 2);
    const [animes, setAnimes] = useState(null);
    const [error, setError] = useState(null);

    const parentRef = useRef();

    const rowVirtualizer = useVirtualizer({
        count: animes ? (Math.floor(animes.length / columns) + (animes.length % columns)) : 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 178,
        enableSmoothScroll: true,
        paddingStart: 8,
        paddingEnd: 8,
        overscan: 12,
    })

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columns,
        getScrollElement: () => parentRef.current,
        estimateSize: () => {
            return ((width < 768 ? width : width / 2) - (width < 1280 ? width < 768 ? 0 : 8 : 0));
        }
    })

    useEffect(() => {
        function handleResize() {
            let width = window.innerWidth;
            width = width >= 1280 ? 1280 : width;

            setColumns(width < 768 ? 1 : 2);

            setWidth(width);

            rowVirtualizer.measure();
            columnVirtualizer.measure();
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (query) {
            const urlSearchParams = new URLSearchParams();
            urlSearchParams.append("query", query);
            urlSearchParams.append("nsfw", get_nsfw());
            urlSearchParams.append("limit", 100);

            const path = `/api/${isAnime ? "anime" : "manga"}/search?${urlSearchParams}`;
            const data = sessionStorage.getItem(path);

            if (data) {
                const json = JSON.parse(data);
                setAnimes(parseAnimes(json));
            } else {
                fetch(path)
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((data) => {
                                sessionStorage.setItem(path, JSON.stringify(data))
                                setAnimes(isAnime ? parseAnimes(data) : parseMangas(data));
                            });
                        } else {
                            if (response.status === 400) {
                                //setStatus(STATUS_ERROR_SERVER);
                            } else if (response.status === 401) {
                                userContext.changeUserData(null);
                            } else if (response.status === 403) {
                                //setStatus(STATUS_ERROR_SERVER);
                            } else if (response.status === 404) {
                                //setStatus(STATUS_ERROR_SERVER);
                            } else if (response.status === 500) {
                                //setError(<ServerError />);
                            } else {
                                //setError(<UnknownError />);
                            }
                        }
                    });
            }
        } else {
            setAnimes(null);
        }
    }, [isAnime, query]);

    useLayoutEffect(() => {
        if (animes && animes.length > 0) {
            const tempObject = JSON.parse(sessionStorage.getItem(location.pathname) || JSON.stringify({ scrollOffset: 0, key: getKey() }));
            if (tempObject.key === getKey()) {
                rowVirtualizer.scrollToOffset(tempObject.scrollOffset);
            }
            sessionStorage.removeItem(location.pathname);
        }
    }, [animes]);

    const getKey = () => {
        return hashCode(`${isAnime}${query}`);
    }

    function getIndex(virtualRow, virtualColumn) {
        return columns === 1 ? virtualRow.index : (virtualColumn.index % 2 ? virtualRow.index * 2 : virtualRow.index * 2 - 1) + 1
    }

    function getData(index) {
        return animes[index];
    }

    function onClick(index) {
        sessionStorage.setItem(location.pathname, JSON.stringify({
            isAnime: isAnime,
            query: query,
            scrollOffset: rowVirtualizer.scrollOffset,
            key: getKey()
        }));
        openPage(() => {
            navigate(isAnime ? `../anime/${index}` : `../manga/${index}`, { state: location.pathname });
        });
    }

    return (
        <div ref={parentRef} className="w-full h-full md:px-2 xl:px-0 overflow-auto">
            {error === null ?
                animes ?
                    animes.length === 0 ?
                        <div className="flex flex-col justify-center w-full h-full p-4">
                            <IconListSearch className="w-full h-36 text-blue-700 dark:text-blue-600" strokeWidth={1} />
                            <p className="font-bold text-lg text-center">No Result Found</p>
                            <p className="text-center text-gray-600 dark:text-gray-400">Please change your query to search again.</p>
                        </div>
                        :
                        <div className="relative max-w-screen-xl w-full h-full mx-auto" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                            {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                                <React.Fragment key={virtualRow.index}>
                                    {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
                                        <div
                                            key={virtualColumn.index}
                                            className={(columns === 1 ? "px-2-" : (virtualColumn.index % 2 ? "pl-0 xl:pl-2 xl:pr-0" : "xl:pl-0 pr-0 xl:pr-2")) + " px-2- md:px-2 xl:px-0 py-2"}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: `${virtualColumn.size}px`,
                                                height: `${virtualRow.size}px`,
                                                transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                                            }}
                                        >
                                            <AnimeView isAnime={isAnime} data={getData(getIndex(virtualRow, virtualColumn))} onClick={onClick} />
                                        </div>
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                    :
                    <div className="flex items-center max-w-screen-xl w-full h-full mx-auto">
                        <Loading />
                    </div>
                :
                { error }
            }
        </div>
    );
}

export default function Search() {
    const location = useLocation();

    const [isAnime, setIsAnime] = useState(true);
    const [query, setQuery] = useState();
    const ref = useRef();

    useLayoutEffect(() => {
        const tempObject = JSON.parse(sessionStorage.getItem(location.pathname));

        if (tempObject) {
            setIsAnime(tempObject.isAnime);
            setQuery(tempObject.query);

            ref.current.value = tempObject.query;
        } else {
            ref.current.focus();
        }

        function handleKeypress(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                submit();
            }
        }

        ref.current.addEventListener("keypress", handleKeypress);

        return () => { ref.current.removeEventListener("keypress", handleKeypress); }
    }, []);

    useEffect(() => {
        updateThemeMeta();
    }, []);

    /*useEffect(() => {
        if (query) {
            const urlSearchParams = new URLSearchParams();
            urlSearchParams.append("query", query);
            urlSearchParams.append("nsfw", get_nsfw());
            urlSearchParams.append("limit", 10);

            fetch(`/api/${isAnime ? "anime" : "manga"}/search?${urlSearchParams}`)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            const temp = [];
                            for (const item of data) {
                                temp.push(item);
                            }
                            setData(temp);
                        });
                    } else {
                        if (response.status === 400) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 401) {
                            //userContext.changeUserData(null);
                        } else if (response.status === 403) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 404) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 500) {
                            //setError(<ServerError />);
                        } else {
                            //setError(<UnknownError />);
                        }
                    }
                })
        } else {
            setData([]);
        }
    }, [query]);*/

    const submit = () => {
        setQuery(ref.current.value);
        ref.current.blur();
    }

    return (
        <div className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <Link to={"../"} className="flex-none block md:hidden text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800">
                        <IconArrowLeft className="w-14 h-14 p-4" />
                    </Link>
                    <h2 className="hidden md:block flex-1 pl-4 xl:pl-0 font-bold text-xl4">Search</h2>
                    <div className="w-32 mr-2 rounded-md relative hidden md:flex items-center h-10 pl-4 pr-1.5 text-white bg-blue-600 border border-blue-600 cursor-pointer"
                        onClick={() => { setIsAnime(!isAnime) }}>
                        <p className="flex-1 font-medium">{isAnime ? "Manga" : "Anime"}</p>
                        <IconSwitchVertical className="w-10 h-10 p-2.5" />
                    </div>
                    <div className="relative flex flex-1 md:flex-none md:w-64 h-full md:h-10 mr-4 xl:mr-0 text-gray-600 dark:text-gray-400">
                        <input ref={ref} type="search" className="flex-1 w-full h-full px-0 md:px-4 font-medium text-white md:text-gray-600 dark:md:text-white placeholder:text-blue-300 md:placeholder:text-gray-400 bg-blue-600 md:bg-white dark:md:bg-gray-900 md:border-2 border-gray-200 dark:border-gray-700 hover:border-blue-600 focus:border-blue-600 dark:hover:border-blue-600 dark:focus:border-blue-600 md:rounded-md outline-none" placeholder={isAnime ? "Anime Search" : "Manga Search"} />
                        <IconSearch onClick={submit} className="absolute right-0 hidden md:block flex-none w-10 h-full p-2.5 mr-2" />
                    </div>
                    <IconSwitchVertical onClick={() => { setIsAnime(!isAnime) }} className="flex-none block md:hidden w-14 h-14 p-4 text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800" />
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full mx-auto">
                    <div className="flex items-center h-full">
                        {query ?
                            <ListView key={`${isAnime}${query}`} isAnime={isAnime} query={query} />
                            :
                            <div className="flex flex-col justify-center w-full h-full p-4">
                                <IconInfoSquareRounded className="w-full h-36 text-blue-700 dark:text-blue-600" strokeWidth={1} />
                                <p className="font-bold text-lg text-center">Enter Your Query</p>
                                <p className="text-center text-gray-600 dark:text-gray-400">Please enter your query to search.</p>
                                {/* <Arrow className="arrow" from={{ direction: DIRECTION.TOP, node: () => ref_2.current, translation: [-0.15, -1] }} to={{ direction: DIRECTION.BOTTOM, node: () => ref.current, translation: [0.2, 1] }} /> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}
