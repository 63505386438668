import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    forwardRef,
    useState,
    useMemo
} from "react";
import { PlayIcon as PlayIconSolid } from "@heroicons/react/24/solid";
import { IconApps, IconCaretLeft, IconCaretRight, IconChevronLeft, IconChevronRight, IconDownload, IconInfoCircle, IconInfoSquareRounded, IconInfoTriangle, IconNews, IconQuestionMark, IconSearch, IconServer, IconServer2, IconSpeakerphone, IconWifiOff } from "@tabler/icons-react";

import { UserContext } from "../Contexts/UserContext";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../Database";
import { get_nsfw, get_year_season, log, openPage, parseAnimes, updateThemeMeta } from "../Helpers/Utils";
import AnimeItem from "../Components/AnimeItem";
import ServerError from "../Components/ServerError";
import UnknownError from "../Components/UnknownError";
import NetworkError from "../Components/NetworkError";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { DateTime } from "luxon";
import Image from "../Components/Image";
import MyanimelistError from "../Components/MyanimelistError";

function Video({ data }) {
    const navigate = useNavigate();

    const onClick = () => {
        openPage(() => {
            navigate(`../anime/${data.anime_id}`);
        });
    }

    if (data) {
        return (
            <div className="w-60 snap-center scroll-mx-6 shrink-0 cursor-pointer"
                style={{ width: "232px" }}>
                <div className="relative"
                    onClick={() => {
                        window.open(`https://www.youtube.com/watch?v=${data.youtube_id}`, "_newtab");
                    }}>
                    <Image
                        className="shrink-0 w-full h-32 bg-gray-200 dark:bg-gray-700 rounded-md"
                        src={`https://img.youtube.com/vi/${data.youtube_id}/mqdefault.jpg`}
                        alt="cover"
                        draggable="false"
                    />
                    <PlayIconSolid className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 w-10 h-10 p-3 text-black bg-white/40 backdrop-blur-sm rounded-full" />
                </div>
                <div onClick={onClick} className="flex items-center mt-2">
                    {/* <div className="flex-none w-0.5 h-8 mr-2 bg-blue-600 rounded-full"></div> */}
                    <div className="w-full mt-1">
                        <p className="font-bold text-sm truncate">{data.title}</p>
                        <p className="font-medium text-xs text-gray-500 dark:text-gray-400 truncate">{data.subtitle}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-60 snap-center scroll-mx-6 shrink-0 cursor-pointer" style={{ width: "232px" }}>
                <div className="w-full h-32 rounded-md bg-gray-200 dark:bg-gray-700 shimmer dark:shimmer" />
                <div className="flex items-center mt-2">
                    {/* <div className="flex-none w-0.5 h-8 mr-2 bg-gray-200 dark:bg-gray-700 rounded-full shimmer dark:shimmer"></div> */}
                    <div className="w-full mt-1">
                        <p className="h-4 mb-1 font-bold text-xs text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                        <p className="w-2/3 h-3 mb-1 font-bold text-xs text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                    </div>
                </div>
            </div>
        );
    }
}

function Videos({ data }) {
    const ref = useRef();

    function previous() {
        ref.current.scrollBy({
            left: -1,
            behavior: 'smooth'
        });
    }

    function next() {
        ref.current.scrollBy({
            left: 1,
            behavior: 'smooth'
        });
    }

    return (
        <div className="relative group/item">
            <div ref={ref} className="relative w-full flex px-4 pb-2 gap-4 snap-x snap-mandatory overflow-x-auto">
                {data.map((item, index) => (
                    <Video key={index} data={item} />
                ))}
            </div>
            <div onClick={() => { previous() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:translate-x-2 absolute top-16 left-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretLeft className="w-full h-full p-1 fill-black" />
            </div>
            <div onClick={() => { next() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:-translate-x-2 absolute top-16 right-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretRight className="w-full h-full p-1 fill-black" />
            </div>
        </div >
    );
}

function Animes({ data }) {
    const ref = useRef();

    function previous() {
        ref.current.scrollBy({
            left: -1,
            behavior: 'smooth'
        });
    }

    function next() {
        ref.current.scrollBy({
            left: 1,
            behavior: 'smooth'
        });
    }

    return (
        <div className="relative group/item">
            <div ref={ref} className="relative w-full flex px-4 pb-2 gap-4 snap-x snap-mandatory overflow-x-auto">
                {data.map((item, index) => (
                    <AnimeItem key={index} data={item} />
                ))}
            </div>
            <div onClick={() => { previous() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:translate-x-2 absolute top-24 left-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretLeft className="w-full h-full p-1 fill-black" />
            </div>
            <div onClick={() => { next() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:-translate-x-2 absolute top-24 right-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretRight className="w-full h-full p-1 fill-black" />
            </div>
        </div>
    );
}

function Announcements({ data }) {
    const onClick = (id) => {
        window.open(`https://myanimelist.net/forum/?topicid=${id}`);
    }

    if (data[0]) {
        data.sort(function (a, b) {
            return DateTime.fromISO(b.created_at).diff(DateTime.fromISO(a.created_at), ["days"]);
        });

        return (
            <div className="grid md:grid-cols-2">
                {data.map((item, index) => (
                    <div key={index} onClick={() => onClick(item.id)} className="flex items-center w-full h-full p-4 bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow overflow-hidden z-10 cursor-pointer">
                        <IconNews className="w-10 h-10 mr-4" stroke={1.5} />
                        <div className="flex-1 flex flex-col">
                            <p className="font-bold text-justifye text-ellipsis-2 text-wrap-balance">{item.title}</p>
                            <p className="mt-1 font-medium text-sm text-gray-500 dark:text-gray-400 truncate">{item.created_by.name} - {DateTime.fromISO(item.created_at).toRelative()}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className="grid md:grid-cols-2">
                {data.map((item, index) => (
                    <div key={index} className="flex items-center w-full h-full p-4 bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow overflow-hidden z-10">
                        <div className="w-10 h-10 mr-4 bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer"></div>
                        <div className="flex-1 flex flex-col">
                            <p className="font-bold text-base text-justifye text-ellipsis-2 text-wrap-balance text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                            <p className="w-2/3 mt-1 text-sm text-gray-500 dark:text-gray-400 truncate text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default function Home() {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);

    const { userData, changeUserData } = useContext(UserContext);
    const [error, setError] = useState(null);
    const [trailers, setTrailers] = useState([null, null, null, null, null, null, null, null, null, null]);
    const [seasonal, setSeasonal] = useState([null, null, null, null, null, null, null, null, null, null]);
    const [animeSuggestions, setAnimeSuggestions] = useState([null, null, null, null, null, null, null, null, null, null]);
    const [mangaSuggestions, setMangaSuggestions] = useState([null, null, null, null, null, null, null, null, null, null]);
    const [announcements, setAnnouncements] = useState([null, null, null, null, null, null, null, null, null, null]);

    const [isInstalled, setIsInstalled] = useState(true);

    const season = useMemo(() => `${get_year_season().season} ${get_year_season().year}`, []);

    const checkIsInstalled = () => {
        if (window.installPromptEvent != null)
            setIsInstalled(navigator.standalone || window.matchMedia('(display-mode: standalone)').matches);
    }

    const path = `/api/home?nsfw=${get_nsfw()}`;

    useLayoutEffect(() => {
        const data = sessionStorage.getItem(path);
        if (data) {
            const json = JSON.parse(data);
            setTrailers(json["trailers"]);
            setSeasonal(parseAnimes(json["seasonal"]));
            setAnimeSuggestions(parseAnimes(json["animeSuggestions"]));
            setAnnouncements(json["announcements"]);
        }

        checkIsInstalled();
    }, []);

    /*useLayoutEffect(() => {
        if (!isInstalled) {
            ref.current.scrollBy(0, ref_2.current.offsetHeight + 16);
            ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [isInstalled]);*/

    useEffect(() => {
        const data = sessionStorage.getItem(path);
        if (!data) {
            fetch(path)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            //log();
                            //userContext.changeUserData(json["user"]);
                            setTrailers(json["trailers"]);
                            setSeasonal(parseAnimes(json["seasonal"]));
                            setAnimeSuggestions(parseAnimes(json["animeSuggestions"]));
                            setAnnouncements(json["announcements"]);
                            sessionStorage.setItem(path, JSON.stringify(json));
                        });
                    } else {
                        if (response.status === 401) {
                            userContext.changeUserData(null);
                        } else if (response.status === 500) {
                            setError(<ServerError />);
                        } else if (response.status === 502) {
                            setError(<MyanimelistError />);
                        } else {
                            setError(<UnknownError />);
                        }
                    }
                    //setStatus(true);
                })
                .catch(error => {
                    log(error);
                    setError(<NetworkError />);
                });
        }

        updateThemeMeta();

        setTimeout(() => {
            checkIsInstalled();
        }, 1000);

        const onAppinstalled = () => { setIsInstalled(true); }
        //if (!isInstalled)
        window.addEventListener('onAppinstalled', onAppinstalled);

        return () => {
            window.removeEventListener('onAppinstalled', onAppinstalled);
        }
    }, []);

    const onClick = (path) => {
        openPage(() => { navigate(path) });
    }

    const install = async () => {
        const installPromptEvent = window.installPromptEvent;
        installPromptEvent.prompt();
        //const { outcome } = await installPromptEvent.userChoice;
        //alert(outcome);
        //log(`User response to the install prompt: ${outcome}`);
    }

    const location = useLocation();

    return (
        <div className="flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <h2 className="flex-1 pl-4 xl:pl-0 font-bold text-xl4">Home</h2>
                    <Link to={"./search"} className="w-64 mr-4 xl:mr-0 rounded-md relative hidden md:flex items-center h-10 pl-4 pr-1.5 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700 hover:border-blue-600 dark:hover:border-blue-600 cursor-pointer">
                        <p className="flex-1 font-medium">Search</p>
                        <IconSearch className="w-10 h-10 p-2.5" />
                    </Link>
                    {!isInstalled && <IconDownload onClick={install} className="block md:hidden w-14 h-14 p-4" />}
                    <Link to={"./search"} className="block md:hidden">
                        <IconSearch className="w-14 h-14 p-4" />
                    </Link>
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full mx-auto">
                    {error ? (
                        error
                    ) : (
                        <>
                            {/* <LayoutGroup> */}
                            {/* <AnimatePresence> */}
                            {/* {!isInstalled && <div
                                initial={{ scaleY: 0, opacity: 0 }}
                                animate={{ scaleY: 1, opacity: 1 }}
                                className="md:hidden flex flex-col items-end p-4 mt-4 bg-white dark:bg-gray-800 shadow xl:rounded-md">
                                <div className="flex w-full items-cente">
                                    <IconDownload className="flex-0 w-6 h-6 mr-4" />
                                    <div className="flex-1">
                                        <p className="mb-2 font-bold">Recommendation</p>
                                        <p className="font-medium text-sm text-justify text-gray-600 dark:text-gray-400">Please install and use AniDex as a progressive web app to get the best user experience.</p>
                                    </div>
                                </div>
                                <button onClick={install} className="flex-0 w-32 py-2 mt-4 text-white bg-blue-600 rounded-md">Install</button>
                            </div>} */}
                            {/* </AnimatePresence> */}
                            {/* <div className="flex flex-col md:flex-row items-center md:px-4 mt-4 text-justify font-bold bg-yellow-50 dark:bg-yellow-900 shadow xl:rounded-md">
                                <IconInfoSquareRounded className="w-full md:w-32 h-16 md:h-32 p-4 md:mr-4 md:text-yellow-700 dark:md:text-yellow-50 text-yellow-50 bg-yellow-700 md:bg-transparent" />
                                <p className="p-4 text-yellow-700 dark:text-yellow-50">Hi, Due to the lack of active users (only 1-3), AniDex on <span className="px-1 bg-yellow-700 text-yellow-50 rounded-md">anidex.app</span> will stop working from June 26, 2024. You can continue using it on <span className="px-1 bg-yellow-700 text-yellow-50 rounded-md">anidex.pages.dev</span> after this date. If you encounter any issues accessing AniDex on <span className="px-1 bg-yellow-700 text-yellow-50 rounded-md">anidex.pages.dev</span> after June 26, 2024, please wait a few days, and I will check and resolve the problem. Make sure to save the link <span className="px-1 bg-yellow-700 text-yellow-50 rounded-md">anidex.pages.dev</span> for easy access in the future.</p>
                            </div> */}
                            <div className="pb-2 mt-4 bg-white dark:bg-gray-800 shadow xl:rounded-md">
                                <div className="flex mb-4 border-b dark:border-gray-700">
                                    <p className="flex-1 px-4 py-2 my-auto font-bold">Latest Trailers</p>
                                    <div onClick={() => { onClick("./trailers") }} className="px-4 py-2 my-2 font-bold text-blue-600 cursor-pointer">More</div>
                                </div>
                                <Videos data={trailers} />
                            </div>
                            <div className="pb-2 mt-4 bg-white dark:bg-gray-800 shadow xl:rounded-md">
                                <div className="flex mb-4 border-b dark:border-gray-700">
                                    <p className="flex-1 px-4 py-2 my-auto font-bold">{season} Animes</p>
                                    <Link to={"./season"} className="px-4 py-2 my-2 font-bold text-blue-600 cursor-pointer">More</Link>
                                </div>
                                <Animes data={seasonal} />
                            </div>
                            <div className="pb-2 mt-4 bg-white dark:bg-gray-800 shadow xl:rounded-md">
                                <div className="flex mb-4 border-b dark:border-gray-700">
                                    <p className="flex-1 px-4 py-2 my-auto font-bold">My Anime Suggestions</p>
                                    <div onClick={() => { onClick("./anime-suggestions") }} className="px-4 py-2 my-2 font-bold text-blue-600 cursor-pointer">More</div>
                                </div>
                                <Animes data={animeSuggestions} />
                            </div>
                            <div className="mt-4 bg-white dark:bg-gray-800 shadow xl:rounded-md">
                                <div className="flex border-b dark:border-gray-700">
                                    <p className="flex-1 px-4 py-2 my-auto font-bold">Updates & Announcements</p>
                                    <div onClick={() => { onClick("./announcements") }} className="px-4 py-2 my-2 font-bold text-blue-600 cursor-pointer">More</div>
                                </div>
                                <Announcements data={announcements} />
                            </div>
                            {/* <div className="pb-2 mt-4 bg-white shadow xl:rounded-md">
                                <div className="flex mb-4 border-b">
                                    <p className="flex-1 px-4 pt-2 pb-1 my-auto font-bold">My Manga Suggestions</p>
                                    <Link to={"./manga-suggestions"} className="px-4 py-2 my-2 font-bold text-blue-600">More</Link>
                                </div>
                                <Animes data={MangaSuggestions}></Animes>
                            </div> */}
                            <div className="h-4"></div>
                            {/* </LayoutGroup> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
