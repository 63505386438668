import { useState } from "react";

export default function Chips({ chips, checked = [], onChange = () => {} }) {
    /*const [selected, setSelected] = useState(checked);

    const _onChange = (event) => {
        const target = event.target;

        if (!selected.includes(target.value) && target.checked) {
            selected.push(target.value);
        } else {
            selected.splice(selected.indexOf(target.value), 1);
        }

        onChange(selected);
    }*/

    const items = chips.map((chip, i) => (
        <label key={chip} htmlFor={chip} className="flex-auto p-1">
            <input type="checkbox" onChange={onChange} id={chip} value={chip} className="sr-only peer/chip" checked={checked.includes(chip)} />
            <span className="inline-block w-full px-2 py-1 text-sm text-center text-gray-900 peer-checked/chip:text-white bg-gray-100 peer-checked/chip:bg-blue-600 border border-gray-200 peer-checked/chip:border-blue-600 rounded-md cursor-pointer">{chip}</span>
        </label>
    ))

    return (
        <div className="flex flex-wrap justify-stretch -m-1 font-medium">{items}</div>
    );
}