import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    forwardRef,
    useState,
    useMemo,
    memo,
    useCallback
} from "react";
import { motion, LayoutGroup, useAnimation, AnimatePresence, useScroll, useTransform } from "framer-motion";
import { IconLayoutList, IconLayoutGrid, IconChevronLeft, IconChevronRight, IconArrowBadgeRight, IconChevronsLeft, IconChevronsRight, IconFilter, IconAdjustments, IconBell, IconX, IconSettings2, IconSettings, IconCode, IconBackhoe, IconBarrierBlock, IconMessage, IconSpeakerphone, IconNews } from "@tabler/icons-react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";

import { formatStatus, get_nsfw, get_year_season, hashCode, is_mobile, is_too_wide, openPage, parseAnimes, parseSeason, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";
import { database, db } from "../Database";
import { Constants } from "../Helpers/Constants";
import Select from "../Components/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import Switch from "../Components/Switch";
import Dialog from "../Components/Dialog";
import Chips from "../Components/Chips";
import { IconCodeCircle } from "@tabler/icons-react";

function TopicView({ data, onClick }) {
    if (data) {
        return (
            <div
                //initial={{ opacity: 0, scale: 1 }}
                //whileInView={{ opacity: 1, scale: 1 }}
                className="flex items-center w-full h-full p-4 bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow hover:shadow-lg md:rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                {/* <img
                    className="flex-none h-full w-22 object-cover bg-gray-200 rounde"
                    style={{ width: "90px" }}
                    src={data.cover}
                    alt="cover"
                /> */}
                <IconNews className="w-10 h-10 mr-4" stroke={1.5} />
                <div className="flex-1 flex flex-col">
                    <p className="font-bold text-sm sm:text-base md:text-sm lg:text-base text-justifye text-ellipsis-2 text-wrap-balance">{data.title}</p>
                    <p className="mt-1 text-xs sm:text-sm md:text-xs lg:text-sm text-gray-500 dark:text-gray-400 truncate">{data.created_by.name} - {DateTime.fromISO(data.created_at).toRelative()}</p>
                </div>
            </div>
        );
    } else {
        return (<></>);
    }
}

function ListView({ }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [width, setWidth] = useState(is_too_wide() ? 1280 : window.innerWidth);
    const [columns, setColumns] = useState(is_mobile() ? 1 : 2);
    const [animesRaw, setAnimesRaw] = useState([]);
    const [animes, setAnimes] = useState([]);
    const [error, setError] = useState(null);

    const parentRef = useRef();

    const SIZE_HEADING = 58 + 16;
    const SIZE_ITEM = 146;

    const rowVirtualizer = useVirtualizer({
        count: animes ? (Math.floor(animes.length / columns) + (animes.length % columns)) : 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 104,
        //estimateSize: () => 146,
        enableSmoothScroll: true,
        paddingStart: 8,
        paddingEnd: 8,
        overscan: 12,
    })

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columns,
        getScrollElement: () => parentRef.current,
        estimateSize: () => {
            return ((width < 768 ? width : width / 2) - (width < 1280 ? width < 768 ? 0 : 8 : 0));
        }
    })

    useEffect(() => {
        function handleResize() {
            let width = window.innerWidth;
            width = width >= 1280 ? 1280 : width;

            setColumns(width < 768 ? 1 : 2);

            setWidth(width);

            rowVirtualizer.measure();
            columnVirtualizer.measure();
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useLayoutEffect(() => {
        const path = `/api/forum`;
        const data = sessionStorage.getItem(path);
        if (data) {
            const json = JSON.parse(data);
            json.sort(function (a, b) {
                return DateTime.fromISO(b.created_at).diff(DateTime.fromISO(a.created_at), ["days"]);
            });
            setAnimes(json);
        } else {
            fetch(path)
                .then((response) => response.json())
                .then((data) => {
                    setAnimes(data);
                    sessionStorage.setItem(path, JSON.stringify(data));
                });
        }
    }, [columns]);

    useLayoutEffect(() => {
        if (animes.length > 0) {
            const tempObject = JSON.parse(sessionStorage.getItem(location.pathname) || JSON.stringify({ scrollOffset: 0, key: getKey() }));
            if (tempObject.key === getKey()) {
                rowVirtualizer.scrollToOffset(tempObject.scrollOffset);
            }
            sessionStorage.removeItem(location.pathname);
        }
    }, [animes]);

    const getKey = () => {
        return hashCode(``);
    }

    function getIndex(virtualRow, virtualColumn) {
        return columns === 1 ? virtualRow.index : (virtualColumn.index % 2 ? virtualRow.index * 2 : virtualRow.index * 2 - 1) + 1
    }

    function getData(index) {
        return animes[index];
    }

    function onClick(index) {
        sessionStorage.setItem(location.pathname, JSON.stringify({
            scrollOffset: rowVirtualizer.scrollOffset,
            key: getKey()
        }));
        /*openPage(() => {
            navigate(`../anime/${index}`, { state: location.pathname });
        });*/
        window.open(`https://myanimelist.net/forum/?topicid=${index}`);
    }

    function getItem(virtualRow, virtualColumn) {
        const data = getData(getIndex(virtualRow, virtualColumn));
        const type = typeof data;

        if (type === "undefined") {
            return <></>;
        } else if (type === "string") {
            return <p className="md:pl-4 py-2 md:py-0 my-2 md:my-4 font-medium md:font-black text-center md:text-left text-white md:text-gray-900 md:dark:text-white bg-blue-600 dark:bg-blue-600 md:bg-transparent md:dark:bg-transparent md:border-l-4 border-blue-600 dark:border-blue-600 rounded-md md:rounded-none shadow md:shadow-none">{data}</p>;
        } else {
            return <TopicView data={data} onClick={onClick} />;
        }
    }

    return (
        <div ref={parentRef} className="w-full h-full md:px-2 xl:px-0 overflow-auto">
            {/* <div className="block md:hidden absolute inset-x-0 top-14 p-4 font-bold text-sm bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 shadow z-30">
                Movies
            </div> */}
            {error === null ?
                animes.length === 0 ?
                    <div className="flex items-center max-w-screen-xl w-full h-full mx-auto">
                        <Loading />
                    </div>
                    :
                    <div className="relative max-w-screen-xl w-full h-full mx-auto" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                            <React.Fragment key={virtualRow.index}>
                                {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
                                    <div
                                        key={virtualColumn.index}
                                        className={(columns === 1 ? "px-2-" : (virtualColumn.index % 2 ? "pl-0 xl:pl-2 xl:pr-0" : "xl:pl-0 pr-0 xl:pr-2")) + " px-2- md:px-2 xl:px-0 py-2"}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: `${virtualColumn.size}px`,
                                            height: `${virtualRow.size}px`,
                                            transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                                        }}
                                    >
                                        {getItem(virtualRow, virtualColumn)}
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                :
                { error }
            }
        </div>
    );
}

export default function Forum({ }) {
    return (
        <div className="flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <h2 className="flex-1 pl-4 xl:pl-0 font-bold text-xl4">Announcements</h2>
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                <ListView />
            </div>
            {/* <div className="flex-1 flex flex-col items-center justify-center overflow-y-scroll">
                <IconBarrierBlock className="w-full h-36 text-blue-700 dark:text-blue-600" strokeWidth={1} />
                <p className="font-bold text-lg text-center">Under Development</p>
                <p className="text-center text-gray-600 dark:text-gray-400">This Section is under development, Please come again later.</p>
            </div> */}
        </div >
    );
}
