import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';
//import runtime from 'serviceworker-webpack-plugin/lib/runtime';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from 'firebase/performance';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAtWrN7qJw1cdxzUqyflxmQwRXseveaU28",
  authDomain: "anidex-9e317.firebaseapp.com",
  projectId: "anidex-9e317",
  storageBucket: "anidex-9e317.appspot.com",
  messagingSenderId: "830750024414",
  appId: "1:830750024414:web:753409e7b018bc2489984f",
  measurementId: "G-LC9SCWGD1X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
window.messaging = getMessaging(app);

/*getToken(messaging, { vapidKey: "BP9u2ljq6AgHTK6sQJ8oz651nnNILolsXUj5Q-N5NN0Yf4y97X1VGVG4GaKjZtjQtqFwtEcQj_pX9ffKW_TuEWk" }).then((currentToken) => {
  if (currentToken) {
    console.log(currentToken);
    fetch(`/api/notifications/subscribe?token=${currentToken}&topic=all`).then((response) => {
      console.log(response.status);
    });
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});*/
/*onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
});*/

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js", { scope: "/pwa" });
  //const registration = runtime.register({ scope: "/pwa" });
}

// Prevent Right Click and Long Press
document.addEventListener('contextmenu', event => event.preventDefault());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);