import React, { useEffect, useLayoutEffect, useRef } from "react";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import { animatePageTransition, closePage, log, null24, null40, page_variants, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";
import { IconArrowLeft } from "@tabler/icons-react";
import { PlayIcon as PlayIconSolid } from "@heroicons/react/24/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ServerError from "../Components/ServerError";
import UnknownError from "../Components/UnknownError";
import NetworkError from "../Components/NetworkError";
import { useContext } from "react";
import { UserContext } from "../Contexts/UserContext";

function Trailer({ data, onClick }) {
    if (data) {
        return (
            <div className="w-full cursor-pointer sm:rounded-md bg-white dark:bg-gray-800 shadow hover:shadow-lg">
                <div className="relative m-4"
                    onClick={() => {
                        window.open(`https://www.youtube.com/watch?v=${data.youtube_id}`, "_newtab");
                    }}>
                    <img
                        className="object-cover w-full h-40 bg-gray-200 rounded-md"
                        src={`https://img.youtube.com/vi/${data.youtube_id}/mqdefault.jpg`}
                        alt="cover"
                        draggable="false"
                    />
                    <PlayIconSolid className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 w-10 h-10 p-3 text-black bg-white/40 backdrop-blur-sm rounded-full" />
                </div>
                <div onClick={() => { onClick(data.anime_id) }} className="block w-full px-4 pb-4 mt-">
                    <p className="font-bold text-sm- truncate">{data.title}</p>
                    <p className="font-medium text-sm text-gray-500 dark:text-gray-400 truncate">{data.subtitle}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-full cursor-pointer sm:rounded-md bg-white dark:bg-gray-800 shadow">
                <div className="h-40 m-4 rounded-md bg-gray-200 dark:bg-gray-700 shimmer dark:shimmer" />
                <div className="block px-4 pb-4">
                    <p className="h-5 mb-1 font-bold text-xs text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                    <p className="w-2/3 h-4 mb-1 font-bold text-xs text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                </div>
            </div>
        );
    }
}

export default function Trailers() {
    const navigate = useNavigate();
    const location = useLocation();

    const userContext = useContext(UserContext);

    const [trailers, setTrailers] = useState(null40);
    const [error, setError] = useState(null);
    const ref = useRef();

    useLayoutEffect(() => {
        const tempObject = JSON.parse(sessionStorage.getItem(location.pathname) || JSON.stringify({ scrollOffset: 0 }));
        ref.current.scrollTop = tempObject.scrollOffset;
        sessionStorage.removeItem(location.pathname);

        const path = "/api/trailers";
        const data = sessionStorage.getItem(path);
        if (data) {
            const json = JSON.parse(data);
            setTrailers(json);
        } else {
            fetch(path)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            //log();
                            setTrailers(json);
                            sessionStorage.setItem(path, JSON.stringify(json));
                        });
                    } else {
                        if (response.status === 400) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 401) {
                            userContext.changeUserData(null);
                        } else if (response.status === 403) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 404) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 500) {
                            //setError(<ServerError />);
                        } else {
                            //setError(<UnknownError />);
                        }
                    }
                    //setStatus(true);
                })
                .catch(error => {
                    log(error);
                    setError(<NetworkError />);
                });
        }

        updateThemeMeta();

        return () => {
            //animatePageTransition();
        }
    }, []);

    const onClick = (id) => {
        sessionStorage.setItem(location.pathname, JSON.stringify({
            scrollOffset: ref.current.scrollTop
        }));
        navigate(`../anime/${id}`, { state: location.pathname });
    }

    const back = () => {
        //closePage(() => {
        if (location.state) {
            navigate(-1);
        } else {
            navigate("/pwa");
        }
        //});
    }

    return (
        <motion.div
            key={location.pathname}
            variants={page_variants()}
            animate={"center"}
            exit={"exit"}
            className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <IconArrowLeft className="flex-none block md:hidden w-14 h-14 p-4" onClick={() => { back() }} />
                    <h2 className="flex-1 pl-0 md:pl-4 xl:pl-0 font-bold text-xl4">Trailers</h2>
                </div>
            </div>
            <div ref={ref} className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full mx-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 content-start w-full h-full px-0 sm:px-4 xl:px-0 py-4">
                        {trailers.map((item, index) => (
                            <Trailer key={index} data={item} onClick={onClick} />
                        ))}
                        <div className="h-px"></div>
                        <div className="hidden md:block h-px"></div>
                        <div className="hidden md:block h-px"></div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
