import { DateTime } from "luxon";
import preval from "preval.macro";

import { database } from "../Database";
import { Constants } from "./Constants";

export const updateThemeMeta = (color = Constants.PRIMARY_DARK_COLOR) => {
    const meta = document.querySelector('meta[name="theme-color"]');
    if (meta)
        meta.setAttribute('content', color);
}

export const getThemeMeta = () => {
    const meta = document.querySelector('meta[name="theme-color"]');
    return meta ? meta.getAttribute('content') : Constants.PRIMARY_DARK_COLOR;
}

export const updateAnime = (anime) => {
    /*console.log(JSON.stringify(anime));
    console.log(anime.num_episodes_watched);
    console.log(parseInt(anime.num_episodes_watched));*/

    database.animes.put({
        id: parseInt(anime.id),
        title: anime.title,
        picture: anime.picture,
        nsfw: anime.nsfw,
        media_type: anime.media_type,
        status: anime.status,
        score: parseFloat(anime.score),
        num_episodes_watched: parseInt(anime.num_episodes_watched),
        is_rewatching: anime.is_rewatching,
        updated_at: anime.updated_at,
        num_episodes: anime.num_episodes,
        season: anime.season,
        year: parseInt(anime.year),
    });
}

export const updateManga = (manga) => {
    console.log(JSON.stringify(manga));

    database.mangas.put({
        id: parseInt(manga.id),
        title: manga.title,
        picture: manga.picture,
        nsfw: manga.nsfw,
        media_type: manga.media_type,
        status: manga.status,
        score: parseFloat(manga.score),
        num_volumes_read: parseInt(manga.num_volumes_read),
        num_chapters_read: parseInt(manga.num_chapters_read),
        is_rewatching: manga.is_rewatching,
        updated_at: manga.updated_at,
        num_volumes: manga.num_volumes,
        num_chapters: manga.num_chapters,
        year: DateTime.fromISO(manga.year).year,
    });
}


/** Format Data Functions */

export const formatStatus = (status) => {
    let background = "#2563eb";
    let backgroundDark = "#1d4ed8";
    let text = "Add To List";
    switch (status) {
        case "watching":
            background = "#16a34a"
            backgroundDark = "#15803d"
            text = "Watching"
            break;
        case "reading":
            background = "#16a34a"
            backgroundDark = "#15803d"
            text = "Reading"
            break;
        case "completed":
            background = "#2563eb"
            backgroundDark = "#1d4ed8"
            text = "Completed"
            break;
        case "on_hold":
            background = "#ea580c"
            backgroundDark = "#c2410c"
            text = "On Hold"
            break;
        case "dropped":
            background = "#dc2626"
            backgroundDark = "#b91c1c"
            text = "Dropped"
            break;
        case "plan_to_watch":
            background = "#4b5563"
            backgroundDark = "#374151"
            text = "Plan To Watch"
            break;
        case "plan_to_read":
            background = "#4b5563"
            backgroundDark = "#374151"
            text = "Plan To Read"
            break;
    }
    return { background, backgroundDark, text };
}

export const formatSource = (source) => {
    let result = "";
    switch (source) {
        case "original":
            result = "Original"
            break;
        case "manga":
            result = "Manga"
            break;
        case "4_koma_manga":
            result = "4 Koma Manga"
            break;
        case "web_manga":
            result = "Web Manga"
            break;
        case "digital_manga":
            result = "Digital Manga"
            break;
        case "novel":
            result = "Novel"
            break;
        case "light_novel":
            result = "Light Novel"
            break;
        case "visual_novel":
            result = "Visual Novel"
            break;
        case "game":
            result = "Game"
            break;
        case "card_game":
            result = "Card Game"
            break;
        case "book":
            result = "Book"
            break;
        case "picture_book":
            result = "Picture Book"
            break;
        case "radio":
            result = "Radio"
            break;
        case "music":
            result = "Music"
            break;
        case "other":
            result = "Other"
            break;
    }
    return result;
}

export const formatDuration = (duration, empty = "?") => {
    if (duration) {
        const seconds = parseInt(duration);
        if (seconds < 60) {
            return `${seconds} Seconds`;
        } else if (seconds < 60 * 60) {
            return `${parseInt(seconds / 60)} Minutes`;
        } else if (seconds < 60 * 60 * 60) {
            return `${parseInt(seconds / 60 / 60)} Hours`;
        } else {
            return `${parseInt(seconds / 60 / 60 / 24)} Days`;
        }
    } else {
        return empty;
    }
}

export const formatAuthors = (authors) => {
    let result = "";
    if (authors) {
        authors.forEach(element => {
            if (element.node.first_name)
                result += element.node.first_name;
            if (element.node.last_name) {
                if (element.node.first_name)
                    result += " ";
                result += element.node.last_name;
            }
            if (element.role && element.node.first_name || element.node.last_name)
                result += ` (${element.role})`;
            result += ", ";
        });
    }
    if (result.endsWith(", "))
        result = result.slice(0, -2);
    return result;
}

export const formatSerialization = (authors) => {
    let result = "";
    if (authors) {
        authors.forEach(element => {
            result += `${element.node.name}, `;
        });
    }
    if (result.endsWith(", "))
        result = result.slice(0, -2);
    return result;
}

export const formatRating = (rating) => {
    let result = "";
    switch (rating) {
        case "g":
            result = "G"
            break;
        case "pg":
            result = "PG"
            break;
        case "pg_13":
            result = "PG 13"
            break;
        case "r":
            result = "R"
            break;
        case "r+":
            result = "R+"
            break;
        case "rx":
            result = "RX"
            break;
        default:
            result = "None"
    }
    return result;
}

export const formatDate = (date, empty = "?") => {
    if (date) {
        const tempDate = new Date(date);
        if (date.length > 7) {
            return tempDate.toLocaleString('default', { dateStyle: 'medium' });
        } else {
            return `${tempDate.toLocaleString('default', { month: 'short' })} ${tempDate.getFullYear()}`;
        }
    } else {
        return empty;
    }
}

export const formatEpisodes = (episodes, empty = "?") => {
    return episodes > 0 ? episodes : empty;
}

export const formatMean = (mean) => {
    return mean > 0 ? mean : "?";
}

export const formatScore = (score) => {
    return score === 0 ? 0 : 11 - score;
}

export const formatMediaType = (media_type) => {
    if (["tv", "ova", "ona"].includes(media_type)) {
        return media_type.toUpperCase();
    } else {
        let words = media_type.split("_");
        words = words.map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(" ");
        return words;
    }
}

export const formatAnimeStatus = (text) => {
    return Constants.ANIME_STATUS_NAMES[Constants.ANIME_STATUS_CODES.indexOf(text)];
}

export const formatArray = (array) => {
    let result = "";
    if (array) {
        array.forEach(element => {
            result += `${element.name}, `;
        });
    }
    if (result.endsWith(", "))
        result = result.slice(0, -2);
    return result;
}


/** */

export const page_variants = () => {
    return (get_page_transition() && is_mobile()) ? Constants.PAGE_VARIANTS : Constants.PAGE_VARIANTS_EMPTY;
}

export const get_year_season = () => {
    const date = new Date();
    let season = "";
    switch (date.getMonth()) {
        case 0:
        case 1:
        case 2:
            season = Constants.SEASONS[0];
            break;
        case 3:
        case 4:
        case 5:
            season = Constants.SEASONS[1];
            break;
        case 6:
        case 7:
        case 8:
            season = Constants.SEASONS[2];
            break;
        case 9:
        case 10:
        case 11:
            season = Constants.SEASONS[3];
            break;
        default:
            season = "";
            break;
    }

    return { 'year': date.getFullYear(), 'season': season };
}

export const format_userdata = (userData) => {
    let result;

    if (userData.location)
        result = userData.location;

    if (userData.birthday) {
        if (result)
            result += " • ";
        result += `${get_age(userData.birthday)} Years`;
    }

    return result;
}

export const get_age = (date) => {
    return Math.abs(parseInt(DateTime.fromISO(date).diffNow('years').years));
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function moveElement(array, fromIndex, toIndex) {
    const element = array.splice(fromIndex, 1)[0];
    array.splice(toIndex, 0, element);
    return array;
}

export const parseSeason = (animes, columns, filters) => {
    const animes_1 = [];

    const animes_2 = {
        "TV": [],
        "ONA": [],
        "OVA": [],
        "Movie": [],
        "Special": [],
        "Other": [],
    };

    animes.forEach((anime, index, arr) => {
        let filtered = false;

        if (anime.node.genres) {
            anime.node.genres.forEach(genre => {
                if (filters.includes(genre.name))
                    filtered = true;
            });
        } else {
            if (anime.node.nsfw !== "white" && filters.includes("Hentai"))
                filtered = true;
        }

        if (!filtered) {
            const tempAnime = {};
            const episodes = formatEpisodes(anime.node.num_episodes);
            const media_type = formatMediaType(anime.node.media_type);

            tempAnime.id = anime.node.id;
            tempAnime.title = anime.node.title;
            tempAnime.cover = anime.node.main_picture ? anime.node.main_picture.medium : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
            tempAnime.subtitle = `${media_type} • ${episodes} Episodes`;
            tempAnime.media_type = media_type;

            if (!animes_2[media_type])
                animes_2[media_type] = [];

            animes_2[media_type].push(tempAnime);
        }
    });

    let last = false;
    for (var anime_1 in animes_2) {
        const anime_2 = animes_2[anime_1];

        if (animes_2[anime_1].length > 0) {
            if (last && columns === 2)
                animes_1.push(undefined);

            animes_1.push(anime_1);

            if (columns === 2)
                animes_1.push(undefined);

            anime_2.forEach((anime, index, arr) => {
                animes_1.push(anime);
            });

            last = anime_2.length % 2 !== 0;
        }
    }

    return animes_1;

    /*animes.forEach((anime, index, arr) => {
        const tempAnime = {};
        tempAnime.id = anime.node.id;
        tempAnime.title = anime.node.title;
        tempAnime.cover = anime.node.main_picture ? anime.node.main_picture.medium : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

        const episodes = formatEpisodes(anime.node.num_episodes);

        tempAnime.episodes = episodes;

        if (anime.relation_type_formatted) {
            tempAnime.subtitle = anime.relation_type_formatted;
        } else if (anime.num_recommendations) {
            tempAnime.subtitle = `${anime.num_recommendations} Recommendations`;
        } else {
            const media_type = formatMediaType(anime.node.media_type);

            if (media_type !== last_media_type) {
                last_media_type = media_type;

                if (columns === 2 && (index + odds) % 2) {
                    tempAnimes.push(undefined);
                    odds += 1;
                }

                tempAnimes.push(formatMediaType(media_type));

                if (columns === 2) {
                    tempAnimes.push(undefined);
                }
            }

            tempAnime.media_type = media_type;
            tempAnime.subtitle = `${media_type} • ${episodes} Episodes`;
        }

        tempAnimes.push(tempAnime);
    });*/
};

export const parseAnimes = (animes, update = false, sort = false) => {
    const tempAnimes = [];
    let last_media_type;

    animes.forEach((anime, index, arr) => {
        const tempAnime = {};
        tempAnime.id = anime.node.id;
        tempAnime.title = anime.node.title;
        tempAnime.cover = anime.node.main_picture ? anime.node.main_picture.medium : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

        const episodes = formatEpisodes(anime.node.num_episodes);

        tempAnime.episodes = episodes;

        if (anime.relation_type_formatted) {
            tempAnime.subtitle = anime.relation_type_formatted;
        } else if (anime.num_recommendations) {
            tempAnime.subtitle = `${anime.num_recommendations} Recommendations`;
        } else {
            const media_type = formatMediaType(anime.node.media_type);

            if (sort && media_type !== last_media_type) {
                last_media_type = media_type;

                if (index)

                    tempAnimes.push(formatMediaType(media_type));
            }

            tempAnime.subtitle = `${media_type} • ${episodes} Episodes`;
        }

        tempAnimes.push(tempAnime);
    });

    return tempAnimes;
};

export const parseMangas = (mangas, update = false, sort = false) => {
    const tempMangas = [];
    let last_media_type;

    mangas.forEach((manga, index, arr) => {
        const tempManga = {};
        tempManga.id = manga.node.id;
        tempManga.title = manga.node.title;
        tempManga.cover = manga.node.main_picture ? manga.node.main_picture.medium : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

        const chapters = formatEpisodes(manga.node.num_chapters);

        tempManga.chapters = chapters;

        if (manga.relation_type_formatted) {
            tempManga.subtitle = manga.relation_type_formatted;
        } else if (manga.num_recommendations) {
            tempManga.subtitle = `${manga.num_recommendations} Recommendations`;
        } else {
            const media_type = formatMediaType(manga.node.media_type);

            if (sort && media_type !== last_media_type) {
                last_media_type = media_type;

                if (index)

                    tempMangas.push(formatMediaType(media_type));
            }

            tempManga.subtitle = `${media_type} • ${chapters} Chapters`;
        }

        tempMangas.push(tempManga);
    });

    return tempMangas;
};

export const percentWatched = (watched, total) => {
    if (total === 0) {
        return 0;
    } else {
        return watched / total * 100;
    }
}

// export const fetchAndCatch = async (url, key = "") => {
//     const key = key ? key : url;

//     let result = sessionStorage.getItem(key);

//     if (result) {

//     } else {
//         const response = fetch(url);

//         if (response.ok) {
//             result = await response.json();
//         } else {

//         }
//     }

//     return result;
// };


// Local Storage Functions

export const get_nsfw = () => {
    const temp = localStorage.getItem("nsfw");
    return temp ? temp === "true" : false;
}

export const set_nsfw = (value) => {
    localStorage.setItem("nsfw", value);
}

export const get_notifications = () => {
    const temp = localStorage.getItem("notifications");
    return temp ? temp === "true" : false;
}

export const set_notifications = (value) => {
    localStorage.setItem("notifications", value);
}

export const get_can_swipe = () => {
    const temp = localStorage.getItem("swipe");
    return temp ? temp === "true" : true;
}

export const set_can_swipe = (value) => {
    localStorage.setItem("swipe", value);
}

export const get_page_transition = () => {
    const temp = localStorage.getItem("page_transition");
    return temp ? temp === "true" : true;
}

export const set_page_transition = (value) => {
    localStorage.setItem("page_transition", value);
}

export const get_layout = () => {
    const temp = localStorage.getItem("layout");
    return temp ? temp : Constants.LAYOUT_DEFAULT;
}

export const set_layout = (value) => {
    localStorage.setItem("layout", value);
}


// Navigation Functions

export const openPage = (navigate) => {
    //navigate();
    if (get_page_transition() && document.startViewTransition && window.matchMedia("(max-width: 768px)").matches) {
        const transition = document.startViewTransition(() => {
            navigate();
        });

        transition.ready.then(() => {
            document.documentElement.animate(
                [
                    { transform: "translateY(100%)" },
                    // { transform: "translateY(66%)" },
                    // { transform: "translateY(33%)" },
                    // { transform: "translateY(0px)" },
                    // { transform: "translateY(10px)" },
                    // { transform: "translateY(0px)" },
                    // { transform: "translateY(5px)" },
                    { transform: "translateY(0)" }
                ],
                {
                    duration: 300,
                    easing: "ease-in",
                    pseudoElement: "::view-transition-new(root)"
                }
            );
        });
    } else {
        navigate();
    }
}

export const animatePageTransition = () => {
    if (document.startViewTransition && window.matchMedia("(max-width: 768px)").matches) {
        const transition = document.startViewTransition();

        transition.ready.then(() => {
            document.documentElement.animate(
                {
                    transform: ["translateY(0%)", "translateY(100%)"],
                    zIndex: [1, 1]
                },
                {
                    duration: 300,
                    easing: "ease-out",
                    pseudoElement: "::view-transition-old(root)"
                }
            );
        });
    }
}

export const closePage = (navigate) => {
    if (document.startViewTransition && window.matchMedia("(max-width: 768px)").matches) {
        const transition = document.startViewTransition(() => {
            navigate();
        });

        transition.ready.then(() => {
            document.documentElement.animate(
                {
                    transform: ["translateY(0%)", "translateY(100%)"],
                    zIndex: [1, 1]
                },
                {
                    duration: 300,
                    easing: "ease-out",
                    pseudoElement: "::view-transition-old(root)"
                }
            );
        });
    } else {
        navigate();
    }
}


// Helper Functions

export const log = (tag = null, data) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (tag) {
            console.log(tag, data);
        } else {
            console.log(data);
        }
    }
}

export const rgbToHex = (r, g, b) => {
    return "#" + (1 << 24 | parseInt(r) << 16 | parseInt(g) << 8 | parseInt(b)).toString(16).slice(1);
}

export const hexToRgb = (hex) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export const rgbToGrayscale = (r, g, b) => {
    //const gray = parseInt((r + g + b) / 3);

    const red = r * .3;
    const green = g * .59;
    const blue = b * .11;

    const gray = parseInt(red + green + blue);

    return [gray, gray, gray];
}

export const wrap = (min, max, v) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

export const get_full_version = () => {
    const version = process.env.REACT_APP_VERSION;
    const buildTimestamp = preval`module.exports = new Date().getTime();`;
    const formatedTimestamp = DateTime.fromMillis(buildTimestamp).toFormat("FF");
    return `${version}.${buildTimestamp} (${formatedTimestamp})`;
}

export const is_mobile = () => {
    return window.matchMedia("(max-width: 768px)").matches;
}

export const is_too_wide = () => {
    return window.matchMedia("(min-width: 1280px)").matches;
}

export const asBoolean = (string) => {
    return /^true$/i.test(string);
}

export const formateNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const shuffle = (array) => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const hashCode = (s) => {
    return [...s].reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0);
}

export const null40 = () => {
    return [
        null, null, null, null, null, null, null, null, null, null,
        null, null, null, null, null, null, null, null, null, null,
        null, null, null, null, null, null, null, null, null, null,
        null, null, null, null, null, null, null, null, null, null
    ];
}