import React, { useState, useEffect, useRef, useLayoutEffect, useContext, lazy, Suspense } from "react";
import { BrowserRouter, createBrowserRouter, Link, NavLink, Route, Routes, useLocation, } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { IconLoader2, IconWifiOff } from "@tabler/icons-react";
import {
  HomeIcon,
  CalendarDaysIcon,
  RectangleStackIcon,
  PuzzlePieceIcon,
  ChatBubbleLeftRightIcon,
  MegaphoneIcon
} from "@heroicons/react/24/outline";
import {
  HomeIcon as HomeIconSolid,
  CalendarDaysIcon as CalendarDaysIconSolid,
  RectangleStackIcon as RectangleStackIconSolid,
  PuzzlePieceIcon as PuzzlePieceIconSolid,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
  MegaphoneIcon as MegaphoneIconSolid,
} from "@heroicons/react/24/solid";
import { DateTime } from "luxon";
import { useLiveQuery } from "dexie-react-hooks";

import { Constants } from "./Helpers/Constants";
import Seasons from "./Pages/Season";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import MyList from "./Pages/MyList";
import { ThemeContext } from "./Contexts/ThemeContext";
import { UserContext } from "./Contexts/UserContext";
import Anime from "./Pages/Anime";
import { database } from "./Database";
import SuggestedAnimes from "./Pages/SuggestedAnimes";
import Trailers from "./Pages/Trailers";
import SuggestedMangas from "./Pages/SuggestedMangas";
import Search from "./Pages/Search";
import Loading from "./Components/Loading";
import Forum from "./Pages/Forum";
import Manga from "./Pages/Manga";
import Main from "./Pages/Main";
import Announcements from "./Pages/Announcements";
import Dialog from "./Components/Dialog";
import Activity from "./Pages/Activity";

/*const Seasons = lazy(() => import('./Pages/Season'));
const Home = lazy(() => import('./Pages/Home'));
const Profile = lazy(() => import('./Pages/Profile'));
const MyList = lazy(() => import('./Pages/MyList'));
const Anime = lazy(() => import('./Pages/Anime'));
const SuggestedAnimes = lazy(() => import('./Pages/SuggestedAnimes'));
const Trailers = lazy(() => import('./Pages/Trailers'));
const SuggestedMangas = lazy(() => import('./Pages/SuggestedMangas'));
const Search = lazy(() => import('./Pages/Search'));
const Forum = lazy(() => import('./Pages/Forum'));
const Manga = lazy(() => import('./Pages/Manga'));
const Announcements = lazy(() => import('./Pages/Announcements'));*/

function NavigationBottom({ active, callback }) {
  return (
    <div className="flex-none md:hidden flex justify-items-stretch items-center h-14 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 bottom-nav z-20">
      <NavLink
        to="/pwa"
        replace={true}
        className={({ isActive }) =>
          (isActive ? "text-blue-600 " : "") + "flex-1 h-12 py-3"
        }
        end
      >
        {({ isActive, isPending }) => (isActive ? <HomeIconSolid className="w-full h-full" /> : <HomeIcon strokeWidth={2} className="w-full h-full" />)}
        {/* <IconHome className="w-full" /> */}
      </NavLink>
      <NavLink
        to="./my-list"
        replace={true}
        className={({ isActive }) =>
          (isActive ? "text-blue-600 " : "") + "flex-1 h-12 py-3"
        }
      >
        {({ isActive, isPending }) => (isActive ? <RectangleStackIconSolid className="w-full h-full" /> : <RectangleStackIcon strokeWidth={2} className="w-full h-full" />)}
        {/* <IconListDetails className="w-full" /> */}
      </NavLink>
      <NavLink
        to="./season"
        replace={true}
        className={({ isActive }) =>
          (isActive ? "text-blue-600 " : "") + "flex-1 h-12 py-3"
        }
      >
        {({ isActive, isPending }) => (isActive ? <CalendarDaysIconSolid className="w-full h-full" /> : <CalendarDaysIcon strokeWidth={2} className="w-full h-full" />)}
        {/* <IconCalendarEvent className="w-full" /> */}
      </NavLink>
      {/* <NavLink
        to="./forum"
        replace={true}
        className={({ isActive }) =>
          (isActive ? "text-blue-600 " : "") + "flex-1 h-12 py-3"
        }
      >
        {({ isActive, isPending }) => (isActive ? <MegaphoneIconSolid className="w-full h-full" /> : <MegaphoneIcon strokeWidth={2} className="w-full h-full" />)}
      </NavLink> */}
      {/* <NavLink
        to="./tools"
        replace={true}
        className={({ isActive }) =>
          (isActive ? "text-blue-600 " : "") + "flex-1 h-12 py-3"
        }
      >
        {({ isActive, isPending }) => (isActive ? <PuzzlePieceIconSolid className="w-full h-full" /> : <PuzzlePieceIcon strokeWidth={2} className="w-full h-full" />)}
      </NavLink> */}
      <UserContext.Consumer>
        {({ userData, changeUserData }) => (
          <NavLink
            to="./profile"
            replace={true}
            className={({ isActive }) =>
              (isActive ? "text-blue-600 " : "") + "flex-1 h-12 py-3"
            }
          >
            {/* {({ isActive, isPending }) => (isActive ? <UserCircleIconSolid className="w-full h-6" /> : <UserCircleIcon strokeWidth={2} className="w-full h-6" />)} */}
            {({ isActive, isPending }) => (
              <img className={(isActive ? "border-blue-600" : "border-gray-900 dark:border-white") + " w-6 h-6 mx-auto object-cover border-2 rounded-full"} src={userData.picture ? userData.picture : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} alt="profile picture" />
            )}
            {/* <IconUserCircle className="w-full" /> */}
          </NavLink>
        )}
      </UserContext.Consumer>
    </div>
  );
}

function LoginComponent({ loginCallback }) {
  const [clicked, setClicked] = useState(false);

  let loginWindow = null;

  function login() {
    //if (!clicked) {
    setClicked(true);
    const url = `${window.location.origin}/api/oauth`;
    window.open(url);
    //}
  }

  function reload() {
    if (loginWindow && !loginWindow.closed) {
      loginWindow.close();
    }
    setClicked(false);
  }

  function checkLogin() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    //console.log(userData);
    if (userData) {
      if (userData.id) {
        loginCallback(null, userData);
      } else {
        setClicked(false);
        loginCallback("Error getting user data...", null);
      }
    }
  }

  useEffect(() => {
    if (clicked) {
      const interval = setInterval(checkLogin, 1000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [clicked]);

  const container = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0, visible: "collapse" },
    visible: { y: 0, opacity: 1, visible: "visible" }
  };

  return (
    <div
      className="flex flex-col w-full h-full bg-white">
      {/* <img src="./images/logo.png" className="w-32 sm:w-48 p-6 sm:p-10 mb-8 bg-blue-600 rounded-md"/> */}
      <div className="flex-1 w-full flex flex-col justify-center bg-blue-600">
        <Loading height={128} color="#ffffffff" className="mbg-16" />
      </div>
      <motion.div
        className="flex-1 flex flex-col items-center justify-center w-full h-full px-8 bg-white dark:bg-gray-900"
        variants={container}
        initial="hidden"
        animate="visible">
        <motion.p className="font-black text-4xl text-blue-600" key={1} variants={item}>AniDex</motion.p>
        <motion.p className="w-full sm:w-96 font-medium text-center text-wrap-balance text-gray-600 dark:text-gray-400 mt-2 mb-8" key={2} variants={item}>
          Login with your MyAnimeList account to start using AniDex MAL Client.
        </motion.p>
        <motion.button
          className="w-full sm:w-96 h-11 font-medium text-white bg-blue-600 rounded-md"
          onClick={login} key={3} variants={item}
        >
          Login With MAL
          {/* <p className={(clicked ? "hidden" : "block")}>Login With MAL</p>
        <IconLoader2 className={(clicked ? "block" : "hidden") + " animate-spin h-5 w-full"} strokeWidth="3" /> */}
        </motion.button>
        {/* <p className={(clicked ? "visible" : "invisible") + " mt-6 text-sm text-white/80 cursor-pointer"} onClick={reload}>Reload</p> */}
        <motion.p className="mt-8 font-medium text-sm text-center text-gray-600 dark:text-gray-400" key={4} variants={item}>By using this site, you agrees to our <a href="/privacy-policy.html" target="_blank" className="underline text-blue-600">Privacy Policy</a>.</motion.p>
      </motion.div>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/my-list",
        element: <MyList />,
      },
      {
        path: "/forum",
        element: <Forum />,
      },
      {
        path: "/season",
        element: <Seasons />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/anime/:id",
        element: <Anime />,
      },
      {
        path: "/manga/:id",
        element: <Anime />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/trailers",
        element: <Trailers />,
      },
      {
        path: "/anime-suggestions",
        element: <SuggestedAnimes />,
      },
      {
        path: "/manga-suggestions",
        element: <SuggestedMangas />,
      }
    ]
  }
], {
  basename: "/pwa",
});

function Dashboard() {
  const location = useLocation();

  const [isOffline, setIsOffline] = useState(false);
  const darkAccent = useRef();

  useEffect(() => {
    const change = () => {
      setIsOffline(!navigator.onLine);
      /*if (navigator.onLine) {
        updateThemeMeta(darkAccent.current);
      } else {
        const color = getThemeMeta();
        const rgb = hexToRgb(color);
        const grayscale_rgb = rgbToGrayscale(rgb[0], rgb[1], rgb[2]);
        const grayscale_hex = rgbToHex(grayscale_rgb[0], grayscale_rgb[1], grayscale_rgb[2]);
        darkAccent.current = color;
        updateThemeMeta(grayscale_hex);
      }*/
    }
    window.addEventListener('online', change);
    window.addEventListener('offline', change);
    return () => {
      window.removeEventListener('online', change);
      window.removeEventListener('offline', change);
    }
  }, []);

  return (
    <>
      <div className={"flex flex-col w-full h-full overflow-hidden"}>
        <header className="hidden md:block w-full h-14 sm:px-2 bg-blue-600 dark:bg-blue-600 text-white shadow-md z-30">
          <nav className="flex items-center max-w-screen-xl h-full mx-auto">
            <div className="hidden md:flex pr-3 pl-2 xl:pl-0">
              {/* <IconNotebook className="w-6 h-6" /> */}
              <Link to="/" className="py-4 font-bold">AniDex</Link>
            </div>

            <div className="flex-1 hidden md:flex whitespace-nowrap">
              <p className="p-4 text-white opacity-50">|</p>
              <Link to="./" className="p-4 font-medium text-white">Home</Link>
              <Link to="./my-list" className="p-4 font-medium text-white">My List</Link>
              <Link to="./season" className="p-4 font-medium text-white">Seasons</Link>
              {/* <Link to="./tools" className="p-4 font-medium text-white">Tools</Link> */}
            </div>

            <div className="flex-1"></div>

            <UserContext.Consumer>
              {({ userData, changeUserData }) => (
                <Link to="profile" className="flex-none flex py-4 mr-2 xl:mr-0">
                  {/* <IconUser className="mr-2"/> */}
                  <p className="font-medium text-white">{userData.name}</p>
                  <img className="w-6 h-6 ml-4 my-auto object-cover outline outline-1 outline-offset-1 rounded-md" src={userData.picture ? userData.picture : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} alt="profile picture" />
                </Link>
              )}
            </UserContext.Consumer>
          </nav>
        </header>

        <div className="flex-1 h-full bg-white shadow-md overflow-x-hidden overflow-y-scroll">
          {/* <Outlet /> */}
          {/* popLayout giving error fallbacks to sync */}
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          <AnimatePresence initial={false} mode="sync">
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path="/my-list" element={<MyList />} />
              <Route path="/season" element={<Seasons />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/anime/:id" element={<Anime />} />
              <Route path="/manga/:id" element={<Manga />} />
              <Route path="/search" element={<Search />} />
              <Route path="/trailers" element={<Trailers />} />
              <Route path="/anime-suggestions" element={<SuggestedAnimes />} />
              <Route path="/manga-suggestions" element={<SuggestedMangas />} />
              <Route path="/announcements" element={<Announcements />} />
              {/* <Route path="/tools" element={<Tools />} /> */}
              {/* <Route path="/meme-creator" element={<MemeCreator />} /> */}

              {/* Admin Tools */}
              <Route path="/activity" element={<Activity />} />
              
              {/* <Route path="*" element={<Home />} /> */}
            </Routes>
          </AnimatePresence>
          {/* </Suspense> */}
        </div>

        <NavigationBottom />

        {/* <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
      </Routes> */}
      </div>
      <Dialog isOpen={isOffline} onClose={() => { }}>
        <div className="flex flex-col items-center justify-center px-2 py-8 text-center text-gray-900">
          <IconWifiOff className="w-full h-36 mb-6 text-blue-700 dark:text-blue-600" strokeWidth={1} />
          <p className="font-bold text-lg">You Are Offline</p>
          <p className="font-medium">Please check your network connection.</p>
        </div>
      </Dialog>
    </>
  );
}

export function AnimesList() {
  const animes = useLiveQuery(
    async () => {
      const animes = await database.animes.toArray();

      return animes;
    }
  );

  return <ul>
    {animes?.map(animes => <li key={animes.id}>
      {animes.title}
    </li>)}
  </ul>;
}

async function fetchAnimeList() {
  const last_updated = (await database.animes.orderBy(Constants.SORT_BY_CODES[2]).reverse().first());
  const dateTime_1 = DateTime.fromISO(last_updated ? last_updated.updated_at : "1990-01-01T00:00:00+00:00");

  let loaded = false;
  let offset = 0;

  while (!loaded) {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("offset", offset);

    const response = await fetch(`/api/anime-list?${urlSearchParams}`);
    if (response.ok) {
      const animes = await response.json();
      animes.forEach((anime, index) => {
        try {
          const dateTime_2 = DateTime.fromISO(anime.node.my_list_status.updated_at);
          if (dateTime_2.diff(dateTime_1, ["seconds"]) <= 0) {
            loaded = true;
            return;
          }

          let genres = [];
          if (anime.node.genres) {
            for (const genre of anime.node.genres) {
              genres.push(genre.id);
            }
          }

          database.animes.put({
            id: anime.node.id,
            title: anime.node.title,
            picture: anime.node.main_picture.medium,
            nsfw: anime.node.nsfw,
            media_type: anime.node.media_type,
            genres: genres,
            status: anime.node.my_list_status.status,
            score: anime.node.my_list_status.score,
            num_episodes_watched: anime.node.my_list_status.num_episodes_watched,
            is_rewatching: anime.node.my_list_status.is_rewatching,
            updated_at: anime.node.my_list_status.updated_at,
            num_episodes: anime.node.num_episodes,
            season: anime.node.start_season ? anime.node.start_season.season : null,
            year: anime.node.start_season ? anime.node.start_season.year : null,
          });
        } catch (error) {
          console.log(error);
        }
      });

      if (animes.length == 100) {
        offset = offset + 100;
      } else {
        loaded = true;
      }
    } else {
      console.log(response.status);
      throw new Error(response.status);
    }
  }
}

async function fetchMangaList() {
  const last_updated = (await database.mangas.orderBy(Constants.SORT_BY_CODES[2]).reverse().first());
  const dateTime_1 = DateTime.fromISO(last_updated ? last_updated.updated_at : "1990-01-01T00:00:00+00:00");

  let loaded = false;
  let offset = 0;

  while (!loaded) {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("offset", offset);

    const response = await fetch(`/api/manga-list?${urlSearchParams}`);
    if (response.ok) {
      const mangas = await response.json();
      mangas.forEach((manga, index) => {
        try {
          const dateTime_2 = DateTime.fromISO(manga.node.my_list_status.updated_at);
          if (dateTime_2.diff(dateTime_1, ["seconds"]) <= 0) {
            loaded = true;
            return;
          }

          let genres = [];
          if (manga.node.genres) {
            for (const genre of manga.node.genres) {
              genres.push(genre.id);
            }
          }

          database.mangas.put({
            id: manga.node.id,
            title: manga.node.title,
            picture: manga.node.main_picture.medium,
            nsfw: manga.node.nsfw,
            media_type: manga.node.media_type,
            genres: genres,
            status: manga.node.my_list_status.status,
            score: manga.node.my_list_status.score,
            num_volumes_read: manga.node.my_list_status.num_volumes_read,
            num_chapters_read: manga.node.my_list_status.num_chapters_read,
            is_rereading: manga.node.my_list_status.is_rereading,
            updated_at: manga.node.my_list_status.updated_at,
            num_volumes: manga.node.num_volumes,
            num_chapters: manga.node.num_chapters,
            year: DateTime.fromISO(manga.node.start_date).year,
          });
        } catch (error) {
          console.log(error);
        }
      });

      if (mangas.length == 100) {
        offset = offset + 100;
      } else {
        loaded = true;
      }
    } else {
      console.log(response.status);
      throw new Error(response.status);
    }
  }
}

async function updateLists() {
  if (!database.isOpen())
    database.open();

  await fetchAnimeList();
  await fetchMangaList();
}

function Initialize({ onInitialized }) {
  const { userData, changeUserData } = useContext(UserContext);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await updateLists();
        onInitialized();
        //changeUserData({ ...userData, initialized: true });
      } catch (error) {
        console.log(error);
        setError(true);
      }
    })();
  }, []);

  function logout() {
    changeUserData(null);
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-4 bg-blue-600 dark:bg-blue-600">
      <p className="font-bold text-2xl text-white">AniDex</p>
      {error ? (
        <>
          <p className="w-full sm:w-96 text-center text-white/80 mt-2 mb-8">
            An Error Occurred, Please Logout And Try Again.
          </p>
          <button className="w-64 sm:w-96 h-11 font-bold text-black bg-white rounded-md" onClick={logout}>
            Logout
          </button>
        </>
      ) : (
        <>
          <p className="w-full sm:w-96 text-center text-white/80 mt-2 mb-8">
            Please wait while we prepare your data, It may take upto a few seconds depending on your connection.
          </p>
          <IconLoader2 className={"block animate-spin h-5 w-full text-white"} strokeWidth="3" />
        </>
      )}
      {/* <AnimesList /> */}
    </div>
  );
}

function Pwa() {
  /* Theme Functions Start */
  const changeTheme = (newTheme) => {
    localStorage.setItem("theme", newTheme);
    setCurrentTheme({
      theme: newTheme,
      changeTheme: changeTheme
    });
  };

  const [currentTheme, setCurrentTheme] = useState({
    theme: Constants.THEME_SYSTEM,
    changeTheme: changeTheme
  });

  useEffect(() => {
    const onThemeChange = ({ matches }) => {
      if (matches) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
      }
    }

    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.removeEventListener('change', onThemeChange);

    const body = document.getElementsByTagName("BODY")[0];
    switch (currentTheme.theme) {
      case Constants.THEME_SYSTEM:
        if (matchMedia.matches) {
          body.classList.add("dark");
        } else {
          body.classList.remove("dark");
        }
        matchMedia.addEventListener('change', onThemeChange);
        break;
      case Constants.THEME_LIGHT:
        body.classList.remove("dark");
        break;
      case Constants.THEME_DARK:
        body.classList.add("dark");
        break;
      default:
        break;
    }
  }, [currentTheme]);
  /* Theme Functions End */

  /* UserData Functions Start */
  const changeUserData = (newUserData) => {
    if (newUserData) {
      localStorage.setItem("userData", JSON.stringify(newUserData));
      //localStorage.setItem("userData", JSON.stringify({ ...newUserData, initialized: false }));
    } else {
      setInitialized(false);
      database.delete();
      localStorage.removeItem("userData");
      localStorage.removeItem("initialized");
      document.cookie = "uuid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "maid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      //localStorage.clear();
      //changeTheme(currentTheme.theme);
    }
    setUserData({
      userData: newUserData,
      changeUserData: changeUserData
    });
  };

  const [userData, setUserData] = useState({
    userData: null,
    changeUserData: changeUserData
  });
  /* UserData Functions End */

  const [initialized, setInitialized] = useState(false);

  useLayoutEffect(() => {
    // Load and apply saved theme
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      changeTheme(savedTheme);
    }

    // Load and apply saved user
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      changeUserData(userData);
    }

    // Load and apply initialized
    const initialized = localStorage.getItem("initialized");
    if (initialized) {
      setInitialized(initialized);
      updateLists();
    }
  }, []);

  useEffect(() => {
    if (userData)
      fetch("/api/user")
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              userData.changeUserData(json);
            });
          } else {
            if (response.status === 401) {
              userData.changeUserData(null);
            }
          }
        })
  }, []);

  function loginCallback(error, userData) {
    if (error) {
      console.log(error);
    } else {
      changeUserData(userData);
    }
  }

  const onInitialized = () => {
    localStorage.setItem("initialized", true);
    setInitialized(true);
  }

  return (
    <ThemeContext.Provider value={currentTheme}>
      <UserContext.Provider value={userData}>
        {userData.userData ? (
          initialized ? (
            // <RouterProvider router={router} />
            <Routes>
              <Route path="/*" element={<Dashboard />} />
            </Routes>
          ) : (
            <Initialize onInitialized={onInitialized} />
          )
        ) : (
          <LoginComponent loginCallback={loginCallback} />
        )}
      </UserContext.Provider>
    </ThemeContext.Provider>
  );
}

export default function App() {
  useLayoutEffect(() => {
    // PWA
    const onBeforeinstallprompt = (event) => {
      event.preventDefault();
      window.installPromptEvent = event;
      window.removeEventListener('beforeinstallprompt', onBeforeinstallprompt);
    }
    window.addEventListener('beforeinstallprompt', onBeforeinstallprompt);

    const installedEvent = new Event("onAppinstalled");
    const onAppinstalled = () => {
      window.installPromptEvent = null;
      window.dispatchEvent(installedEvent);
      window.removeEventListener('appinstalled', onAppinstalled);
    }
    window.addEventListener('appinstalled', onAppinstalled);

    /*return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeinstallprompt);
      window.removeEventListener('appinstalled', onAppinstalled);
    }*/
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/pwa/*" element={<Pwa />} />
      </Routes>
    </BrowserRouter>
  );
}