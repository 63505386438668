import React, { useEffect, useLayoutEffect, useRef } from "react";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import { animatePageTransition, closePage, log, null24, null40, page_variants, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";
import { IconArrowLeft } from "@tabler/icons-react";
import { PlayIcon as PlayIconSolid } from "@heroicons/react/24/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ServerError from "../Components/ServerError";
import UnknownError from "../Components/UnknownError";
import NetworkError from "../Components/NetworkError";
import { useContext } from "react";
import { UserContext } from "../Contexts/UserContext";
import Image from "../Components/Image";

function User({ data }) {
    if (data) {
        return (
            <div className="flex items-center w-full h-20 p-4 cursor-pointer sm:rounded-md bg-white dark:bg-gray-800 shadow hover:shadow-lg">
                <Image className="object-cover w-10 h-10 bg-gray-200 border rounded-md" src={data.picture} alt="cover" draggable="false" />
                <div className="flex-1 px-4 pb-0.5">
                    <p className="font-bold truncate">{data.name}</p>
                    <p className="font-medium text-sm text-gray-500 dark:text-gray-400 truncate">{DateTime.fromSQL(data.updated_at, { zone: "UTC" }).toRelative()}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex items-center w-full h-20 p-4 cursor-pointer sm:rounded-md bg-white dark:bg-gray-800 shadow hover:shadow-lg">
                <div className="w-10 h-10 border rounded-md bg-gray-200 dark:bg-gray-700 shimmer dark:shimmer"></div>
                <div className="flex-1 px-4">
                    <p className="h-5 mb-1 font-bold text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                    <p className="w-2/3 h-4 font-medium text-sm text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                </div>
            </div>
        );
    }
}

export default function Activity() {
    const navigate = useNavigate();
    const location = useLocation();

    const userContext = useContext(UserContext);

    const [activity, setActivity] = useState(null40);
    const [error, setError] = useState(null);

    useLayoutEffect(() => {
        const path = "/api/admin/activity";

        fetch(path)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        //log();
                        setActivity(json);
                        sessionStorage.setItem(path, JSON.stringify(json));
                    });
                } else {
                    if (response.status === 400) {
                        //setStatus(STATUS_ERROR_SERVER);
                    } else if (response.status === 401) {
                        userContext.changeUserData(null);
                    } else if (response.status === 403) {
                        //setStatus(STATUS_ERROR_SERVER);
                    } else if (response.status === 404) {
                        //setStatus(STATUS_ERROR_SERVER);
                    } else if (response.status === 500) {
                        //setError(<ServerError />);
                    } else {
                        //setError(<UnknownError />);
                    }
                }
                //setStatus(true);
            })
            .catch(error => {
                log(error);
                setError(<NetworkError />);
            });

        updateThemeMeta();
    }, []);

    const back = () => {
        if (location.state) {
            navigate(-1);
        } else {
            navigate("/pwa/profile");
        }
    }

    return (
        <motion.div
            key={location.pathname}
            variants={page_variants()}
            animate={"center"}
            exit={"exit"}
            className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <IconArrowLeft className="flex-none block md:hidden w-14 h-14 p-4" onClick={() => { back() }} />
                    <h2 className="flex-1 pl-0 md:pl-4 xl:pl-0 font-bold text-xl4">Activity</h2>
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 content-start w-full h-full px-0 sm:px-4 xl:px-0 py-4">
                        {activity.map((item, index) => (
                            <User key={index} data={item} />
                        ))}
                        <div className="h-px"></div>
                        <div className="hidden md:block h-px"></div>
                        <div className="hidden md:block h-px"></div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
