import React, { useEffect, useLayoutEffect, useContext, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import { animatePageTransition, closePage, get_nsfw, log, null40, openPage, page_variants, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";
import ServerError from "../Components/ServerError";
import UnknownError from "../Components/UnknownError";
import NetworkError from "../Components/NetworkError";
import { parseAnimes } from "../Helpers/Utils";
import { database } from "../Database";
import { formatStatus } from "../Helpers/Utils";
import { UserContext } from "../Contexts/UserContext";
import Image from "../Components/Image";

function Suggested({ data, onClick }) {
    const [status, setStatus] = useState(null);
    const [color, setColor] = useState(null);

    useEffect(() => {
        //log(data);
        (async () => {
            if (data) {
                const anime = await database.animes.get(data.id);
                if (anime) {
                    const { background, text } = formatStatus(anime.status);
                    setColor(background);
                    setStatus({ text: text, episodes: `${anime.num_episodes_watched} / ${data.episodes} Episodes` });
                }
            }
        })();
    }, [data]);

    if (data) {
        return (
            <div onClick={() => { onClick(data.id) }} className="flex items-center w-full p-4 cursor-pointer sm:rounded-md bg-white dark:bg-gray-800 shadow hover:shadow-lg">
                <Image
                    className="flex-none h-32 rounded-md object-cover"
                    style={{ width: "90px" }}
                    src={data.cover}
                    alt="cover"
                />
                <div className="flex-1 flex flex-col w-full pl-4">
                    <p className="font-bold text-justif text-ellipsis-2 text-wrap-balance">{data.title}</p>
                    <p className="font-medium mt-1 text-sm text-gray-500 dark:text-gray-400 truncate">
                        {data.subtitle}
                        {status && <>⠀<span className="inline-block font-medium text-white rounded-md shine" style={{ backgroundColor: color }}>⠀{status.text}⠀</span></>}
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex items-center w-full p-4 cursor-pointer sm:rounded-md bg-white dark:bg-gray-800 shadow">
                <div className="flex-none h-32 bg-gray-200 dark:bg-gray-700 shimmer dark:shimmer rounded-md" style={{ width: "90px" }} />
                <div className="flex-1 flex flex-col w-full pl-4">
                    <p className="font-bold text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                    <p className="w-2/3 mt-1 text-sm text-transparent bg-gray-200 dark:bg-gray-700 rounded-md shimmer dark:shimmer">#</p>
                </div>
            </div>
        );
    }
}

export default function SuggestedAnimes() {
    const navigate = useNavigate();
    const location = useLocation();

    const userContext = useContext(UserContext);

    const [suggested, setSuggested] = useState(null40);
    const [error, setError] = useState(null);
    const ref = useRef();

    useLayoutEffect(() => {
        const tempObject = JSON.parse(sessionStorage.getItem(location.pathname) || JSON.stringify({ scrollOffset: 0 }));
        ref.current.scrollTop = tempObject.scrollOffset;
        sessionStorage.removeItem(location.pathname);

        const path = `/api/anime/suggested?limit=40&nsfw=${get_nsfw()}`;
        const data = sessionStorage.getItem(path);
        if (data) {
            const json = JSON.parse(data);
            setSuggested(parseAnimes(json));
        } else {
            fetch(path)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            //log();
                            setSuggested(parseAnimes(json));
                            sessionStorage.setItem(path, JSON.stringify(json));
                        });
                    } else {
                        if (response.status === 400) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 401) {
                            userContext.changeUserData(null);
                        } else if (response.status === 403) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 404) {
                            //setStatus(STATUS_ERROR_SERVER);
                        } else if (response.status === 500) {
                            setError(<ServerError />);
                        } else {
                            setError(<UnknownError />);
                        }
                    }
                    //setStatus(true);
                })
                .catch(error => {
                    log(error);
                    setError(<NetworkError />);
                });
        }

        updateThemeMeta();

        return () => {
            //animatePageTransition();
        }
    }, []);

    const onClick = (id) => {
        sessionStorage.setItem(location.pathname, JSON.stringify({
            scrollOffset: ref.current.scrollTop
        }));
        openPage(() => {
            navigate(`../anime/${id}`, { state: location.pathname });
        });
    }

    const back = () => {
        //closePage(() => {
            if (location.state) {
                navigate(-1);
            } else {
                navigate("/pwa");
            }
        //});
    }

    return (
        <motion.div
            key={location.pathname}
            variants={page_variants()}
            animate={"center"}
            exit={"exit"}
            className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <IconArrowLeft className="flex-none block md:hidden w-14 h-14 p-4" onClick={() => { back() }} />
                    <h2 className="flex-1 pl-0 md:pl-4 xl:pl-0 font-bold text-xl4">My Anime Suggestions</h2>
                </div>
            </div>
            <div ref={ref} className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 content-start w-full h-full px-0 sm:px-4 xl:px-0 py-4">
                        {suggested.map((item, index) => (
                            <Suggested key={index} data={item} onClick={onClick} />
                        ))}
                        <div className="h-px"></div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
