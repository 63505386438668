import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { formatStatus, openPage } from "../Helpers/Utils";
import { database } from "../Database";
import Image from "./Image";

export default function AnimeItem({ data }) {
    const navigate = useNavigate();

    const [status, setStatus] = useState(null);
    const [color, setColor] = useState(null);

    useEffect(() => {
        //console.log(data);
        (async () => {
            if (data) {
                const anime = await database.animes.get(data.id);
                if (anime) {
                    const { background, text } = formatStatus(anime.status);
                    setColor(background);
                    setStatus({ text: text, episodes: `${anime.num_episodes_watched} / ${data.episodes} Episodes` });
                }
            }
        })();
    }, [data]);

    const onClick = () => {
        openPage(() => {
            navigate(`../anime/${data.id}`, { state: { from: '/anime' } });
        });
    }

    if (data) {
        return (
            <div className="w-32 h-56 snap-start scroll-mx-6 shrink-0 cursor-pointer" style={{ height: "220px" }} onClick={onClick}>
                <div className="relative w-full h-44 rounded-md overflow-hidden">
                    <Image className="w-full h-full bg-gray-200 dark:bg-gray-700 object-cover" src={data.cover} alt="cover" />
                    {status && (<>
                        <p className="absolute top-0 left-0 right-0 py-1.5 font-medium text-xs text-center text-white bg-black shine" style={{ backgroundColor: color }}>{status.text}</p>
                        <p className="absolute bottom-0 left-0 right-0 py-1.5 font-medium text-xs text-center text-white bg-black shine" style={{ backgroundColor: color }}>{status.episodes}</p>
                    </>)}
                </div>
                <div className="flex items-center mt-2">
                    <div className="w-full mt-1">
                        <p className="font-bold text-sm truncate">{data.title}</p>
                        <p className="font-medium text-xs text-gray-500 dark:text-gray-400 truncate">{data.subtitle}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-32 h-56 snap-start scroll-mx-6 shrink-0" style={{ height: "220px" }}>
                <div className={"w-full h-44 bg-gray-200 dark:bg-gray-700 rounded-md " + (data === null ? "shimmer dark:shimmer" : "")} />
                <div className="flex items-center mt-2">
                    <div className="w-full mt-1">
                        <p className={"h-4 mb-1 font-bold text-xs text-transparent bg-gray-200 dark:bg-gray-700 rounded-md " + (data === null ? "shimmer dark:shimmer" : "")}>#</p>
                        <p className={"w-2/3 h-3 mb-1 font-bold text-xs text-transparent bg-gray-200 dark:bg-gray-700 rounded-md " + (data === null ? "shimmer dark:shimmer" : "")}>#</p>
                    </div>
                </div>
            </div>
        );
    }
}