import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    forwardRef,
    useState,
    useMemo,
    memo,
    useCallback,
    useContext
} from "react";
import { motion, LayoutGroup, useAnimation, AnimatePresence, useScroll, useTransform } from "framer-motion";
import { IconLayoutList, IconLayoutGrid, IconChevronLeft, IconChevronRight, IconArrowBadgeRight, IconChevronsLeft, IconChevronsRight, IconFilter, IconAdjustments, IconBell, IconX, IconSettings2, IconSettings } from "@tabler/icons-react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";

import { formatStatus, get_nsfw, get_year_season, hashCode, is_mobile, is_too_wide, log, openPage, parseAnimes, parseSeason, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";
import { database, db } from "../Database";
import { Constants } from "../Helpers/Constants";
import Select from "../Components/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import Switch from "../Components/Switch";
import Dialog from "../Components/Dialog";
import Chips from "../Components/Chips";
import { UserContext } from "../Contexts/UserContext";
import Image from "../Components/Image";
import UnknownError from "../Components/UnknownError";
import ServerError from "../Components/ServerError";
import MyanimelistError from "../Components/MyanimelistError";

function AnimeView({ data, onClick }) {
    const [status, setStatus] = useState(null);
    const [color, setColor] = useState(null);

    useEffect(() => {
        //log(data);
        (async () => {
            if (data) {
                const anime = await database.animes.get(data.id);
                if (anime) {
                    const { background, text } = formatStatus(anime.status);
                    setColor(background);
                    setStatus({ text: text, episodes: `${anime.num_episodes_watched} / ${data.episodes} Episodes` });
                }
            }
        })();
    }, [data]);

    if (data) {
        return (
            <div className="flex items-center w-full h-full p-4 bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow hover:shadow-lg md:rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                <Image
                    className="flex-none h-full w-22 object-cover bg-gray-200 dark:bg-gray-700 rounded-md"
                    style={{ width: "90px" }}
                    src={data.cover}
                    alt="cover"
                />
                <div className="flex-1 flex flex-col ml-4">
                    <p className="font-bold text-justifye text-ellipsis-2 text-wrap-balance">{data.title}</p>
                    <p className="mt-1 font-medium text-sm text-gray-500 dark:text-gray-400 truncate">
                        {data.subtitle}
                        {status && <>⠀<span className="inline-block font-medium text-white rounded-md shine" style={{ backgroundColor: color }}>⠀{status.text}⠀</span></>}
                    </p>
                </div>
            </div>
        );
    } else {
        return (<></>);
    }
}

function ListView({ season, filters, onSubtitleUpdate }) {
    const navigate = useNavigate();
    const location = useLocation();

    const userContext = useContext(UserContext);

    const [width, setWidth] = useState(is_too_wide() ? 1280 : window.innerWidth);
    const [columns, setColumns] = useState(is_mobile() ? 1 : 2);
    const [animes, setAnimes] = useState([]);
    const [error, setError] = useState(null);

    const parentRef = useRef();

    const SIZE_HEADING = 58;
    const SIZE_ITEM = 146 + 32;

    const rowVirtualizer = useVirtualizer({
        count: animes ? (Math.floor(animes.length / columns) + (animes.length % columns)) : 0,
        getScrollElement: () => parentRef.current,
        estimateSize: (index) => {
            if (columns === 1) {
                return (typeof animes[index] === "string" ? SIZE_HEADING : SIZE_ITEM);
            } else if (index === 0) {
                return SIZE_HEADING;
            } else {
                return (typeof animes[index * 2] === "string" ? SIZE_HEADING : SIZE_ITEM);
            }
        },
        //estimateSize: () => 146,
        enableSmoothScroll: true,
        paddingStart: 8,
        paddingEnd: 8,
        overscan: 12,
        rangeExtractor: useCallback((range) => {
            const index = range.startIndex * 2;
            const item = animes[index];
            if (item) {
                if (typeof item === "string") {
                    onSubtitleUpdate(null);
                } else {
                    onSubtitleUpdate(item.media_type);
                }
            }
            return defaultRangeExtractor(range)
        }, [])
    })

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columns,
        getScrollElement: () => parentRef.current,
        estimateSize: () => {
            return ((width < 768 ? width : width / 2) - (width < 1280 ? width < 768 ? 0 : 8 : 0));
        }
    })

    useLayoutEffect(() => {
        const path = `/api/anime/seasons?season=${season.season}&year=${season.year}`;
        const data = sessionStorage.getItem(path);
        if (data) {
            const json = JSON.parse(data);
            setAnimes(parseSeason(json, columns, filters));
        } else {
            fetch(path)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((data) => {
                            setAnimes(parseSeason(data, columns, filters));
                            sessionStorage.setItem(path, JSON.stringify(data));
                        });
                    } else {
                        if (response.status === 401) {
                            userContext.changeUserData(null);
                        } else if (response.status === 500) {
                            setError(<ServerError />);
                        } else if (response.status === 502) {
                            setError(<MyanimelistError />);
                        } else {
                            setError(<UnknownError />);
                        }
                    }
                })
        }
    }, [columns, filters]);

    useLayoutEffect(() => {
        if (animes.length > 0) {
            const tempObject = JSON.parse(sessionStorage.getItem(location.pathname) || JSON.stringify({ scrollOffset: 0, key: getKey() }));
            if (tempObject.key === getKey()) {
                rowVirtualizer.scrollToOffset(tempObject.scrollOffset);
            }
            sessionStorage.removeItem(location.pathname);
        }
    }, [animes]);

    useEffect(() => {
        function handleResize() {
            let width = window.innerWidth;
            width = width >= 1280 ? 1280 : width;

            setColumns(width < 768 ? 1 : 2);

            setWidth(width);

            rowVirtualizer.measure();
            columnVirtualizer.measure();
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getKey = () => {
        return hashCode(`${season.year}${season.season}${filters.join("")}`);
    }

    function getIndex(virtualRow, virtualColumn) {
        return columns === 1 ? virtualRow.index : (virtualColumn.index % 2 ? virtualRow.index * 2 : virtualRow.index * 2 - 1) + 1
    }

    function getData(index) {
        return animes[index];
    }

    function onClick(index) {
        sessionStorage.setItem(location.pathname, JSON.stringify({
            season: season,
            filters: filters,
            scrollOffset: rowVirtualizer.scrollOffset,
            key: getKey()
        }));
        openPage(() => {
            navigate(`../anime/${index}`, { state: location.pathname });
        });
    }

    function getItem(virtualRow, virtualColumn) {
        const data = getData(getIndex(virtualRow, virtualColumn));
        const type = typeof data;

        if (type === "undefined") {
            return <></>;
        } else if (type === "string") {
            return <p className="md:pl-4 py-2 md:py-0 my-2- md:my-4 font-bold md:font-black text-center md:text-left text-black dark:text-white md:text-gray-900 md:dark:text-white bg-white- dark:bg-gray-800- md:bg-transparent- md:dark:bg-transparent- md:border-l-4 border-blue-600 dark:border-blue-600 md:rounded-md- md:rounded-none- shadow- md:shadow-none-">{data}</p>;
        } else {
            return <AnimeView data={data} onClick={onClick} />;
        }
    }

    return (
        <div ref={parentRef} className="w-full h-full md:px-2 xl:px-0 overflow-auto">
            {/* <div className="block md:hidden absolute inset-x-0 top-14 p-4 font-bold text-sm bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 shadow z-30">
                Movies
            </div> */}
            {error === null ?
                animes.length === 0 ?
                    <div className="flex items-center max-w-screen-xl w-full h-full mx-auto">
                        <Loading />
                    </div>
                    :
                    <div className="relative max-w-screen-xl w-full h-full mx-auto" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                            <React.Fragment key={virtualRow.index}>
                                {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
                                    <div
                                        key={virtualColumn.index}
                                        className={(columns === 1 ? "px-2-" : (virtualColumn.index % 2 ? "pl-0 xl:pl-2 xl:pr-0" : "xl:pl-0 pr-0 xl:pr-2")) + " px-2- md:px-2 xl:px-0 py-2"}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: `${virtualColumn.size}px`,
                                            height: `${virtualRow.size}px`,
                                            transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                                        }}
                                    >
                                        {getItem(virtualRow, virtualColumn)}
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                :
                { error }
            }
        </div>
    );
}

const variants = {
    enter: (direction) => {
        return {
            x: direction === 0 ? 0 : direction > 0 ? 32 : -32,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction === 0 ? 0 : direction < 0 ? 32 : -32,
            opacity: 0
        };
    }
};

export default function Seasons() {
    const location = useLocation();
    const navigate = useNavigate();

    const [initiated, setInitiated] = useState(false);

    const [season, setSeason] = useState(get_year_season());
    //const [year, setYear] = useState(get_year_season().year);
    //const [years, setYears] = useState([new Date().getFullYear()]);

    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [filters, setFilters] = useState(["Erotica", "Hentai", "Kids"]);
    const [tempFilters, setTempFilters] = useState(["Erotica", "Hentai", "Kids"]);

    const [subtitle, setSubtitle] = useState();
    const [direction, setDirection] = useState(0);

    //const years = [];

    //const ref = useRef(null);

    //const catched = useMemo(() => isGridView, []);

    useLayoutEffect(() => {
        const temp = [];
        for (let i = 1917; i <= new Date().getFullYear() + 1; i++) {
            //temp.push(`${i}`);
            temp.splice(0, 0, `${i}`);
        }
        //setYears(temp);
    }, []);

    useLayoutEffect(() => {
        //setSeason(get_year_season());

        const tempObject = JSON.parse(sessionStorage.getItem(location.pathname));

        if (tempObject) {
            setSeason(tempObject.season);
            setFilters(tempObject.filters);
        }

        setInitiated(true);

        updateThemeMeta();
    }, []);

    useEffect(() => {
        const hash = location.hash;
        setIsFiltersOpen(hash === "#filters");
    }, [location]);

    /*useEffect(() => {
        sessionStorage.setItem(location.pathname, JSON.stringify({ season: season, filters: filters }));
    }, [season, filters]);*/

    /*useEffect(() => {
        fetch(
            "/api/anime/seasons?season=winter&year=2023"
        )
            .then((response) => response.json())
            .then((data) => {
                log(data.data);
                let tempData = data.data;

                tempData.forEach((item) => {
                    item["type"] = 1;
                });
                log(tempData);
            });
    }, [season]);*/

    const limitSeason = () => {
        const currentSeason = get_year_season();
        const currentSeasonIndex = Constants.SEASONS.indexOf(currentSeason.season);

        let limitSeason = currentSeason.season;
        let limitYear = currentSeason.year;

        if (currentSeasonIndex > 1) {
            limitSeason = currentSeasonIndex === 2 ? Constants.SEASONS[0] : Constants.SEASONS[1];
            limitYear = parseInt(limitYear) + 1;
        } else {
            limitSeason = Constants.SEASONS[currentSeasonIndex + 2];
        }

        return { 'year': limitYear, 'season': limitSeason };
    }

    const nextSeason = () => {
        const limit = limitSeason();
        if (season.season === limit.season && season.year === limit.year)
            return;

        setDirection(1);
        const index = Constants.SEASONS.indexOf(season.season);
        if (index === Constants.SEASONS.length - 1) {
            setSeason({ year: parseInt(season.year) + 1, season: Constants.SEASONS[0] });
        } else {
            setSeason({ year: season.year, season: Constants.SEASONS[index + 1] });
        }
    }

    const previousSeason = () => {
        setDirection(-1);
        const index = Constants.SEASONS.indexOf(season.season);
        if (index === 0) {
            setSeason({ year: parseInt(season.year) - 1, season: Constants.SEASONS[Constants.SEASONS.length - 1] });
        } else {
            setSeason({ year: season.year, season: Constants.SEASONS[index - 1] });
        }
    }

    const onSubtitleUpdate = (string) => {
        if (subtitle !== string)
            setSubtitle(string);
    }

    const onFilterChange = (event) => {
        const target = event.target;

        if (!tempFilters.includes(target.value) && target.checked) {
            tempFilters.push(target.value);
        } else {
            tempFilters.splice(tempFilters.indexOf(target.value), 1);
        }

        setTempFilters([...tempFilters]);
    }

    const openFilters = () => {
        navigate(`../season#filters`);
    }

    const closeFilters = () => {
        navigate(-1);
        setTempFilters([...filters]);
    }

    const resetFilters = (e) => {
        const resetValues = ["Erotica", "Hentai", "Kids"];
        setIsFiltersOpen(false);
        setTempFilters(resetValues);
        setFilters(resetValues);
    }

    const applyFilters = (e) => {
        setIsFiltersOpen(false);
        setFilters([...tempFilters]);
    }

    return (
        <div className="flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <div className="flex-1 flex items-center">
                        <h2 className="flex-0 pl-4 xl:pl-0 font-bold text-xl4">Seasons</h2>
                        {subtitle &&
                            <>
                                <IconChevronRight className="hidden md:block w-14 h-14 p-4 text-black/50 dark:text-white/50" />
                                <h2 className="hidden md:block flex-0 font-bold text-xl4">{subtitle}</h2>
                            </>
                        }
                    </div>
                    <div className="flex-1 flex items-center justify-end md:justify-center">
                        <IconChevronsLeft className="w-14 h-14 p-4 text-white md:text-blue-600 cursor-pointer" onClick={previousSeason} />
                        <AnimatePresence initial={false} custom={direction} mode="popLayout">
                            <motion.div
                                key={`${season.year} ${season.season}`}
                                custom={direction}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: { type: "spring", stiffness: 300, damping: 30 },
                                    opacity: { duration: 0.2 }
                                }}
                                className="w-[120px]">
                                <p className="w-full font-bold text-center uppercase text-white md:text-blue-600 cursor-pointer">{`${season.year} ${season.season}`}</p>
                            </motion.div>
                        </AnimatePresence>
                        {/* <p className="w-[120px] font-medium text-center uppercase text-white md:text-blue-600 cursor-pointer">{`${season.year} ${season.season}`}</p> */}
                        <IconChevronsRight className="w-14 h-14 p-4 xl:-mr-4 text-white md:text-blue-600 cursor-pointer" onClick={nextSeason} />
                    </div>
                    <div className="flex-1 hidden md:flex items-center justify-end">
                        <div className="w-32 mr-4 xl:mr-0 rounded-md relative flex items-center h-10 pl-4 pr-1.5 font-medium text-white bg-blue-600 border border-blue-600 cursor-pointer" onClick={openFilters}>
                            <p className="flex-1">Filters</p>
                            <IconFilter className="w-10 h-10 p-2.5" />
                        </div>
                    </div>
                    {/* <div className="flex-1"></div>
                    <div className="hidden md:block flex-1"></div> */}
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                {initiated && <ListView key={`${season.year}${season.season}${filters}`} season={season} filters={filters} onSubtitleUpdate={onSubtitleUpdate} />}
                <IconAdjustments onClick={openFilters} className="scale-in block md:hidden absolute right-4 bottom-[72px] w-14 h-14 p-4 text-white bg-blue-600 rounded-full shadow-md cursor-pointer" />
            </div>
            <Dialog isOpen={isFiltersOpen} onClose={closeFilters}>
                <>
                    <div className="flex p-4 items-center text-blue-600">
                        <p className="flex-1 font-bold text-xl">Filters</p>
                        <IconX className="w-6 h-6" onClick={closeFilters} />
                    </div>
                    <div className="flex-1 p-4 text-gray-400 border-y border-gray-200 overflow-y-scroll">
                        <p className="mb-2 font-bold text-gray-600">Genres</p>
                        <Chips chips={Constants.GENRES} onChange={onFilterChange} checked={tempFilters} />
                        <p className="mt-4 mb-2 font-bold text-gray-600">Explicit Genres</p>
                        <Chips chips={Constants.EXPLICIT_GENRES} onChange={onFilterChange} checked={tempFilters} />
                        <p className="mt-4 mb-2 font-bold text-gray-600">Themes</p>
                        <Chips chips={Constants.THEMES} onChange={onFilterChange} checked={tempFilters} />
                        <p className="mt-4 mb-2 font-bold text-gray-600">Demographics</p>
                        <Chips chips={Constants.DEMOGRAPHICS} onChange={onFilterChange} checked={tempFilters} />
                    </div>
                    <div className="flex p-4 space-x-4">
                        <button onClick={resetFilters} className="flex-1 h-12 font-medium text-white bg-red-700 rounded-md">Reset</button>
                        <button onClick={applyFilters} className="flex-1 h-12 font-medium text-white bg-blue-600 rounded-md">Apply</button>
                    </div>
                </>
            </Dialog>
        </div >
    );
}
