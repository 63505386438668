export const Constants = {
  VAPID_KEY: "BP9u2ljq6AgHTK6sQJ8oz651nnNILolsXUj5Q-N5NN0Yf4y97X1VGVG4GaKjZtjQtqFwtEcQj_pX9ffKW_TuEWk",

  URL_API_USER: "/api/user",
  URL_API_HOME: "/api/home",
  URL_API_TRAILERS: "/api/trailers",
  URL_API_ANIME_SEASONS: "/api/anime/seasons",
  URL_API_ANIME_SUGGESTIONS: "/api/anime/suggested?limit=40",
  URL_API_MANGA_SUGGESTIONS: "/api/manga/suggested?limit=40",

  PRIMARY_COLOR: "#2563eb",
  PRIMARY_DARK_COLOR: "#1d4ed8",

  NAV_LOGIN: 0,
  NAV_HOME: 1,
  NAV_PROFILE: 2,
  NAV_SEASONS: 3,
  NAV_LIST: 4,
  NAV_ANIME_ALL: 411,
  NAV_ANIME_WATCHING: 412,
  NAV_ANIME_COMPLETED: 413,
  NAV_ANIME_ON_HOLD: 414,
  NAV_ANIME_DROPPED: 415,
  NAV_ANIME_PLAN_TO_WATCH: 416,
  NAV_MANGA_ALL: 421,

  THEME_SYSTEM: "0",
  THEME_LIGHT: "1",
  THEME_DARK: "2",

  LAYOUT_LIST: "list",
  LAYOUT_GRID: "grid",
  LAYOUT_DEFAULT: "grid",

  THEMES: {},

  SEASONS: ["Winter", "Spring", "Summer", "Fall"],

  GENRES_WHITE: ["Action", "Adventure", "Cars", "Comedy", "Dementia", "Demons", "Drama", "Ecchi", "Fantasy", "Game", "Harem", "Historical", "Horror", "Josei", "Kids", "Magic", "Martial Arts", "Mecha", "Military", "Music", "Mystery", "Parody", "Police", "Psychological", "Romance", "Samurai", "School", "Sci-Fi", "Seinen", "Shoujo", "Shoujo Ai", "Shounen", "Shounen Ai", "Slice of Life", "Space", "Sports", "Super Power", "Supernatural", "Thriller", "Vampire", "Yaoi", "Yuri"],
  GENRES_GRAY: ["Action", "Adventure", "Cars", "Comedy", "Dementia", "Demons", "Drama", "Ecchi", "Fantasy", "Game", "Harem", "Hentai", "Historical", "Horror", "Josei", "Kids", "Magic", "Martial Arts", "Mecha", "Military", "Music", "Mystery", "Parody", "Police", "Psychological", "Romance", "Samurai", "School", "Sci-Fi", "Seinen", "Shoujo", "Shoujo Ai", "Shounen", "Shounen Ai", "Slice of Life", "Space", "Sports", "Super Power", "Supernatural", "Thriller", "Vampire", "Yaoi", "Yuri"],

  GENRES: ["Action", "Adventure", "Avant Garde", "Award Winning", "Boys Love", "Comedy", "Drama", "Fantasy", "Girls Love", "Gourmet", "Horror", "Mystery", "Romance", "Sci-Fi", "Slice of Life", "Sports", "Supernatural", "Suspense"],
  EXPLICIT_GENRES: ["Ecchi", "Erotica", "Hentai"],
  THEMES: ["Adult Cast", "Anthropomorphic", "CGDCT", "Childcare", "Combat Sports", "Crossdressing", "Delinquents", "Detective", "Educational", "Gag Humor", "Gore", "Harem", "High Stakes Game", "Historical", "Idols (Female)", "Idols (Male)", "Isekai", "Iyashikei", "Love Polygon", "Magical Sex Shift", "Mahou Shoujo", "Martial Arts", "Mecha", "Medical", "Military", "Music", "Mythology", "Organized Crime", "Otaku Culture", "Parody", "Performing Arts", "Pets", "Psychological", "Racing", "Reincarnation", "Reverse Harem", "Romantic Subtext", "Samurai", "School", "Showbiz", "Space", "Strategy Game", "Super Power", "Survival", "Team Sports", "Time Travel", "Vampire", "Video Game", "Visual Arts", "Workplace"],
  DEMOGRAPHICS: ["Josei", "Kids", "Seinen", "Shoujo", "Shounen"],


  ANIME_STATUS_CODES: ["finished_airing", "currently_airing", "not_yet_aired"],
  ANIME_STATUS_NAMES: ["Finished Airing", "Currently Airing", "Not Yet Aired"],

  SCORE_NAMES: ["Select", "(10) Masterpiece", "(9) Great", "(8) Very Good", "(7) Good", "(6) Fine", "(5) Average", "(4) Bad", "(3) Very Bad", "(2) Horrible", "(1) Appailling"],

  ANIME_LIST_CODES: ["watching", "completed", "on_hold", "dropped", "plan_to_watch"],
  ANIME_LIST_NAMES: ["Watching", "Completed", "On Hold", "Dropped", "Plan To Watch"],

  MANGA_LIST_CODES: ["reading", "completed", "on_hold", "dropped", "plan_to_read"],
  MANGA_LIST_NAMES: ["Reading", "Completed", "On Hold", "Dropped", "Plan To Read"],

  SORT_BY_CODES: ["title", "score", "updated_at"],
  SORT_BY_NAMES: ["Title", "Score", "Last Updated"],

  ORDER_BY_ASC: "asc",
  ORDER_BY_DESC: "desc",

  LIST_TYPES: ["Anime", "Manga"],

  PAGE_VARIANTS_EMPTY: {
    enter: {
      transition: {
        duration: 0
      }
    },
    center: {
      transition: {
        duration: 0
      }
    },
    exit: {
      transition: {
        duration: 0
      }
    }
  },

  PAGE_VARIANTS: {
    enter: {
      y: "0%",
      transition: {
        ease: "easeOut",
        duration: 0.3
      }
    },
    center: {
      y: "0%",
      transition: {
        ease: "easeOut",
        duration: 0.3
      }
    },
    exit: {
      zIndex: 99,
      y: "100%",
      transition: {
        ease: "easeOut",
        duration: 0.3
      }
    }
  }
};
