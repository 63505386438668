import { IconLoader, IconLoader2 } from "@tabler/icons-react";
import { motion } from "framer-motion";

export default function Loading({ className, height = 128, color = "#2563ebff" }) {
    const draw = {
        hidden: { strokeDasharray: 0, strokeDashoffset: 1000, fill: "#00000000" },
        visible: {
            strokeDasharray: 500,
            strokeDashoffset: 0,
            fill: color,
            transition: {
                strokeDasharray: { duration: 2 },
                strokeDashoffset: { duration: 2 },
                fill: { delay: 1, duration: 2 }
            }
        }
    };
    return (
        <svg
            width="100%"
            height={height}
            viewBox="0 0 135.47 135.47"
            className={className + " mx-auto"}>
            <motion.path
                initial="hidden"
                animate="visible"
                fill={"none"}
                stroke={color}
                strokeWidth={2}
                strokeDasharray={20}
                strokeDashoffset={0}
                //strokeLinecap={"round"}
                variants={draw}
                //className="stroke-blue-600"
                d="M93.706 125.798l-2.333-6.042a5.833 5.833 34.442 0 0-5.441-3.731H53.51a2.838 2.838 54.64 0 1-2.679-3.776l5.927-16.944a5.637 5.637 144.64 0 1 5.321-3.776h14.353a2.743 2.743 124.439 0 0 2.559-3.731L48.809 9.669a2.743 2.743 124.439 0 1 2.559-3.731H83.51a5.901 5.901 34.133 0 1 5.481 3.716l46.307 116.161a2.712 2.712 124.133 0 1-2.519 3.716H99.147a5.833 5.833 34.442 0 1-5.441-3.731zm-93.554-.015L41.496 15.272a1.492 1.492.055 0 1 2.796.003l15.466 41.585a11.429 11.429 88.889 0 1 .146 7.549l-20.136 61.32a5.524 5.524 144.09 0 1-5.248 3.8H2.751a2.774 2.774 55.256 0 1-2.598-3.746z" />
        </svg>
        // <IconLoader2 className="animate-spin h-10 w-full text-blue-600"/>
        /*<svg class="animate-spin h-5 w-full text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>*/
    );
}