import { IconChevronDown } from "@tabler/icons-react";
import { useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Select({ options = [], selected = 0, onSelect = {}, indicator = true, className = "w-32" }) {
    //const [value, setValue] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    /*useEffect(() => {
        setValue(options[selected]);
    }, [options]);*/

    function select(text) {
        setIsOpen(!isOpen);
        //setValue(text);
        onSelect(text);
    }

    //console.log("Select", "rendered");

    return (
        <div className="relative font-medium">
            {isOpen ? <div
                className="fixed top-0 bottom-0 right-0 left-0 bg-blackh/10 md:backdrop-blur-smh"
                onClick={() => { setIsOpen(false) }}></div> : null}
            <div
                className={(isOpen ? "border-blue-600 " : "border-gray-300 dark:border-gray-700 ") + className + " relative flex items-center h-10 px-4 bg-white dark:bg-gray-900 border-2 hover:border-blue-600 dark:hover:border-blue-600 cursor-pointer"}
                onClick={() => { setIsOpen(!isOpen) }} >
                <p className="flex-1 mr-4 truncate">{options[selected]}</p>
                {indicator && <IconChevronDown strokeWidth={3} className={(isOpen ? "rotate-180" : "") + " flex-none w-4 h-4 opacity-50 transition-transform"} />}
            </div>
            {isOpen ? <motion.div
                initial={{ scaleY: 0, translateY: "-50%", opacity: 0 }}
                animate={{ scaleY: 1, translateY: "0%", opacity: 1 }}
                className={className + " absolute top-14 py-2 bg-white dark:bg-gray-900 border-2 border-blue-600 shadow-md overflow-x-hidden overflow-y-scroll z-50"}
                style={{ maxHeight: "257px" }}
                ref={ref} >
                {options.map(text => (
                    <p
                        className="px-4 py-2 hover:bg-blue-600/10 dark:hover:bg-blue-600/30 cursor-pointer"
                        onClick={() => { select(text) }}
                        key={text}
                    >{text}</p>
                ))}
            </motion.div> : null}
        </div>
    );
}