import { motion } from "framer-motion";
import { memo, useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";

export default function Tabs({ tabs = [], onSelect = {}, selected = 0, className = "" }) {
    //const [selected, setSelected] = useState(defaultSelected);
    const [initiated, setInitiated] = useState(false);
    const ref = useRef([]);

    /*useEffect(() => {
        setSelected(defaultSelected);
    }, [defaultSelected]);*/

    // TODO:- Testing useLayoutEffect
    useLayoutEffect(() => {
        ref.current[selected].scrollIntoView({behavior: "instant", inline: "center"});
        //ref.current[selected].scrollIntoView({ behavior: "smooth", inline: "center" });
    }, [selected]);

    /*function select(i) {
        setSelected(i);
        onSelect(tabs[i]);
    }*/

    useEffect(() => {
        setInitiated(true);
    }, []);

    const items = tabs.map((tab, i) => (
        <div ref={element => ref.current[i] = element} key={i} className="relative snap-center cursor-pointer text-sm" onClick={() => { onSelect(tabs[i]) }}>
            <p className={(i === selected ? "text-blue-600" : "text-gray-600 dark:text-gray-200") + " px-4 pb-4 pt-5"}>{tab}</p>
            {initiated ? (
                i === selected && <motion.div layoutId="underline" className="absolute bottom-0 w-full h-1 mx-4 bg-blue-600 rounded-t" style={{ width: "calc(100% - 32px)" }} />
            ) : (
                i === selected && <div className="absolute bottom-0 w-full h-1 mx-4 bg-blue-600 rounded-t" style={{ width: "calc(100% - 32px)" }} />
            )}
        </div>
    ))

    //console.log("Tabs", "Rendered");

    return (
        <div className={className + " flex items-center ah-14 flex-nowrap font-bold text-xs whitespace-nowrap uppercase border-b border-gray-200 dark:border-gray-700 overflow-x-scroll snap-mandatory snap-x"}>
            {items}
        </div>
    );
};

//export default Tabs = memo(Tabs);