import { IconServer2 } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export default function ServerError() {
    return (
        <div className="flex flex-col justify-center w-full h-full p-4">
            <IconServer2 className="w-full h-36 text-blue-700 dark:text-blue-600" strokeWidth={1} />
            <p className="font-bold text-lg text-center">Server Error</p>
            <p className="text-center text-gray-600 dark:text-gray-400">Unknown server error, Please try again later.</p>
            <Link to={0} className="w-36 py-3 mx-auto mt-12 font-bold text-center text-white bg-blue-700 dark:bg-blue-600 rounded-md">Try Again</Link>
        </div>
    );
}