import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import preval from "preval.macro";
import { IconBolt, IconBrandDiscord, IconBrandGithub, IconBrandPatreon, IconCoffee, IconCup, IconMoon, IconSparkles } from "@tabler/icons-react";
import { IconMoonStars } from "@tabler/icons-react";
import { log } from "../Helpers/Utils";

export default function Main() {
    const navigate = useNavigate();

    //const [isInstalled, setIsInstalled] = useState(false);

    /*const checkIsInstalled = () => {
        if (window.installPromptEvent != null)
            setIsInstalled(navigator.standalone || window.matchMedia('(display-mode: standalone)').matches);
    }

    useLayoutEffect(() => {
        checkIsInstalled();
    }, []);*/

    useEffect(() => {
        const onAppinstalled = () => { navigate("/pwa"); }
        window.addEventListener('onAppinstalled', onAppinstalled);
        return () => { window.removeEventListener('onAppinstalled', onAppinstalled); }
    }, []);

    /*useEffect(() => {
        if (isInstalled)
            navigate("/pwa");
    }, [isInstalled]);*/

    return (
        <>
            <div className="px-4 pt-16 text-white bg-blue-600">
                <div className="flex flex-col max-w-screen-xl mx-auto">
                    <p className="font-bold text-4xl text-center">AniDex <sup className="px-2 py-1 mb-2 text-xs text-black- uppercase bg-white- border border-white rounded-md">Beta</sup></p>
                    <p className="font-medium text-2xl text-center text-white/80">MyAnimeList Web Client</p>
                    <p className="max-w-[72rem] mx-auto mt-16 font-medium- text-xl text-center text-wrap-balance">AniDex is a cross-platform MyAnimeList web client. It makes it easy for you to access MyAnimeList and view and edit your data.</p>
                    <Link to={"/pwa"} className="w-64 py-3.5 mx-auto mt-16 font-bold text-xl text-center text-black bg-white hover:shadow-xl rounded-full transition-shadow duration-200">Open AniDex</Link>
                    <img fetchpriority="high" width="1279" height="1040" className="w-full max-w-[48rem] mx-auto mt-16 bg-white rounded-t-md overflow-hidden-" src="/images/screenshots/screenshot-1.avif" alt="screenshot" draggable={false} />
                </div>
            </div>
            <div className="px-4 py-4 text-gray-900 bg-white">
                <div className="flex flex-col max-w-screen-xl mx-auto">
                    <h2 className="text-2xl font-bold mt-12 mb-5 mx-auto text-black dark:text-white border-b-2 border-blue-600">Features</h2>
                    <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mb-0 md:mb-4">
                        <div className="basis-1/3 flex flex-col items-center p-6 text-lg bg-white border border-gray-200 shadow-md- rounded-md">
                            <IconMoonStars className="w-10 h-10 p-2 text-blue-600 bg-blue-100 rounded-md" />
                            <p className="mt-4 font-bold text-center">Dark Mode</p>
                            <p className="mt-4 font-medium text-center text-wrap-balance text-gray-600">Light or dark use whatever theme you prefer.</p>
                        </div>
                        <div className="basis-1/3 flex flex-col items-center p-6 text-lg bg-white border border-gray-200 shadow-md- rounded-md">
                            <IconSparkles className="w-10 h-10 p-2 text-blue-600 bg-blue-100 rounded-md" />
                            <p className="mt-4 font-bold text-center">Responsive</p>
                            <p className="mt-4 font-medium text-center text-wrap-balance text-gray-600">Modern design makes it easy to use and navigate.</p>
                        </div>
                        <div className="basis-1/3 flex flex-col items-center p-6 text-lg bg-white border border-gray-200 shadow-md- rounded-md">
                            <IconBolt className="w-10 h-10 p-2 text-blue-600 bg-blue-100 rounded-md" />
                            <p className="mt-4 font-bold text-center">Performance</p>
                            <p className="mt-4 font-medium text-center text-wrap-balance text-gray-600">Extreamly performant and lightweight website.</p>
                        </div>
                    </div>
                    <h2 className="text-2xl font-bold mt-12 mb-5 mx-auto text-black border-b-2 border-blue-600">About</h2>
                    <div className="mb-6 bg-white text-lg text-justify text-gray-600 border border-gray-200 shadow-md- rounded-md p-6 space-y-4 mb-6">
                        <p className="font-medium sm:text-center text-wrap-balance">AniDex uses MyAnimeList APIs under the hood to work, and so future development of AniDex will depend on the development of MyAnimeList APIs. AniDex is developed and maintained solely by me. If you find AniDex helpful and convenient, please support it by making a donation to cover server, domain, and development costs. If you have any queries or complaints, please contact me at <a href="mailto:admin@anidex.app" className="text-blue-600">admin@anidex.app</a>, and for privacy policies, click <a className="text-blue-600" href="/privacy-policy">Privacy Policy</a>.</p>
                    </div>
                    {/* <h2 className="text-2xl font-bold mt-12 mb-5 mx-auto text-black dark:text-white border-b-2 border-blue-600">Support Us</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 justify-center mt-12- mb-16 space-x-4-">
                        <a className="flex-1 flex items-center justify-center px-4 py-3.5 font-medium text-xl text-white bg-blue-600 rounded-md" style={{ backgroundColor: "#5865f2" }} href="https://discord.gg/WEZFPsNy3m" target="_blank"><IconBrandDiscord className="w-6 h-6 mr-2" /><p>Discord</p></a>
                        <a className="flex-1 flex items-center justify-center px-4 py-3.5 font-medium text-xl text-white bg-blue-600 rounded-md" style={{ backgroundColor: "#ff424d" }} href="https://patreon.com/porush" target="_blank"><IconBrandPatreon className="w-6 h-6 mr-2" /><p>Patreon</p></a>
                        <a className="flex-1 flex items-center justify-center px-4 py-3.5 font-medium text-xl text-white bg-blue-600 rounded-md" style={{ backgroundColor: "#333333" }} href="https://github.com/sponsors/Porush" target="_blank"><IconBrandGithub className="w-6 h-6 mr-2" /><p>Github Sponsors</p></a>
                        <a className="flex-1 flex items-center justify-center px-4 py-3.5 font-medium text-xl text-black bg-blue-600 rounded-md" style={{ backgroundColor: "#ffdd00" }} href="https://buymeacoffee.com/porush" target="_blank"><IconCup className="w-6 h-6 mr-2" /><p>Buy Me A Coffee</p></a>
                    </div> */}
                </div>
            </div>
            <div className="px-4 py-16 text-white bg-blue-600">
                <div className="flex flex-col max-w-screen-xl mx-auto">
                    <p className="font-bold text-center">©{preval`module.exports = (1900 + new Date().getYear());`} AniDex - All rights reserved.</p>
                </div>
            </div>
        </>
    );
}
