import { useState } from "react";

export default function Image({ src, className, style, alt }) {
    const [loaded, setLoaded] = useState(false);

    const onLoad = () => {
        setLoaded(true);
    }

    return (
        <>
            {!loaded && <div className={className + " shimmer dark:shimmer"} style={style}></div>}
            <img className={className} style={{ ...style, display: loaded ? "block" : "none" }} src={src} alt={alt} onLoad={onLoad} draggable={false} />
        </>
    );
}