import { IconApiOff } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export default function MyanimelistError() {
    return (
        <div className="flex flex-col justify-center w-full h-full p-4">
            <IconApiOff className="w-full h-36 text-blue-700 dark:text-blue-600" strokeWidth={1} />
            <p className="font-bold text-lg text-center">MyAnimeList API Error</p>
            <p className="text-center text-gray-600 dark:text-gray-400">MyAnimeList API is unavailable, Please Try again later.</p>
        </div>
    );
}