import React, { useLayoutEffect, useRef, useState } from "react";
import CharacterItem from "./CharacterItem";
import { IconCaretLeft, IconCaretRight } from "@tabler/icons-react";

export default function Characters({ data }) {
    const [items, setItems] = useState([]);
    const [canScroll, setCanScroll] = useState(true);
    const ref = useRef();

    useLayoutEffect(() => {
        const offsetWidth = ref.current.offsetWidth;
        let length = 0;
        for (const item of data) {
            if (item)
                length = length + 1;
        }
        const dataWidth = (128 * length) + (length > 1 ? length + 4 * 15 : 0);

        if (dataWidth < offsetWidth) {
            setItems([...data, null, null, null, null, null, null]);
            setCanScroll(false);
        } else {
            setItems(data);
        }
    }, [data]);

    function previous() {
        if (canScroll) {
            ref.current.scrollBy({
                left: -1,
                behavior: 'smooth'
            });
        }
    }

    function next() {
        if (canScroll) {
            ref.current.scrollBy({
                left: 1,
                behavior: 'smooth'
            });
        }
    }

    return (
        <div className="relative group/item mt-4 mb-2">
            <div ref={ref} className={"relative w-full flex px-4 pb-2 gap-4 snap-x snap-mandatory " + (canScroll ? "overflow-x-auto" : "overflow-x-hidden")}>
                {items.map((item, index) => (
                    <CharacterItem key={index} data={item} />
                ))}
            </div>
            <div onClick={() => { previous() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:translate-x-2 absolute top-24 left-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretLeft className="w-full h-full p-1 fill-black" />
            </div>
            <div onClick={() => { next() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:-translate-x-2 absolute top-24 right-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretRight className="w-full h-full p-1 fill-black" />
            </div>
        </div>
    );
}