import React, { useLayoutEffect, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import { animatePageTransition, closePage, openPage, page_variants, updateThemeMeta } from "../Helpers/Utils";
import Loading from "../Components/Loading";

export default function SuggestedMangas() {
    const navigate = useNavigate();
    const location = useLocation();

    useLayoutEffect(() => {
        return () => {
            //animatePageTransition();
        }
    }, []);

    useEffect(() => {
        updateThemeMeta();
    }, []);

    const onClick = (id) => {
        /*sessionStorage.setItem(location.pathname, JSON.stringify({
            scrollOffset: ref.current.scrollTop
        }));*/
        openPage(() => {
            navigate(`../anime/${id}`, { state: location.pathname });
        });
    }

    const back = () => {
        //closePage(() => {
            if (location.state) {
                navigate(-1);
            } else {
                navigate("/pwa");
            }
        //});
    }

    return (
        <motion.div
            key={location.pathname}
            variants={page_variants()}
            animate={"center"}
            exit={"exit"}
            className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <IconArrowLeft className="flex-none block md:hidden w-14 h-14 p-4" onClick={() => { back() }} />
                    <h2 className="flex-1 pl-0 md:pl-4 xl:pl-0 font-bold text-xl4">My Manga Suggestions</h2>
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full mx-auto">
                    <div className="flex items-center h-full">
                        <Loading />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
