import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    forwardRef,
    useState,
    useContext
} from "react";
import {
    IconComponents,
    IconBrightness,
    IconLayout2,
    IconDevices,
    IconBell,
    IconHeart,
    IconUsers,
    IconChevronRight,
    IconRating18Plus,
    IconBrandDiscord,
    IconExternalLink,
    IconBrandGithub,
    IconChevronUp,
    IconBrandWindows,
    IconDeviceDesktop,
    IconLogout,
    IconBrandPaypal,
    IconUser,
    IconMessage,
    IconCoffee,
    IconCheck,
    IconMoon,
    IconSun,
    IconSettings,
    IconFileCode2,
    IconFileCode,
    IconCircleDot,
    IconCircle,
    IconCircleDotFilled,
    IconCircleCheckFilled,
    IconLayoutList,
    IconLayoutGrid,
    IconInfoHexagon,
    IconNotes,
    IconHandMove,
    IconInfoCircle,
    IconBrandPatreon,
    IconEaseInOutControlPoints,
    IconSwipe,
    IconMapPin,
    IconCake,
    IconCalendarEvent,
    IconActivity
} from "@tabler/icons-react";
import { motion, AnimatePresence } from "framer-motion";
import { DateTime } from "luxon";
import Dexie from "dexie";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { ThemeContext } from "../Contexts/ThemeContext";
import { UserContext } from "../Contexts/UserContext";
import { Constants } from "../Helpers/Constants";
import { database } from "../Database";
import Select from "../Components/Select";
import { asBoolean, format_userdata, get_age, get_can_swipe, get_full_version, get_layout, get_notifications, get_nsfw, get_page_transition, openPage, set_can_swipe, set_layout, set_notifications, set_nsfw, set_page_transition, updateThemeMeta } from "../Helpers/Utils";
import Switch from "../Components/Switch";
import Dialog from "../Components/Dialog";
import { Link, useNavigate } from "react-router-dom";

function SelectItem({ Icon, name, items, selected = 0, onChange }) {
    const [value, setValue] = useState(selected);
    const options = [];
    Object.keys(items).forEach((key) => {
        options.push(
            <option key={items[key]} value={items[key]}>
                {key}
            </option>
        );
    });
    return (
        <div className="flex items-center h-14 px-4 font-medium text-dark dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
            <Icon />
            <p className="flex-1 ml-8">{name}</p>
            <select
                className="w-24 bg-white dark:bg-gray-900 border-4 border-white dark:border-gray-900 outline outline-1 outline-gray-200 rounded"
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e);
                }}
                value={value}
            >
                {options}
            </select>
        </div>
    );
}

export default function Profile() {
    const navigate = useNavigate();

    //static contextType = ThemeContext;
    //const theme = useContext(ThemeContext);
    const { userData, changeUserData } = useContext(UserContext);
    const [userState, setUserState] = useState({ anime: { all: 0, watching: 0, completed: 0, on_hold: 0, dropped: 0, plan_to_watch: 0 }, manga: { all: 0, reading: 0, completed: 0, on_hold: 0, dropped: 0, plan_to_read: 0 } });
    const [nsfw, setNsfw] = useState(true);
    const [notifications, setNotifications] = useState(get_notifications());
    const [pageTransition, setPageTransition] = useState(get_page_transition());
    const [canSwipe, setCanSwipe] = useState(get_can_swipe());
    const [layout, setLayout] = useState(get_layout());

    const [isThemeDialogOpen, setIsThemeDialogOpen] = useState(false);
    const [isLayoutDialogOpen, setIsLayoutDialogOpen] = useState(false);
    const [isAboutDialogOpen, setIsAboutlogDialogOpen] = useState(false);

    useLayoutEffect(() => {
        (async () => {
            let anime_watching = 0;
            let anime_completed = 0;
            let anime_on_hold = 0;
            let anime_dropped = 0;
            let anime_plan_to_watch = 0;

            const animes = await database.animes.toArray();
            animes.forEach((anime) => {
                switch (anime.status) {
                    case "watching":
                        anime_watching++;
                        break;
                    case "completed":
                        anime_completed++;
                        break;
                    case "on_hold":
                        anime_on_hold++;
                        break;
                    case "dropped":
                        anime_dropped++;
                        break;
                    case "plan_to_watch":
                        anime_plan_to_watch++;
                        break;
                }
            });

            const anime_all = anime_watching + anime_completed + anime_on_hold + anime_dropped + anime_plan_to_watch;

            let manga_reading = 0;
            let manga_completed = 0;
            let manga_on_hold = 0;
            let manga_dropped = 0;
            let manga_plan_to_read = 0;

            const mangas = await database.mangas.toArray();
            mangas.forEach((manga) => {
                switch (manga.status) {
                    case "reading":
                        manga_reading++;
                        break;
                    case "completed":
                        manga_completed++;
                        break;
                    case "on_hold":
                        manga_on_hold++;
                        break;
                    case "dropped":
                        manga_dropped++;
                        break;
                    case "plan_to_read":
                        manga_plan_to_read++;
                        break;
                }
            });

            const manga_all = manga_reading + manga_completed + manga_on_hold + manga_dropped + manga_plan_to_read;

            setUserState({
                anime: { all: anime_all, watching: anime_watching, completed: anime_completed, on_hold: anime_on_hold, dropped: anime_dropped, plan_to_watch: anime_plan_to_watch },
                manga: { all: manga_all, reading: manga_reading, completed: manga_completed, on_hold: manga_on_hold, dropped: manga_dropped, plan_to_read: manga_plan_to_read }
            });
        })();

        /*setNsfw(get_nsfw);
        setNotifications(get_notifications);
        setCanSwipe(get_can_swipe);
        setLayout(get_layout);*/

        if (get_notifications() && "Notification" in window && Notification.permission !== "granted") {
            setNotifications(false);
        }

        /*if (("Notification" in window)) {
            if (Notification.permission === "granted") {
                setNotifications(true);
            } else {
                setNotifications(false);
            }
        } else {
            setNotifications(false);
        }*/

        updateThemeMeta();
    }, []);

    /*useEffect(() => {
        if (notifications) {
            // Subscribe
            getToken(window.messaging, { vapidKey: Constants.VAPID_KEY }).then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken);
                    fetch(`/api/notifications/subscribe?token=${currentToken}&topic=all`).then((response) => {
                        console.log(response.status);
                        if (!response.ok) {
                            changeNotifications(false);
                        }
                    });
                }
            });
        } else {
            // Unsubscribe
            getToken(window.messaging, { vapidKey: Constants.VAPID_KEY }).then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken);
                    fetch(`/api/notifications/unsubscribe?token=${currentToken}&topic=all`).then((response) => {
                        console.log(response.status);
                    });
                }
            });
        }
    }, [notifications]);*/

    const changeNsfw = async (checked) => {
        setNsfw(checked);
        set_nsfw(checked);
    }

    const subscribeNotifications = () => {
        getToken(window.messaging, { vapidKey: Constants.VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
                fetch(`/api/notifications/subscribe?token=${currentToken}&topic=all`).then((response) => {
                    if (!response.ok) {
                        changeNotifications(false, false);
                    }
                });
            }
        });
    }

    const unsubscribeNotifications = () => {
        getToken(window.messaging, { vapidKey: Constants.VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
                fetch(`/api/notifications/unsubscribe?token=${currentToken}&topic=all`).then((response) => {
                    if (!response.ok) {
                        changeNotifications(true, false);
                    }
                });
            }
        });
    }

    const changeNotifications = async (checked, repeat = true) => {
        const switchNotifications = (boolean) => {
            setNotifications(boolean);
            set_notifications(boolean);

            if (boolean) {
                subscribeNotifications();
            } else {
                unsubscribeNotifications();
            }
        }
        if (Notification.permission === "granted") {
            switchNotifications(checked);
        } else {
            Notification.requestPermission(() => {
                if (Notification.permission === "granted") {
                    switchNotifications(checked);
                } else {
                    setNotifications(false);
                }
            });
        }
        /*if (checked) {
            if (Notification.permission === "granted") {
                switchNotifications(true);
            } else {
                Notification.requestPermission(() => {
                    if (Notification.permission === "granted") {
                        switchNotifications(true);
                    } else {
                        switchNotifications(false);
                    }
                });
            }
        } else {
            switchNotifications(false);
        }*/
    }

    const changeCanSwipe = async (checked) => {
        setCanSwipe(checked);
        set_can_swipe(checked);
    }

    const changePageTransition = async (checked) => {
        setPageTransition(checked);
        set_page_transition(checked);
    }

    const onChangeTheme = async (changeTheme, theme) => {
        setIsThemeDialogOpen(false);
        changeTheme(theme);
    }

    const onChangeLayout = async (layout) => {
        setIsLayoutDialogOpen(false);
        setLayout(layout);
        set_layout(layout);
    }

    const logout = async () => {
        const response = await fetch("/api/logout");
        if (response.ok) {
            changeUserData(null);
        }
    }

    const onClick = (path) => {
        openPage(() => { navigate(path) });
    }

    return (
        <div className="flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900">
            <div className="flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <h2 className="flex-1 pl-4 xl:pl-0 font-bold text-xl4">Profile</h2>
                    {/* <IconApps className="block md:hidden w-14 h-14 p-4" onClick={() => { }} /> */}
                </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
                <div className="max-w-screen-xl w-full h-full md:px-4 xl:px-0 py-4 pb-4 space-y-4 mx-auto">
                    <div className="flex flex-col items-center py-4 font-medium text-dark dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
                        {/* <img
                        className="w-16 h-16 mt-2 border border-gray-300 dark:border-gray-700 rounded-mdd"
                        src={userData.picture}
                        alt="profile"
                        style={{ clipPath: "path('M93.6797 5.15479C97.359 2.15401 102.641 2.15401 106.32 5.15479L114.253 11.625C116.75 13.6608 120.074 14.3696 123.183 13.5291L133.039 10.8652C137.616 9.62811 142.432 11.775 144.572 16.0058L149.229 25.2154C150.679 28.0814 153.416 30.0768 156.588 30.5793L166.717 32.184C171.384 32.9234 174.897 36.829 175.139 41.5483L175.672 51.9384C175.836 55.133 177.519 58.0565 180.199 59.8033L188.865 65.452C192.811 68.0247 194.427 73.0033 192.743 77.4035L189.015 87.1465C187.874 90.1271 188.225 93.4731 189.958 96.1526L195.618 104.899C198.175 108.852 197.629 114.053 194.306 117.389L186.976 124.748C184.722 127.011 183.684 130.214 184.184 133.369L185.815 143.668C186.552 148.327 183.933 152.868 179.531 154.564L169.921 158.264C166.93 159.416 164.672 161.931 163.849 165.029L161.188 175.047C159.973 179.623 155.716 182.719 150.989 182.466L140.78 181.92C137.567 181.748 134.468 183.132 132.452 185.639L126.014 193.647C123.04 197.346 117.876 198.445 113.653 196.277L104.567 191.612C101.7 190.141 98.2999 190.141 95.4331 191.612L86.3465 196.277C82.1243 198.445 76.9601 197.346 73.9862 193.647L67.548 185.639C65.5321 183.132 62.433 181.748 59.2203 181.92L49.0111 182.466C44.284 182.719 40.0271 179.623 38.8119 175.047L36.1507 165.029C35.3279 161.931 33.0702 159.416 30.0792 158.264L20.4691 154.564C16.0666 152.868 13.4477 148.327 14.1855 143.668L15.8162 133.369C16.3157 130.214 15.2781 127.011 13.024 124.748L5.69367 117.389C2.37113 114.053 1.82509 108.852 4.38248 104.899L10.0415 96.1526C11.7752 93.4731 12.1257 90.1271 10.9851 87.1465L7.25661 77.4035C5.57276 73.0033 7.18856 68.0247 11.1354 65.452L19.8014 59.8033C22.4812 58.0565 24.1636 55.133 24.3275 51.9384L24.8607 41.5483C25.1029 36.829 28.6155 32.9234 33.2828 32.184L43.4118 30.5793C46.5838 30.0768 49.3214 28.0814 50.7708 25.2154L55.4283 16.0058C57.5679 11.775 62.3844 9.6281 66.9613 10.8652L76.8169 13.5291C79.9264 14.3696 83.2504 13.6608 85.7465 11.625L93.6797 5.15479Z')" }}
                    /> */}
                        <div className="relative w-32 h-32 mt-2">
                            {/* <svg viewBox="0 0 200 200" className="absolute top-0 left-0 w-24 h-24">
                            <defs>
                                <clipPath id="shape">
                                    <path d="M71.5579 16.3347C84.3365 -5.4449 115.825 -5.44489 128.603 16.3347L129.067 17.1257C134.963 27.1733 145.709 33.378 157.358 33.4596L158.276 33.466C183.527 33.6428 199.271 60.9123 186.798 82.8687L186.345 83.6661C180.591 93.7953 180.591 106.205 186.345 116.334L186.798 117.131C199.271 139.088 183.527 166.357 158.276 166.534L157.358 166.54C145.709 166.622 134.963 172.827 129.067 182.874L128.603 183.665C115.825 205.445 84.3365 205.445 71.5579 183.665L71.0938 182.874C65.1986 172.827 54.4517 166.622 42.8027 166.54L41.8856 166.534C16.6346 166.357 0.890585 139.088 13.3629 117.131L13.8159 116.334C19.5698 106.205 19.5698 93.7953 13.8159 83.6661L13.3629 82.8687C0.890585 60.9123 16.6346 33.6428 41.8856 33.466L42.8027 33.4596C54.4518 33.378 65.1986 27.1733 71.0938 17.1257L71.5579 16.3347Z" />
                                </clipPath>
                            </defs>
                            <rect width="100%" height="100%" clipPath="url(#shape)" className="fill-gray-200 dark:fill-gray-700" />
                        </svg> */}
                            <svg viewBox="0 0 200 200" className="absolute top-0 left-0 w-full h-full drop-shadow">
                                <defs>
                                    <clipPath id="shape-1">
                                        <path d="M93.6797 5.15479C97.359 2.15401 102.641 2.15401 106.32 5.15479L114.253 11.625C116.75 13.6608 120.074 14.3696 123.183 13.5291L133.039 10.8652C137.616 9.62811 142.432 11.775 144.572 16.0058L149.229 25.2154C150.679 28.0814 153.416 30.0768 156.588 30.5793L166.717 32.184C171.384 32.9234 174.897 36.829 175.139 41.5483L175.672 51.9384C175.836 55.133 177.519 58.0565 180.199 59.8033L188.865 65.452C192.811 68.0247 194.427 73.0033 192.743 77.4035L189.015 87.1465C187.874 90.1271 188.225 93.4731 189.958 96.1526L195.618 104.899C198.175 108.852 197.629 114.053 194.306 117.389L186.976 124.748C184.722 127.011 183.684 130.214 184.184 133.369L185.815 143.668C186.552 148.327 183.933 152.868 179.531 154.564L169.921 158.264C166.93 159.416 164.672 161.931 163.849 165.029L161.188 175.047C159.973 179.623 155.716 182.719 150.989 182.466L140.78 181.92C137.567 181.748 134.468 183.132 132.452 185.639L126.014 193.647C123.04 197.346 117.876 198.445 113.653 196.277L104.567 191.612C101.7 190.141 98.2999 190.141 95.4331 191.612L86.3465 196.277C82.1243 198.445 76.9601 197.346 73.9862 193.647L67.548 185.639C65.5321 183.132 62.433 181.748 59.2203 181.92L49.0111 182.466C44.284 182.719 40.0271 179.623 38.8119 175.047L36.1507 165.029C35.3279 161.931 33.0702 159.416 30.0792 158.264L20.4691 154.564C16.0666 152.868 13.4477 148.327 14.1855 143.668L15.8162 133.369C16.3157 130.214 15.2781 127.011 13.024 124.748L5.69367 117.389C2.37113 114.053 1.82509 108.852 4.38248 104.899L10.0415 96.1526C11.7752 93.4731 12.1257 90.1271 10.9851 87.1465L7.25661 77.4035C5.57276 73.0033 7.18856 68.0247 11.1354 65.452L19.8014 59.8033C22.4812 58.0565 24.1636 55.133 24.3275 51.9384L24.8607 41.5483C25.1029 36.829 28.6155 32.9234 33.2828 32.184L43.4118 30.5793C46.5838 30.0768 49.3214 28.0814 50.7708 25.2154L55.4283 16.0058C57.5679 11.775 62.3844 9.6281 66.9613 10.8652L76.8169 13.5291C79.9264 14.3696 83.2504 13.6608 85.7465 11.625L93.6797 5.15479Z" />
                                    </clipPath>
                                    <clipPath id="shape-2">
                                        <path d="M71.5579 16.3347C84.3365 -5.4449 115.825 -5.44489 128.603 16.3347L129.067 17.1257C134.963 27.1733 145.709 33.378 157.358 33.4596L158.276 33.466C183.527 33.6428 199.271 60.9123 186.798 82.8687L186.345 83.6661C180.591 93.7953 180.591 106.205 186.345 116.334L186.798 117.131C199.271 139.088 183.527 166.357 158.276 166.534L157.358 166.54C145.709 166.622 134.963 172.827 129.067 182.874L128.603 183.665C115.825 205.445 84.3365 205.445 71.5579 183.665L71.0938 182.874C65.1986 172.827 54.4517 166.622 42.8027 166.54L41.8856 166.534C16.6346 166.357 0.890585 139.088 13.3629 117.131L13.8159 116.334C19.5698 106.205 19.5698 93.7953 13.8159 83.6661L13.3629 82.8687C0.890585 60.9123 16.6346 33.6428 41.8856 33.466L42.8027 33.4596C54.4518 33.378 65.1986 27.1733 71.0938 17.1257L71.5579 16.3347Z" />
                                    </clipPath>
                                    <clipPath id="shape-3">
                                        <path d="M29.2893 29.2893C19.2658 39.3128 15.6458 53.315 18.4294 66.2123C7.34132 73.3638 0 85.8246 0 100C-1.74156e-06 114.175 7.34132 126.636 18.4294 133.788C15.6458 146.685 19.2658 160.687 29.2893 170.711C39.3129 180.734 53.315 184.354 66.2123 181.571C73.3639 192.659 85.8246 200 100 200C114.175 200 126.636 192.659 133.788 181.571C146.685 184.354 160.687 180.734 170.711 170.711C180.734 160.687 184.354 146.685 181.571 133.788C192.659 126.636 200 114.175 200 100C200 85.8246 192.659 73.3638 181.571 66.2123C184.354 53.315 180.734 39.3129 170.711 29.2893C160.687 19.2658 146.685 15.6458 133.788 18.4294C126.636 7.34133 114.175 0 100 0C85.8246 0 73.3638 7.34131 66.2123 18.4293C53.315 15.6458 39.3129 19.2658 29.2893 29.2893Z" />
                                    </clipPath>
                                </defs>
                                <image href={userData.picture ? userData.picture : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} height="100%" width="100%" clipPath="url(#shape-1)" preserveAspectRatio="xMidYMid slice" />
                            </svg>
                            {userData.is_supporter && <div className="absolute bottom-0 left-10 right-10 pt-0.5 pb-px font-bol text-xs text-center uppercase text-white bg-blue-600 rounded-full shadow">VIP</div>}
                        </div>
                        <p className="mt-4 font-bold text-lg">{userData.name}</p>
                        <div className="flex space-x-4 font-medium text-gray-600 dark:text-gray-400">
                            {userData.location && <div className="flex items-center"><IconMapPin className="w-4 h-4 mr-2" stroke={2} />{userData.location}</div>}
                            {userData.birthday && <div className="flex items-center"><IconCalendarEvent className="w-4 h-4 mr-2" stroke={2} />{DateTime.fromISO(userData.birthday).toFormat("DD")}</div>}
                        </div>
                        {/* <p className="text-smh text-gray-600 dark:text-gray-400">{format_userdata(userData)}</p> */}
                    </div>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                        <div className="flex-1/2 w-full p-4 bg-white dark:bg-gray-800 borde border-gray-700 shadow md:rounded">
                            <div className="flex mb-4 font-bold">
                                <p className="flex-1">Anime Statitics</p>
                                <p className="flex-none">{userState.anime.all}</p>
                            </div>
                            {userState.anime.all === 0 ?
                                <div className="flex w-full h-2 space-x-1">
                                    <div className="flex-1 h-full bg-green-600 dark:bg-green-400 rounded"></div>
                                    <div className="flex-1 h-full bg-blue-600 dark:bg-blue-400 rounded"></div>
                                    <div className="flex-1 h-full bg-orange-600 dark:bg-orange-400 rounded"></div>
                                    <div className="flex-1 h-full bg-red-600 dark:bg-red-400 rounded"></div>
                                    <div className="flex-1 h-full bg-gray-600 dark:bg-gray-400 rounded"></div>
                                </div>
                                :
                                <div className="flex w-full h-2 space-x-1">
                                    <motion.div className="min-w-1px h-full bg-green-600 dark:bg-green-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.anime.watching / userState.anime.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-blue-600 dark:bg-blue-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.anime.completed / userState.anime.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-orange-600 dark:bg-orange-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.anime.on_hold / userState.anime.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-red-600 dark:bg-red-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.anime.dropped / userState.anime.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-gray-600 dark:bg-gray-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.anime.plan_to_watch / userState.anime.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                </div>
                            }
                            <div className="mt-4 space-y-2 font-medium text-base text-gray-600 dark:text-gray-400">
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-green-600 dark:bg-green-400 rounded"></div>
                                    <p className="flex-1">Watching</p>
                                    <p className="flex-none">{userState.anime.watching}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-blue-600 dark:bg-blue-400 rounded"></div>
                                    <p className="flex-1">Completed</p>
                                    <p className="flex-none">{userState.anime.completed}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-orange-600 dark:bg-orange-400 rounded"></div>
                                    <p className="flex-1">On-Hold</p>
                                    <p className="flex-none">{userState.anime.on_hold}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-red-600 dark:bg-red-400 rounded"></div>
                                    <p className="flex-1">Dropped</p>
                                    <p className="flex-none">{userState.anime.dropped}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-gray-600 dark:bg-gray-400 rounded"></div>
                                    <p className="flex-1">Plan To Watch</p>
                                    <p className="flex-none">{userState.anime.plan_to_watch}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1/2 w-full p-4 bg-white dark:bg-gray-800 borde border-gray-700 shadow md:rounded">
                            <div className="flex mb-4 font-bold">
                                <p className="flex-1">Manga Statitics</p>
                                <p className="flex-none">{userState.manga.all}</p>
                            </div>
                            {userState.manga.all === 0 ?
                                <div className="flex w-full h-2 space-x-1">
                                    <div className="flex-1 h-full bg-green-600 dark:bg-green-400 rounded"></div>
                                    <div className="flex-1 h-full bg-blue-600 dark:bg-blue-400 rounded"></div>
                                    <div className="flex-1 h-full bg-orange-600 dark:bg-orange-400 rounded"></div>
                                    <div className="flex-1 h-full bg-red-600 dark:bg-red-400 rounded"></div>
                                    <div className="flex-1 h-full bg-gray-600 dark:bg-gray-400 rounded"></div>
                                </div>
                                :
                                <div className="flex w-full h-2 space-x-1">
                                    <motion.div className="min-w-1px h-full bg-green-600 dark:bg-green-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.manga.reading / userState.manga.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-blue-600 dark:bg-blue-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.manga.completed / userState.manga.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-orange-600 dark:bg-orange-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.manga.on_hold / userState.manga.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-red-600 dark:bg-red-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.manga.dropped / userState.manga.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                    <motion.div className="min-w-1px h-full bg-gray-600 dark:bg-gray-400 rounded" initial={{ width: "20%" }} whileInView={{ width: `${userState.manga.plan_to_read / userState.manga.all * 100}%` }} viewport={{ once: true }}></motion.div>
                                </div>
                            }
                            <div className="mt-4 space-y-2 font-medium text-base text-gray-600 dark:text-gray-400">
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-green-600 dark:bg-green-400 rounded"></div>
                                    <p className="flex-1">Reading</p>
                                    <p className="flex-none">{userState.manga.reading}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-blue-600 dark:bg-blue-400 rounded"></div>
                                    <p className="flex-1">Completed</p>
                                    <p className="flex-none">{userState.manga.completed}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-orange-600 dark:bg-orange-400 rounded"></div>
                                    <p className="flex-1">On-Hold</p>
                                    <p className="flex-none">{userState.manga.on_hold}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-red-600 dark:bg-red-400 rounded"></div>
                                    <p className="flex-1">Dropped</p>
                                    <p className="flex-none">{userState.manga.dropped}</p>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-none w-1 h-4 mr-4 bg-gray-600 dark:bg-gray-400 rounded"></div>
                                    <p className="flex-1">Plan To Read</p>
                                    <p className="flex-none">{userState.manga.plan_to_read}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="px-4 md:px-0 pt-4 font-bold text-dark dark:text-white">
                        App Settings
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ThemeContext.Consumer>
                            {({ theme, changeTheme }) => (
                                <div className="flex items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={() => { setIsThemeDialogOpen(true) }}>
                                    <IconBrightness />
                                    <p className="flex-1 ml-8">Theme</p>
                                    <p className="w-20 py-1 text-xs text-center text-whit text-gray-600 dark:text-gray-400 uppercase bg-gray-100 dark:bg-gray-900 rounded-full">{theme === "0" ? "System" : theme === "1" ? "Light" : "Dark"}</p>
                                </div>
                            )}
                        </ThemeContext.Consumer>
                        <div className="flex items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={() => { setIsLayoutDialogOpen(true) }}>
                            <IconLayout2 />
                            <p className="flex-1 ml-8">Layout</p>
                            <p className="w-20 py-1 text-xs text-center text-whit text-gray-600 dark:text-gray-400 uppercase bg-gray-100 dark:bg-gray-900 rounded-full">{layout}</p>
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
                            <IconRating18Plus />
                            <p className="flex-1 ml-8">NSFW</p>
                            {/* <Select options={["Show", "Hide"]} selected={1} onSelect={(selected) => { }} className={"w-32 rounded-md"} /> */}
                            {/* <label class="relative inline-block w-16 h-8">
                        <input type="checkbox" className="peer sr-only" checke={false}/>
                        <span class="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 rounded-full transition-colors before:absolute before:left-1.5 before:top-1.5 before:content-[''] before:w-5 before:h-5 before:bg-white before:rounded-full eer-checked:before:left-auto eer-checked:before:right-1.5 peer-checked:before:translate-x-8 peer-checked:bg-blue-600 before:transition-transform cursor-pointer"></span>
                    </label> */}
                            <Switch checked={nsfw} onChange={(checked) => { changeNsfw(checked) }} />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-dark dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
                            <IconBell />
                            <p className="flex-1 ml-8">Notifications</p>
                            <Switch checked={notifications} onChange={(checked) => { changeNotifications(checked) }} />
                        </div>
                        <div className="flex md:hidden items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
                            <IconHandMove />
                            <p className="flex-1 ml-8">My List - Swipe</p>
                            {/* <Select options={["Enable", "Disable"]} selected={notifications} onSelect={(selected) => { }} className={"w-32 rounded-md"} /> */}
                            <Switch checked={canSwipe} onChange={(checked) => { changeCanSwipe(checked) }} />
                        </div>
                        <div className="flex md:hidden items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
                            <IconSwipe />
                            <p className="flex-1 ml-8">Page Transition</p>
                            <Switch checked={pageTransition} onChange={(checked) => { changePageTransition(checked) }} />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={() => { window.open("https://discord.gg/WEZFPsNy3m") }}>
                            <IconBrandDiscord />
                            <p className="flex-1 ml-8">Discord Server</p>
                            <IconExternalLink />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={() => { window.open("/privacy-policy.html") }}>
                            <IconNotes />
                            <p className="flex-1 ml-8">Privacy Policy</p>
                            <IconExternalLink />
                        </div>
                        {/* <div className="flex items-center h-14 px-4 font-medium text-dark dark:text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={() => { window.open("/privacy-policy.html") }}>
                            <IconNotes />
                            <p className="flex-1 ml-8">Privacy Policy</p>
                            <IconExternalLink />
                        </div> */}
                        <div className="flex items-center h-14 px-4 font-medium text-gray-900 dark:text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={() => { setIsAboutlogDialogOpen(true) }}>
                            <IconInfoCircle />
                            <p className="flex-1 ml-8">About</p>
                            <IconChevronRight />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-red-600 bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" onClick={logout}>
                            <IconLogout />
                            <p className="flex-1 ml-8">Logout</p>
                            <IconChevronRight />
                        </div>
                    </div>
                    {userData.id === 5685612 && <>
                        <h2 className="px-4 md:px-0 pt-4 font-bold text-dark dark:text-white">
                            Admin Tools
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div onClick={() => { onClick("../activity") }} className="flex items-center h-14 px-4 font-medium text-dark dark:text-white bg-white dark:bg-gray-800 shadow md:rounded">
                                <IconActivity />
                                <p className="flex-1 ml-8">Activity</p>
                                <IconChevronRight />
                            </div>
                        </div>
                    </>}
                    {/* <h2 className="px-4 md:px-0 pt-4 font-bold text-dark dark:text-white">
                        Support Us
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex items-center h-14 px-4 font-medium text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" style={{ backgroundColor: "#5865f2" }} onClick={() => { window.open("https://discord.gg/WEZFPsNy3m") }}>
                            <IconBrandDiscord />
                            <p className="flex-1 ml-8">Discord Server</p>
                            <IconExternalLink />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" style={{ backgroundColor: "#333333" }} onClick={() => { window.open("https://github.com/sponsors/Porush") }}>
                            <IconBrandGithub />
                            <p className="flex-1 ml-8">Github Sponsor</p>
                            <IconExternalLink />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-white bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" style={{ backgroundColor: "#ff424d" }} onClick={() => { window.open("https://patreon.com/porush") }}>
                            <IconBrandPatreon />
                            <p className="flex-1 ml-8">Patreon</p>
                            <IconExternalLink />
                        </div>
                        <div className="flex items-center h-14 px-4 font-medium text-black bg-white dark:bg-gray-800 shadow md:rounded cursor-pointer" style={{ backgroundColor: "#ffdd00" }} onClick={() => { window.open("https://buymeacoffee.com/porush") }}>
                            <IconCoffee />
                            <p className="flex-1 ml-8">Buy Me A Coffee</p>
                            <IconExternalLink />
                        </div>
                    </div> */}
                    <div className="pt-4 pb-8 text-center">
                        <p className="font-bold text-gray-900 dark:text-white">AniDex</p>
                        <p className="text-sm text-gray-600 dark:text-gray-400">{get_full_version()}</p>
                    </div>
                </div>
            </div>
            <Dialog isOpen={isThemeDialogOpen} onClose={() => setIsThemeDialogOpen(false)}>
                <ThemeContext.Consumer>
                    {({ theme, changeTheme }) => (
                        <div className="w-full px-2 pt-4 pb-3">
                            <p className="px-4 mb-2 font-bold text-lg text-blue-600">Theme</p>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onChangeTheme(changeTheme, "0") }}>
                                <IconSettings className="w-5 h-5 mr-4" />
                                <p className="flex-1 py-1 font-medium">System</p>
                                {theme === "0" ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                            </div>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onChangeTheme(changeTheme, "1") }}>
                                <IconSun className="w-5 h-5 mr-4" />
                                <p className="flex-1 py-1 font-medium">Light</p>
                                {theme === "1" ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                            </div>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onChangeTheme(changeTheme, "2") }}>
                                <IconMoon className="w-5 h-5 mr-4" />
                                <p className="flex-1 py-1 font-medium">Dark</p>
                                {theme === "2" ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                            </div>
                        </div>
                    )}
                </ThemeContext.Consumer>
            </Dialog>
            <Dialog isOpen={isLayoutDialogOpen} onClose={() => setIsLayoutDialogOpen(false)}>
                <div className="w-full px-2 pt-4 pb-3">
                    <p className="px-4 mb-2 font-bold text-lg text-blue-600">Layout</p>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onChangeLayout(Constants.LAYOUT_LIST) }}>
                        <IconLayoutList className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">List</p>
                        {layout === Constants.LAYOUT_LIST ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onChangeLayout(Constants.LAYOUT_GRID) }}>
                        <IconLayoutGrid className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Grid</p>
                        {layout === Constants.LAYOUT_GRID ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                </div>
            </Dialog>
            <Dialog isOpen={isAboutDialogOpen} onClose={() => setIsAboutlogDialogOpen(false)}>
                <div className="px-2 pt-4 pb-3 text-center text-gray-900">
                    <p className="px-4 py-2 mb-3 font-bold text-lg text-blue-600">About</p>
                    <p className="px-4 pb-6 text-justify font-medium text-gray-600">AniDex (anidex.app) is a cross-platform web client for MyAnimeList, It makes it easy for you to access and update your MyAnimeList data.</p>
                </div>
            </Dialog>
        </div >
    );
}

function Accordian({ icon, title, content, open = true, className = "" }) {
    const [isOpen, setIsOpen] = useState(open);

    return (
        <motion.div className={"bg-white dark:bg-gray-800 " + className} layout="position">
            <div
                className="flex h-14 p-4 shadow"
                onClick={() => setIsOpen(!isOpen)}
            >
                {icon}
                <p className="flex-1 ml-8 font-medium">{title}</p>
                <IconChevronUp className={(isOpen ? "rotate-180" : "") + " transition-transform"} />
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="border-t border-gray-200 dark:border-gray-700"
                    >
                        {content}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}
