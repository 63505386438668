import { useRef } from "react";

export default (input) => {
    const touchData = useRef({
        touchStart: 0,
        touchEnd: 0
    });

    const minSwipeDistance = 75;

    const onTouchStart = (e) => {
        touchData.current.touchEnd = 0; // otherwise the swipe is fired even with usual touch events
        touchData.current.touchStart = e.targetTouches[0].clientX;
    }

    const onTouchMove = (e) => { touchData.current.touchEnd = e.targetTouches[0].clientX }

    const onTouchEnd = () => {
        if (!touchData.current.touchStart || !touchData.current.touchEnd) return;
        const distance = touchData.current.touchStart - touchData.current.touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            input.onSwipedLeft();
        } else if (isRightSwipe) {
            input.onSwipedRight();
        }
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}