import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    forwardRef,
    useState,
    useMemo,
    memo,
    usePrevious,
    useContext
} from "react";
import { motion, LayoutGroup, useAnimation, AnimatePresence, useScroll, useTransform, useWillChange, usePresence } from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    IconX,
    IconArrowLeft,
    IconPlaylistAdd,
    IconPlayerPlay,
    IconChevronUp,
    IconPencil,
    IconShare,
    IconCaretLeft,
    IconCaretRight,
    IconExposureMinus1,
    IconExposurePlus1,
    IconLoader2,
    IconDownload
} from "@tabler/icons-react";
import { PlayIcon as PlayIconSolid } from "@heroicons/react/24/solid";

import { Img } from "../Components/Placeholders";
import ServerError from "../Components/ServerError";
import UnknownError from "../Components/UnknownError";
import NetworkError from "../Components/NetworkError";
import { database } from "../Database";
import { animatePageTransition, capitalizeFirstLetter, closePage, formatAnimeStatus, formatArray, formatAuthors, formatDate, formatDuration, formatEpisodes, formatMean, formatMediaType, formatRating, formatScore, formatSerialization, formatSource, formatStatus, formateNumber, get_nsfw, is_mobile, log, openPage, page_variants, parseAnimes, parseMangas, updateAnime, updateManga, updateThemeMeta } from "../Helpers/Utils";
import Select from "../Components/Select";
import { Constants } from "../Helpers/Constants";
import Loading from "../Components/Loading";
import Image from "../Components/Image";
import { UserContext } from "../Contexts/UserContext";
import Anime from "./Anime";
import { DateTime } from "luxon";
import AnimeItem from "../Components/AnimeItem";
import MangaItem from "../Components/MangaItem";
import MyanimelistError from "../Components/MyanimelistError";
import Characters from "../Components/Characters";

function Animes({ data }) {
    const [items, setItems] = useState([]);
    const [canScroll, setCanScroll] = useState(true);
    const ref = useRef();

    useLayoutEffect(() => {
        const offsetWidth = ref.current.offsetWidth;
        let length = 0;
        for (const item of data) {
            if (item)
                length = length + 1;
        }
        const dataWidth = (128 * length) + (length > 1 ? length + 4 * 15 : 0);

        if (dataWidth < offsetWidth) {
            setItems([...data, undefined, undefined, undefined, undefined, undefined, undefined]);
            setCanScroll(false);
        } else {
            setItems(data);
        }
    }, [data]);

    function previous() {
        if (canScroll) {
            ref.current.scrollBy({
                left: -1,
                behavior: 'smooth'
            });
        }
    }

    function next() {
        if (canScroll) {
            ref.current.scrollBy({
                left: 1,
                behavior: 'smooth'
            });
        }
    }

    return (
        <div className="relative group/item mt-4 mb-2">
            <div ref={ref} className={"relative w-full flex px-4 pb-2 gap-4 snap-x snap-mandatory " + (canScroll ? "overflow-x-auto" : "overflow-x-hidden")}>
                {items.map((item, index) => (
                    <AnimeItem key={index} data={item} />
                ))}
            </div>
            <div onClick={() => { previous() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:translate-x-2 absolute top-24 left-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretLeft className="w-full h-full p-1 fill-black" />
            </div>
            <div onClick={() => { next() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:-translate-x-2 absolute top-24 right-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretRight className="w-full h-full p-1 fill-black" />
            </div>
        </div>
    );
}

function Mangas({ data }) {
    const [items, setItems] = useState([]);
    const [canScroll, setCanScroll] = useState(true);
    const ref = useRef();

    useLayoutEffect(() => {
        const offsetWidth = ref.current.offsetWidth;
        let length = 0;
        for (const item of data) {
            if (item)
                length = length + 1;
        }
        const dataWidth = (128 * length) + (length > 1 ? length + 4 * 15 : 0);

        if (dataWidth < offsetWidth) {
            setItems([...data, undefined, undefined, undefined, undefined, undefined, undefined]);
            setCanScroll(false);

            //ref.current.classList.add("overflow-x-hidden");
            //ref.current.classList.remove("overflow-x-auto");
            //const count = ((offsetWidth - dataWidth) / 128);
            /*for (let i = 0; i < 6; i++) {
                data.push(null);
            }*/
            //log("count", count);
        } else {
            setItems(data);
        }
    }, [data]);

    function previous() {
        if (canScroll) {
            ref.current.scrollBy({
                left: -1,
                behavior: 'smooth'
            });
        }
    }

    function next() {
        if (canScroll) {
            ref.current.scrollBy({
                left: 1,
                behavior: 'smooth'
            });
        }
    }

    return (
        <div className="relative group/item mt-4 mb-2">
            <div ref={ref} className={"relative w-full flex px-4 pb-2 gap-4 snap-x snap-mandatory " + (canScroll ? "overflow-x-auto" : "overflow-x-hidden")}>
                {items.map((item, index) => (
                    <MangaItem key={index} data={item} />
                ))}
            </div>
            <div onClick={() => { previous() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:translate-x-2 absolute top-24 left-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretLeft className="w-full h-full p-1 fill-black" />
            </div>
            <div onClick={() => { next() }} className="touch transition-transform invisible group-hover/item:visible group-hover/item:-translate-x-2 absolute top-24 right-4 -translate-y-1/2 w-6 h-14 text-black bg-white/40 backdrop-blur-md shadow-md rounded-md cursor-pointer">
                <IconCaretRight className="w-full h-full p-1 fill-black" />
            </div>
        </div>
    );
}

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

export default function Manga() {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const userContext = useContext(UserContext);

    const [manga, setManga] = useState(null);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const ref = useRef();

    const [width, setWidth] = useState(window.innerWidth);

    const [picturesOpen, setPicturesOpen] = useState(false);
    const [[page, direction], setPage] = useState([0, 0]);

    useLayoutEffect(() => {
        return () => {
            //animatePageTransition();
        }
    }, []);

    useLayoutEffect(() => {
        if (ref.current)
            //ref.current.scrollTo({ top: 0, behavior: 'smooth' });
            ref.current.scrollTo({ top: 0 });

        setSaving(false);
        setManga(null);
        setError(null);
    }, [id]);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const update = () => {
            fetch(`/api/manga?id=${id}&nsfw=${get_nsfw()}`)
                .then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            //log(json.recommendations);

                            const tempAnime = {};

                            tempAnime.title = json.title;

                            tempAnime.main_picture = {};
                            if (json.main_picture) {
                                tempAnime.main_picture.medium = json.main_picture.medium;
                                tempAnime.main_picture.large = json.main_picture.large;
                            } else {
                                tempAnime.main_picture.medium = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
                                tempAnime.main_picture.large = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
                            }

                            tempAnime.pictures = [];
                            if (json.pictures) {
                                for (const picture of json.pictures) {
                                    if (tempAnime.main_picture.large === picture.large) {
                                        tempAnime.pictures.unshift(picture.large);
                                    } else {
                                        tempAnime.pictures.push(picture.large);
                                    }

                                    /*tempAnime.pictures.unshift(picture.large);
                                    const index = tempAnime.pictures.indexOf(tempAnime.main_picture.large);
                                    if (index != -1) {
                                        tempAnime.pictures.splice(index, 1);
                                        tempAnime.pictures.unshift(tempAnime.main_picture.large);
                                    }*/
                                }
                            }

                            tempAnime.media_type = json.media_type;
                            tempAnime.synopsis = json.synopsis ? json.synopsis : "No synopsis information has been added to this title.";
                            tempAnime.background = json.background;
                            tempAnime.status = json.status;
                            tempAnime.num_volumes = json.num_volumes;
                            tempAnime.num_chapters = json.num_chapters;
                            tempAnime.mean = json.mean;
                            tempAnime.nsfw = json.nsfw;
                            tempAnime.start_date = json.start_date;
                            tempAnime.end_date = json.end_date;

                            tempAnime.authors = json.authors;
                            tempAnime.source = json.source;
                            tempAnime.genres = json.genres;
                            tempAnime.theme = json.theme;
                            tempAnime.demographic = json.demographic;
                            tempAnime.rating = json.rating;
                            tempAnime.serialization = json.serialization;

                            tempAnime.related_anime = parseAnimes(json.related_anime);
                            tempAnime.related_manga = parseMangas(json.related_manga);
                            tempAnime.recommendations = parseMangas(json.recommendations);

                            if (json.my_list_status) {
                                tempAnime.my_list_status = json.my_list_status
                            } else {
                                tempAnime.my_list_status = {};
                            }

                            tempAnime.mean = json.mean;
                            tempAnime.rank = json.rank;
                            tempAnime.popularity = json.popularity;
                            tempAnime.num_list_users = json.num_list_users;
                            tempAnime.num_favorites = json.num_favorites;
                            tempAnime.num_scoring_users = json.num_scoring_users;

                            tempAnime.alternative_titles = {};
                            tempAnime.alternative_titles.synonyms = json.alternative_titles.synonyms;
                            tempAnime.alternative_titles.en = json.alternative_titles.en;
                            tempAnime.alternative_titles.ja = json.alternative_titles.ja;
                            
                            /*tempAnime.characters = [];
                            if (json.characters) {
                                for (const character of json.characters) {
                                    const tempCharacter = {
                                        title: character.node.last_name ? `${character.node.last_name}, ${character.node.first_name}` : character.node.first_name,
                                        subtitle: character.role,
                                        cover: character.node.main_picture.medium ? character.node.main_picture.medium : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
                                    };
                                    tempAnime.characters.push(tempCharacter);
                                }
                            }*/

                            setManga(tempAnime);
                        });
                    } else {
                        if (response.status === 401) {
                            userContext.changeUserData(null);
                        } else if (response.status === 500) {
                            setError(<ServerError />);
                        } else if (response.status === 502) {
                            setError(<MyanimelistError />);
                        } else {
                            setError(<UnknownError />);
                        }
                    }
                    //setStatus(true);
                })
                .catch(error => {
                    log(error);
                    setError(<NetworkError />);
                });
        };

        update();

        /*if (!document.startViewTransition) {
            update();
            return;
        }

        document.startViewTransition(() => update());*/
    }, [id]);

    useEffect(() => {
        const hash = location.hash;
        setPicturesOpen(hash === "#pictures");
        setIsOpen(hash === "#edit");
    }, [location]);

    /*useEffect(() => {
        const meta = document.querySelector('meta[name="theme-color"]')
        if (meta) {
            if (isOpen) {
                meta.setAttribute('content', '#e5e7eb')
            } else {
                meta.setAttribute('content', '#1d4ed8')
            }
        }
        if (picturesOpen) {
            updateThemeMeta("#cccccc");
        } else {
            updateThemeMeta();
        }
    }, [picturesOpen]);*/

    const back = () => {
        //closePage(() => {
        if (location.state) {
            navigate(-1);
        } else {
            navigate("/pwa");
        }
        //});
    }

    const edit = () => {
        if (!saving)
            navigate(`../manga/${id}#edit`);
    }

    const closeEdit = async (newStatus) => {
        //setIsOpen(false);
        navigate(-1);
        log(newStatus);
        if (newStatus) {
            setSaving(true);

            const oldManga = manga;
            setManga({ ...manga, my_list_status: { ...manga.my_list_status, status: newStatus.status, score: newStatus.score, num_episodes_watched: newStatus.num_watched_episodes } });

            const urlSearchParams = new URLSearchParams();
            urlSearchParams.append("id", id);
            urlSearchParams.append("status", newStatus.status);
            urlSearchParams.append("score", newStatus.score);
            urlSearchParams.append("num_volumes_read", newStatus.num_volumes_read);
            urlSearchParams.append("num_chapters_read", newStatus.num_chapters_read);

            const result = await fetch(`/api/manga-update?${urlSearchParams}`);

            if (result.status == 200) {
                const data = await result.json();
                log("year", manga);
                updateManga({
                    id: id,
                    title: manga.title,
                    picture: manga.main_picture.medium,
                    nsfw: manga.nsfw,
                    media_type: manga.media_type,
                    genres: data.genres,
                    status: data.status,
                    score: data.score,
                    num_volumes_read: data.num_volumes_read,
                    num_chapters_read: data.num_chapters_read,
                    is_rereading: data.is_rereading,
                    updated_at: data.updated_at,
                    num_volumes: manga.num_volumes,
                    num_chapters: manga.num_chapters,
                    year: manga.start_date,
                });
            } else if (result.status == 401) {
                setManga(oldManga);
            } else {
                setManga(oldManga);
            }

            setSaving(false);
        }
    }

    const share = async () => {
        try {
            await navigator.share({
                title: manga.title,
                text: manga.synopsis.substring(0, 100),
                url: window.location.href,
            });
        } catch (error) {
            // Ignore
        }
    }

    const wrap = (min, max, v) => {
        const rangeSize = max - min;
        return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
    };

    //const imageIndex = page;

    const paginate = (newDirection) => {
        if (newDirection === 1 && page < manga.pictures.length - 1) {
            setPage([page + newDirection, newDirection]);
        } else if (newDirection === -1 && page > 0) {
            setPage([page + newDirection, newDirection]);
        }
    };

    const openPictures = () => {
        setPage([0, 1]);
        setPicturesOpen(true);
    }

    const closePictures = (forced = false) => {
        if (!is_mobile() || forced) {
            navigate(-1);
            //setPicturesOpen(false);
            //setPage([0, 1]);
        }
    }

    /*const downloadPicture = () => {
        var element = document.createElement('a');
        element.setAttribute('href', anime.pictures[page]);
        element.setAttribute('download', '');
        element.click();
    }*/

    if (manga) {
        return (
            <motion.div
                key={location.pathname}
                variants={page_variants()}
                //initial={"enter"}
                animate={"center"}
                exit={"exit"}
                className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
                <div className="flex-none text-white md:text-black dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                    <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                        <IconArrowLeft className="block md:hidden w-14 h-14 p-4" onClick={() => { back() }} />
                        <h2 className="flex-1 pl-0 md:pl-4 xl:pl-0 font-bold truncate">{manga.title}</h2>
                        <IconPencil className="block md:hidden w-14 h-14 p-4" onClick={edit} />
                    </div>
                </div>
                <div ref={ref} className="flex-1 overflow-y-scroll">
                    <div className="max-w-screen-xl w-full h-full mx-auto">
                        <LayoutGroup>
                            <div className="flex flex-col xl:flex-row items-center p-8 md:mx-4 xl:mx-0 mt-4 bg-white dark:bg-gray-800 md:rounded shadow">
                                <Link to={`../manga/${id}#pictures`} className="relative cursor-pointer">
                                    {/* <img
                                        draggable="false"
                                        className="w-56 xl:w-40 h-80 xl:h-56 object-cover outlin outlin-8 outline-gray-100 dark:outline-gray-900 rounded-md"
                                        src={anime.main_picture.large} /> */}
                                    <Image
                                        className="w-56 xl:w-40 h-80 xl:h-56 object-cover bg-gray-200 dark:bg-gray-700 outlin outlin-8 outline-gray-100 dark:outline-gray-900 rounded-md"
                                        src={manga.main_picture.large} />
                                    <p className="absolute inset-x-1/2 -translate-x-1/2 bottom-8 w-20 py-1 font-bold text-xs text-center text-black uppercase bg-white/50 backdrop-blur-sm rounded-full">{`${manga.pictures.length} ${manga.pictures.length === 1 ? "Image" : "Images"}`}</p>
                                </Link>
                                <div className="flex-1 xl:mx-8 my-8 xl:my-0">
                                    <p className="font-bold xl:text-xl text-center xl:text-left text-wrap-balance">{manga.title}</p>
                                    {manga.start_season ?
                                        <p className="mt-2 font-medium text-sm xl:text-lg text-center xl:text-left text-gray-600 dark:text-gray-400">{`${capitalizeFirstLetter(manga.start_season.season)} ${manga.start_season.year} | ${formatMediaType(manga.media_type)}`}</p>
                                        :
                                        <p className="mt-2 font-medium text-sm xl:text-lg text-center xl:text-left text-gray-600 dark:text-gray-400">{`${formatMediaType(manga.media_type)}`}</p>
                                    }
                                </div>
                                <div className="font-bold text-sm uppercase">
                                    <button onClick={edit} className="relative w-56 h-10 mx-auto flex items-center justify-center text-white bg-blue-600 rounded-md cursor-pointer" style={{ background: formatStatus(manga.my_list_status.status).background }}>
                                        {saving ?
                                            <>
                                                <IconLoader2 className="block animate-spin h-5 w-full text-white" strokeWidth="3" />
                                            </>
                                            :
                                            <>
                                                <IconPlaylistAdd className="w-6 h-6 mr-2" />
                                                <p>{formatStatus(manga.my_list_status.status).text}</p>
                                            </>
                                        }
                                        {/* <motion.div layoutId="edit" className="absolute top-0 bottom-0 left-0 right-0 bg-inherit rounded-md"></motion.div> */}
                                    </button>
                                    <div className="w-56 h-10 mx-auto mt-4 flex items-center justify-center text-white bg-gray-700 rounded-md cursor-pointer" onClick={share}>
                                        <IconShare className="w-6 h-6 mr-2" />
                                        <p>Share</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col-reverse lg:flex-row md:mx-4 xl:mx-0 lg:space-x-4">
                                <div className="flex-none lg:w-96">
                                    <Accordian
                                        className="mt-4 overflow-hidden shadow md:rounded-md"
                                        title="Alternative Titles"
                                        content={
                                            <table className="m-4 text-gray-600 dark:text-gray-400">
                                                <tbody>
                                                    {manga.alternative_titles.synonyms.length > 0 && <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Synonyms</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{manga.alternative_titles.synonyms.join(", ")}</td>
                                                    </tr>}
                                                    {manga.alternative_titles.en && <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">English</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{manga.alternative_titles.en}</td>
                                                    </tr>}
                                                    {manga.alternative_titles.ja && <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Japanese</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{manga.alternative_titles.ja}</td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        } />
                                    <Accordian
                                        className="mt-4 overflow-hidden shadow md:rounded-md"
                                        title="Information"
                                        content={
                                            <table className="m-4 text-gray-600 dark:text-gray-400">
                                                <tbody>
                                                    <tr className="mb-4">
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Type</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{manga.media_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Volumes</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{formatEpisodes(manga.num_volumes)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Chapters</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{formatEpisodes(manga.num_chapters)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Published</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{formatDate(manga.start_date)} to {formatDate(manga.end_date)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Genres</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{formatArray(manga.genres)}</td>
                                                    </tr>
                                                    {manga.authors && <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Authors</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{formatAuthors(manga.authors)}</td>
                                                    </tr>}
                                                    {manga.serialization && manga.serialization.length > 0 && <tr>
                                                        <td className="align-top font-bold text-gray-900 dark:text-white">Serialization</td>
                                                        <td className="align-top px-4 font-bold">:</td>
                                                        <td className="font-medium">{formatSerialization(manga.serialization)}</td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        } />
                                    <Accordian
                                        className="mt-4 overflow-hidden shadow md:rounded-md"
                                        title="Statistics"
                                        content={
                                            <div className="p-4 text-center space-y-4">
                                                {manga.mean && manga.rank && <div className="flex">
                                                    <div className="flex-1">
                                                        <p className="font-medium text-sm uppercase text-gray-600 dark:text-gray-400">Score</p>
                                                        <p className="font-bold">{manga.mean}</p>
                                                    </div>
                                                    <div className="flex-1">
                                                        <p className="font-medium text-sm uppercase text-gray-600 dark:text-gray-400">Scored By</p>
                                                        <p className="font-bold">{formateNumber(manga.num_scoring_users)}</p>
                                                    </div>
                                                    <div className="flex-1">
                                                        <p className="font-medium text-sm uppercase text-gray-600 dark:text-gray-400">Ranked</p>
                                                        <p className="font-bold">#{manga.rank}</p>
                                                    </div>
                                                </div>}
                                                <div className="flex">
                                                    <div className="flex-1">
                                                        <p className="font-medium text-sm uppercase text-gray-600 dark:text-gray-400">Popularity</p>
                                                        <p className="font-bold">#{manga.popularity}</p>
                                                    </div>
                                                    <div className="flex-1">
                                                        <p className="font-medium text-sm uppercase text-gray-600 dark:text-gray-400">Members</p>
                                                        <p className="font-bold">{formateNumber(manga.num_list_users)}</p>
                                                    </div>
                                                    <div className="flex-1">
                                                        <p className="font-medium text-sm uppercase text-gray-600 dark:text-gray-400">Favorites</p>
                                                        <p className="font-bold">{formateNumber(manga.num_favorites)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        } />
                                </div>
                                <div className="flex-1 lg:w-0">
                                    <Accordian
                                        className="mt-4 overflow-hidden shadow md:rounded-md"
                                        title="Synopsis"
                                        content={<p className="p-4 font-medium text-justify whitespace-pre-wrap text-gray-600 dark:text-gray-400">{manga.synopsis}</p>} />
                                    {manga.background && (
                                        <Accordian
                                            open={false}
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            title="Background"
                                            content={<p className="p-4 font-medium text-justify whitespace-pre-wrap text-gray-600 dark:text-gray-400">{manga.background}</p>} />
                                    )}
                                    {/* <Accordian
                                        className="mt-4 overflow-hidden shadow md:rounded-md"
                                        title="Information"
                                        content={
                                            <table className="m-4 text-gray-600 dark:text-gray-400">
                                                <tbody>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td className="px-4">:</td>
                                                        <td>{anime.media_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Episodes</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatEpisodes(anime.num_episodes)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatAnimeStatus(anime.status)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aired</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatDate(anime.start_date)} to {formatDate(anime.end_date)}</td>
                                                    </tr>
                                                    {anime.start_season && <tr>
                                                        <td>Premiered</td>
                                                        <td className="px-4">:</td>
                                                        <td>{`${anime.start_season.season} ${anime.start_season.year}`}</td>
                                                    </tr>}
                                                    {anime.broadcast && <tr>
                                                        <td>Broadcast</td>
                                                        <td className="px-4">:</td>
                                                        <td>{`${anime.broadcast.day_of_the_week} at ${anime.broadcast.start_time} (JST)`}</td>
                                                    </tr>}
                                                    <tr>
                                            <td>Producers</td>
                                            <td className="px-4">:</td>
                                            <td>{formatArray(anime.producers)}</td>
                                        </tr>
                                        <tr>
                                            <td>Licensors</td>
                                            <td className="px-4">:</td>
                                            <td>{formatArray(anime.licensors)}</td>
                                        </tr>
                                                    <tr>
                                                        <td>Studios</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatArray(anime.studios)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Source</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatSource(anime.source)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Genres</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatArray(anime.genres)}</td>
                                                    </tr>
                                                    <tr>
                                            <td>Theme</td>
                                            <td className="px-4">:</td>
                                            <td>{capitalizeFirstLetter(anime.theme)}</td>
                                        </tr>
                                        <tr>
                                            <td>Demographic</td>
                                            <td className="px-4">:</td>
                                            <td>{capitalizeFirstLetter(anime.demographic)}</td>
                                        </tr>
                                                    <tr>
                                                        <td>Duration</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatDuration(anime.average_episode_duration)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rating</td>
                                                        <td className="px-4">:</td>
                                                        <td>{formatRating(anime.rating)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        } /> */}
                                    {/* {manga.characters && (
                                        <Accordian
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            title="Characters"
                                            content={<Characters data={manga.characters} />} />
                                    )} */}
                                    {(manga.related_anime && manga.related_anime.length > 0) && (
                                        <Accordian
                                            key={`2-${width}`}
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            title="Related Anime"
                                            content={<Animes data={manga.related_anime} />} />
                                    )}
                                    {(manga.related_manga && manga.related_manga.length > 0) && (
                                        <Accordian
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            title="Related Manga"
                                            content={<Mangas data={manga.related_manga} />} />
                                    )}
                                    {(manga.opening_themes && manga.opening_themes.length > 0) && (
                                        <Accordian
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            open={false}
                                            title="Opening Themes"
                                            content={<p className="p-4 whitespace-pre text-wrap-balance text-gray-600 dark:text-gray-400">{manga.opening_themes.join("\r\n")}</p>} />
                                    )}
                                    {(manga.ending_themes && manga.ending_themes.length > 0) && (
                                        <Accordian
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            open={false}
                                            title="Ending Themes"
                                            content={<p className="p-4 whitespace-pre text-wrap-balance text-gray-600 dark:text-gray-400">{manga.ending_themes.join("\r\n")}</p>} />
                                    )}
                                    {(manga.characters && manga.characters.length > 0) && (
                                        <Accordian
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            title="Characters"
                                            content={
                                                <p></p>
                                            } />
                                    )}
                                    {(manga.recommendations && manga.recommendations.length > 0) && (
                                        <Accordian
                                            key={`3-${width}`}
                                            className="mt-4 overflow-hidden shadow md:rounded-md"
                                            title="Recommendations"
                                            content={<Mangas data={manga.recommendations} />} />
                                    )}
                                </div>
                            </div>
                            <div className="h-4"></div>
                        </LayoutGroup>
                    </div>
                </div>

                <LazyDialog isOpen={isOpen}>
                    <EditDialog manga={manga} close={closeEdit} />
                </LazyDialog>

                <LazyDialog isOpen={picturesOpen} onClose={closePictures}>
                    <GalleryDialog pictures={manga.pictures} close={closePictures} />
                </LazyDialog>
            </motion.div>
        );
    } else {
        return (
            <motion.div
                key={location.pathname}
                variants={page_variants()}
                //initial={"enter"}
                animate={"center"}
                exit={"exit"}
                className="fixed md:static top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900 z-50">
                <div className="flex-none text-white md:text-black dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 border-b border-gray-200 dark:border-gray-700 shadow z-20">
                    <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                        <IconArrowLeft className="block md:hidden w-14 h-14 p-4" onClick={() => { back() }} />
                        <h2 className="flex-1 pl-0 md:pl-4 xl:pl-0 font-bold truncate">
                            {/* <span className="block w-48 text-transparent bg-blue-500 md:bg-gray-200 md:dark:bg-gray-700 rounded-md shine dark:shimme">$</span> */}
                        </h2>
                        <IconPencil className="block md:hidden w-14 h-14 p-4" onClick={() => { }} />
                    </div>
                </div>
                <div className="flex-1 flex items-center h-full">
                    {error ? error : <Loading />}
                </div>
            </motion.div>
        );
    }
}

function LazyDialogContent({ children }) {
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        !isPresent && setTimeout(safeToRemove, 300)
    }, [isPresent])

    return (
        <>
            {children}
        </>
    );
}

function LazyDialog({ children, isOpen = false, onClose = () => { } }) {
    return (
        <div onClick={() => { !is_mobile() && onClose() }} className={"absolute inset-0 z-50 duration-300 transition-all " + (isOpen ? "visible opacity-100 bg-black/[0.15] dark:bg-white/[0.15] md:backdrop-blur-sm" : "collapse md:opacity-0 pointer-events-none")}>
            <div className={"flex flex-col items-center justify-center max-w-screen-xl w-full h-full px-0 md:px-16 xl:px-0 mx-auto duration-300 transition-transform " + (isOpen ? "scale-100" : "md:scale-95 translate-y-full md:translate-y-0")}>
                <AnimatePresence>
                    {isOpen && <LazyDialogContent>
                        {children}
                    </LazyDialogContent>}
                </AnimatePresence>
            </div>
        </div>
    );
}

function GalleryDialog({ pictures, close }) {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = (newDirection) => {
        if (newDirection === 1 && page < pictures.length - 1) {
            setPage([page + newDirection, newDirection]);
        } else if (newDirection === -1 && page > 0) {
            setPage([page + newDirection, newDirection]);
        }
    };

    return (
        <>
            <div className="block md:hidden flex w-full h-14 text-white bg-blue-600 -dark:bg-gray-800 md:bg-transparent shadow-md z-10">
                <p className="flex-1 h-14 p-4 font-bold uppercas">Pictures</p>
                <IconX onClick={() => { close(true) }} className="block md:hidden w-14 h-14 p-4" />
                {/* <IconDownload onClick={downloadPicture} className="block md:hidden w-14 h-14 p-4" /> */}
            </div>
            <div className="hidden md:block w-full h-14"></div>
            {/* <IconDownload className="block md:hidden w-14 h-14 p-4 mx-auto" /> */}
            {/* <IconX className="w-12 h-12 p-4 mt-24 bg-white shadow rounded-full" /> */}
            <div className="relative flex-1 flex items-center w-full bg-blue-50 dark:bg-gray-900 md:bg-transparent md:dark:bg-transparent">
                <AnimatePresence initial={false} custom={direction} mode="popLayout">
                    <motion.div
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) {
                                paginate(1);
                            } else if (swipe > swipeConfidenceThreshold) {
                                paginate(-1);
                            }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                        className="flex items-center w-full md:w-auto h-full md:h-auto p-4 md:mx-auto"
                    >
                        <img src={pictures[page]} draggable="false" className="mx-auto bg-white shadow rounded-md cursor-grabbing" />
                    </motion.div>
                </AnimatePresence>
                <IconCaretLeft onClick={(e) => { e.stopPropagation(); paginate(-1); }} className="hidden md:block absolute left-0 inset-y-1/2 -translate-y-1/2 w-10 h-16 p-2 text-black bg-white fill-black shadow rounded-r-full md:rounded-full cursor-pointer z-10" />
                <IconCaretRight onClick={(e) => { e.stopPropagation(); paginate(1); }} className="hidden md:block absolute right-0 inset-y-1/2 -translate-y-1/2 w-10 h-16 p-2 text-black bg-white fill-black shadow rounded-l-full md:rounded-full cursor-pointer z-10" />
            </div>
            <div className="block md:hidden flex w-full h-14 bg-white dark:bg-gray-800 md:bg-transparent bottom-nav z-10">
                <IconCaretLeft onClick={(e) => { e.stopPropagation(); paginate(-1); }} className="block md:hidden w-14 h-14 p-4" />
                <p className="flex-1 h-14 py-4 font-bold text-center uppercase">{`${page + 1}/${pictures.length}`}</p>
                <IconCaretRight onClick={(e) => { e.stopPropagation(); paginate(1); }} className="block md:hidden w-14 h-14 p-4" />
            </div>
            <p className="hidden md:block w-32 h-14 py-4 mb-24- font-bold text-center uppercase">{`${page + 1}/${pictures.length}`}</p>
        </>
    );
}

function EditDialog({ manga, close }) {
    const [listType, setListType] = useState(0);
    //const [episodes, setEpisodes] = useState(0);
    const [score, setScore] = useState(0);
    const [background, setBackground] = useState(0);
    const inputVolumes = useRef();
    const inputChapters = useRef();

    useEffect(() => {
        if (manga.my_list_status.status) {
            setListType(Constants.MANGA_LIST_CODES.indexOf(manga.my_list_status.status));
            setScore(formatScore(manga.my_list_status.score));
        } else {
            setListType(Constants.MANGA_LIST_CODES.indexOf("plan_to_read"));
        }

        return () => {
            updateThemeMeta();
        }
    }, []);

    useEffect(() => {
        const tempStatus = formatStatus(Constants.MANGA_LIST_CODES[listType]);
        setBackground(tempStatus.background);
        updateThemeMeta(tempStatus.backgroundDark);
    }, [listType]);

    const onListTypeChanged = (text) => {
        const volumes = parseInt(manga.num_volumes);
        const chapters = parseInt(manga.num_chapters);
        if (text === Constants.MANGA_LIST_NAMES[1]) {
            if (volumes > 0)
                inputVolumes.current.value = volumes;
            if (chapters > 0)
                inputChapters.current.value = chapters;
        }
        setListType(Constants.MANGA_LIST_NAMES.indexOf(text));
    }

    const onScoreChanged = (text) => {
        setScore(Constants.SCORE_NAMES.indexOf(text));
    }

    const cancel = () => {
        close(null);
    }

    const save = () => {
        close({ status: Constants.MANGA_LIST_CODES[listType], score: formatScore(score), num_volumes_read: inputVolumes.current.value, num_chapters_read: inputChapters.current.value });
    }

    return (
        <div className="flex flex-col w-full md:w-96 h-full md:h-[30rem] bg-white dark:bg-gray-800 md:rounded-md">
            <div className="flex-none flex items-center w-full h-14 text-white bg-blue-600 shadow md:rounded-t-md" style={{ background: background }}>
                {/* <IconX className="block md:hidden w-14 h-14 p-4" onClick={cancel} /> */}
                <h2 className="flex-1 pl-4 pr-0 font-bold truncate">{manga.title}</h2>
                <IconX className="w-14 h-14 p-4" onClick={cancel} />
            </div>
            <div className="flex-1 p-4">
                <div className="flex mb-1 font-medium">
                    <p className="flex-1">Status</p>
                    <p className="flex-none py-1 font-medium text-xs text-gray-600 dark:text-gray-400">{formatAnimeStatus(manga.status)}</p>
                </div>
                <Select
                    options={Constants.MANGA_LIST_NAMES}
                    selected={listType}
                    onSelect={onListTypeChanged}
                    className="w-full mr-2 rounded-md"
                />
                <div className="flex mt-4 mb-1 font-medium">
                    <p className="flex-1">Volumes</p>
                    <p className="flex-none py-1 font-medium text-xs text-gray-600 dark:text-gray-400">{formatEpisodes(manga.num_volumes)} Volumes</p>
                </div>
                <input ref={inputVolumes} type="number" min="0" max={manga.num_volumes === 0 ? 9999 : manga.num_volumes} defaultValue={formatEpisodes(manga.my_list_status.num_volumes_read, 0)} className="w-full h-10 px-4 bg-white dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700 hover:border-blue-600 dark:hover:border-blue-600 focus:border-blue-600 dark:focus:border-blue-600 outline-none rounded-md" />
                <div className="flex mt-4 mb-1 font-medium">
                    <p className="flex-1">Chapters</p>
                    <p className="flex-none py-1 font-medium text-xs text-gray-600 dark:text-gray-400">{formatEpisodes(manga.num_chapters)} Chapters</p>
                </div>
                <input ref={inputChapters} type="number" min="0" max={manga.num_chapters === 0 ? 9999 : manga.num_chapters} defaultValue={formatEpisodes(manga.my_list_status.num_chapters_read, 0)} className="w-full h-10 px-4 bg-white dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700 hover:border-blue-600 dark:hover:border-blue-600 focus:border-blue-600 dark:focus:border-blue-600 outline-none rounded-md" />
                <div className="flex mt-4 mb-1 font-medium">
                    <p className="flex-1">Score</p>
                    <p className="flex-none py-1 font-medium text-xs text-gray-600 dark:text-gray-400">{formatMean(manga.mean)} Stars</p>
                </div>
                <Select
                    options={Constants.SCORE_NAMES}
                    selected={score}
                    onSelect={onScoreChanged}
                    className="w-full mr-2 rounded-md"
                />
            </div>
            <div className="flex-none flex p-4 space-x-4 border-t border-gray-200 dark:border-gray-700">
                <button className="flex-1 h-10 font-bold bg-gray-200 dark:bg-gray-700 rounded-md" onClick={cancel}>Cancel</button>
                <button className="flex-1 h-10 font-bold text-white bg-blue-600 rounded-md" style={{ background: background }} onClick={save}>Save</button>
            </div>
        </div>
    );
}

function Accordian({ title, content, open = true, className = "" }) {
    const [isOpen, setIsOpen] = useState(open);

    return (
        <motion.div className={"bg-white dark:bg-gray-800 " + className} layout="position">
            <div
                className="flex h-14 p-4 shadow"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="flex-1 font-bold">{title}</p>
                <IconChevronUp className={(isOpen ? "rotate-180" : "") + " transition-transform"} />
            </div>
            {/* {isOpen ? content : null} */}
            {/* mode="wait" */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="border-t border-gray-200 dark:border-gray-700"
                    >
                        {content}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}