import { Icon123, IconBook, IconCalendar, IconCheck, IconCircle, IconCircleCheck, IconCircleCheckFilled, IconCircleDot, IconFilter, IconMovie, IconSort09, IconSortAZ, IconSortZA, IconStar, IconStarFilled, IconStarHalfFilled, IconSwitchVertical, IconVideo } from "@tabler/icons-react";
import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    forwardRef,
    useState,
    useCallback
} from "react";
import { AnimatePresence, motion, useWillChange } from "framer-motion";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual';
import { useLiveQuery } from "dexie-react-hooks";

import Select from "../Components/Select";
import Tabs from "../Components/Tabs";
import { database } from "../Database";
import { capitalizeFirstLetter, formatEpisodes, formatMediaType, formatStatus, get_can_swipe, get_layout, get_nsfw, is_mobile, log, openPage, percentWatched, updateThemeMeta } from "../Helpers/Utils";
import { Constants } from "../Helpers/Constants";
import useSwipe from "../Helpers/useSwipe";
import Dialog from "../Components/Dialog";
import { IconSort90 } from "@tabler/icons-react";
import Image from "../Components/Image";

function RatingView({ rating }) {
    if (rating === 0) {
        return (
            <>
                <IconStar className="w-4 h-4" /><IconStar className="w-4 h-4" /><IconStar className="w-4 h-4" /><IconStar className="w-4 h-4" /><IconStar className="w-4 h-4" />
            </>
        );
    } else {
        return (
            <>
                {rating < 1 ? <IconStar className="w-4 h-4" /> : rating >= 2 ? <IconStarFilled className="w-4 h-4" /> : <IconStarHalfFilled className="w-4 h-4" />}
                {rating < 3 ? <IconStar className="w-4 h-4" /> : rating >= 4 ? <IconStarFilled className="w-4 h-4" /> : <IconStarHalfFilled className="w-4 h-4" />}
                {rating < 5 ? <IconStar className="w-4 h-4" /> : rating >= 6 ? <IconStarFilled className="w-4 h-4" /> : <IconStarHalfFilled className="w-4 h-4" />}
                {rating < 7 ? <IconStar className="w-4 h-4" /> : rating >= 8 ? <IconStarFilled className="w-4 h-4" /> : <IconStarHalfFilled className="w-4 h-4" />}
                {rating < 9 ? <IconStar className="w-4 h-4" /> : rating >= 10 ? <IconStarFilled className="w-4 h-4" /> : <IconStarHalfFilled className="w-4 h-4" />}
            </>
        );
    }
}

function AnimeView({ data, onClick }) {
    if (data) {
        return (
            //<Link to={`/anime/${data.id}`} state={{ from: '/my-list' }}>
            <div
                //initial={{ opacity: 0, scale: 1 }}
                //whileInView={{ opacity: 1, scale: 1 }}
                className="flex w-full h-full p-4 bg-white dark:bg-gray-800 borderu border-gray-300 dark:border-gray-700 shadow hover:shadow-lg transition-shadow md:rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                <Image
                    //className="flex-none h-full w-22 md:w-28 object-cover bg-gray-200 rounde"
                    className="flex-none h-full w-22 object-cover bg-gray-200 dark:bg-gray-700 rounded-md"
                    style={{ width: "90px" }}
                    src={data.picture}
                    alt="cover"
                />
                <div className="flex-1 flex flex-col ml-4">
                    <p className="font-bold text-sm- text-justify- text-ellipsis-2 text-wrap-balance">
                        {data.title}
                    </p>
                    <p className="mt-1 font-medium text-sm text-gray-600 dark:text-gray-400">
                        {(data.season && data.year) ?
                            `${formatMediaType(data.media_type)} | ${capitalizeFirstLetter(data.season)} ${data.year}`
                            :
                            `${formatMediaType(data.media_type)}`
                        }
                    </p>
                    <div className="flex-grow"></div>
                    {/* <div className="flex mb-2 font-bold text-xs text-gray-600 dark:text-gray-400">
                        <p className="flex-1">{`${data.score === 0 ? "(0) Not Rated" : Constants.SCORE_NAMES[11 - data.score]}`}</p>
                        <p className="flex-none">{`${data.num_episodes_watched} / ${data.num_episodes} Episodes`}</p>
                    </div> */}
                    <div className="flex mb-2 font-bold text-sm text-gray-600 dark:text-gray-400">
                        <p className="flex-1 flex items-center"><RatingView rating={data.score} /></p>
                        <div className="flex-none flex items-center"><IconMovie className="h-4" />{` Ep: ${data.num_episodes_watched} / ${data.num_episodes}`}</div>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                        <div className="bg-blue-600 h-full rounded-full dark:bg-blue-500" style={{ width: `${percentWatched(data.num_episodes_watched, data.num_episodes)}%`, background: formatStatus(data.status).background }}></div>
                    </div>
                    {/* <p className="mt-2 font-medium text-xs text-gray-600 dark:text-gray-400">Action, Mystery, Supernatural</p> */}
                    {/* <p className="mt-2 space-x-2 font-medium text-xs">
                    <span className="px-2 bg-blue-100 rounded-full">Action</span>
                    <span className="px-2 bg-blue-100 rounded-full">Mystery</span>
                    <span className="px-2 bg-blue-100 rounded-full">Supernatural</span>
                </p> */}
                </div>
            </div>
            //</Link>
        );
    } else {
        return (<></>);
    }
}

function AnimeListView({ data, onClick }) {
    if (data) {
        return (
            <div
                className="flex flex-col px-4f w-full h-full bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow hover:shadow-lg transition-shadow md:rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                <div className="flex flex-col md:flex-row md:items-center justify-center md:justify-start h-full px-4">
                    <p className="md:flex-1 font-bold text-justify whitespace-nowrap text-ellipsis overflow-hidden">{data.title}</p>
                    {/* <p className="mt-1 font-medium text-xs text-gray-500 dark:text-gray-400">{`${formatMediaType(data.media_type)} | ${capitalizeFirstLetter(data.season)} ${data.year}`}</p> */}
                    <div className="md:basis-1/2 lg:basis-1/2 flex mt-1 font-bold text-sm text-gray-500 dark:text-gray-400">
                        <p className="flex-1 text-left">
                            {(data.season && data.year) ?
                                `${formatMediaType(data.media_type)} | ${capitalizeFirstLetter(data.season)} ${data.year}`
                                :
                                `${formatMediaType(data.media_type)}`
                            }
                        </p>
                        <p className="flex-none md:flex-1 flex items-center justify-center md:justify-start"><RatingView rating={data.score} /></p>
                        <div className="flex-1 flex items-center justify-end md:justify-start"><IconMovie className="h-4" />{` Ep: ${data.num_episodes_watched} / ${data.num_episodes}`}</div>
                        {/* <p className="flex-1 text-center">{`${data.score === 0 ? "" : Constants.SCORE_NAMES[11 - data.score]}`}</p> */}
                        {/* <p className="md:flex-1 text-right">{`${data.num_episodes_watched} / ${data.num_episodes} Episodes`}</p> */}
                    </div>
                </div>
                <div className="w-full h-1 bg-gray-200 rounded-ful dark:bg-gray-700">
                    <div className="bg-blue-600 h-full rounded-ful dark:bg-blue-500" style={{ width: `${percentWatched(data.num_episodes_watched, data.num_episodes)}%`, background: formatStatus(data.status).background }}></div>
                </div>
            </div>
        );
    } else {
        return (<></>);
    }
}

function MangaView({ data, onClick }) {
    if (data) {
        return (
            //<Link to={`/anime/${data.id}`} state={{ from: '/my-list' }}>
            <div
                //initial={{ opacity: 0, scale: 1 }}
                //whileInView={{ opacity: 1, scale: 1 }}
                className="flex w-full h-full p-4 bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow hover:shadow-lg transition-shadow md:rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                <Image
                    //className="flex-none h-full w-22 md:w-28 object-cover bg-gray-200 rounde"
                    className="flex-none h-full w-22 object-cover bg-gray-200 dark:bg-gray-700 rounded-md"
                    style={{ width: "90px" }}
                    src={data.picture}
                    alt="cover"
                />
                <div className="flex-1 flex flex-col ml-4">
                    <p className="font-bold text-justify- text-ellipsis-2 text-wrap-balance">
                        {data.title}
                    </p>
                    <p className="mt-1 font-medium text-sm text-gray-500 dark:text-gray-400">
                        {`${formatMediaType(data.media_type)} | ${data.year}`}
                    </p>
                    <div className="flex-grow"></div>
                    {/* <div className="flex mb-2 font-medium text-xs text-gray-500 dark:text-gray-400">
                        <p className="flex-1">{`${data.score === 0 ? "" : Constants.SCORE_NAMES[11 - data.score]}`}</p>
                        <p className="flex-none">{`${data.num_chapters_read} / ${formatEpisodes(data.num_chapters)} Chapters`}</p>
                        <p className="flex-none">{`${data.num_chapters_read} / ${formatEpisodes(data.num_chapters)} C - ${data.num_volumes_read} / ${formatEpisodes(data.num_volumes)} V`}</p>
                    </div> */}
                    <div className="flex mb-2 font-bold text-sm text-gray-600 dark:text-gray-400">
                        <p className="flex-1 flex items-center"><RatingView rating={data.score} /></p>
                        <div className="flex-none flex items-center"><IconBook className="h-4" />{` Ch: ${data.num_chapters_read} / ${data.num_chapters}`}</div>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                        <div className="bg-blue-600 h-full rounded-full dark:bg-blue-500" style={{ width: `${percentWatched(data.num_chapters_read, data.num_chapters)}%`, background: formatStatus(data.status).background }}></div>
                    </div>
                    {/* <p className="mt-2 font-medium text-xs text-gray-600 dark:text-gray-400">Action, Mystery, Supernatural</p> */}
                    {/* <p className="mt-2 space-x-2 font-medium text-xs">
                    <span className="px-2 bg-blue-100 rounded-full">Action</span>
                    <span className="px-2 bg-blue-100 rounded-full">Mystery</span>
                    <span className="px-2 bg-blue-100 rounded-full">Supernatural</span>
                </p> */}
                </div>
            </div>
            //</Link>
        );
    } else {
        return (<></>);
    }
}

function MangaListView({ data, onClick }) {
    if (data) {
        return (
            <div
                className="flex flex-col px-4f w-full h-full bg-white dark:bg-gray-800 border- border-gray-300 dark:border-gray-700 shadow hover:shadow-lg transition-shadow rounded-md overflow-hidden z-10 cursor-pointer"
                onClick={() => onClick(data.id)}>
                <div className="flex flex-col md:flex-row md:items-center justify-center md:justify-start h-full px-4">
                    <p className="md:flex-1 font-bold text-justify whitespace-nowrap text-ellipsis overflow-hidden">{data.title}</p>
                    {/* <p className="mt-1 font-medium text-xs text-gray-500 dark:text-gray-400">{`${formatMediaType(data.media_type)} | ${capitalizeFirstLetter(data.season)} ${data.year}`}</p> */}
                    <div className="md:basis-1/2 lg:basis-1/2 flex mt-1 font-bold text-sm text-gray-500 dark:text-gray-400">
                        <p className="flex-1 text-left">{`${formatMediaType(data.media_type)} | ${data.year}`}</p>
                        <p className="flex-none md:flex-1 flex items-center justify-center md:justify-start"><RatingView rating={data.score} /></p>
                        <div className="flex-1 flex items-center justify-end md:justify-start"><IconBook className="h-4" />{` Ep: ${data.num_chapters_read} / ${data.num_chapters}`}</div>
                        {/* <p className="flex-1 text-center">{`${data.score === 0 ? "" : Constants.SCORE_NAMES[11 - data.score]}`}</p>
                        <p className="md:flex-1 text-right">{`${data.num_chapters_read} / ${formatEpisodes(data.num_chapters)} Chapters`}</p> */}
                    </div>
                </div>
                <div className="w-full h-1 bg-gray-200 rounded-ful dark:bg-gray-700">
                    <div className="bg-blue-600 h-full rounded-ful dark:bg-blue-500" style={{ width: `${percentWatched(data.num_chapters_read, data.num_chapters)}%`, background: formatStatus(data.status).background }}></div>
                </div>
            </div>
        );
    } else {
        return (<></>);
    }
}

function ListView({ is_anime, listType, sortBy, orderBy, nsfw }) {
    const navigate = useNavigate();
    const location = useLocation();

    const filter = (media) => {
        return nsfw ? true : media.nsfw === "white";
    }

    const animes = useLiveQuery(
        () => {
            if (is_anime) {
                if (orderBy === Constants.ORDER_BY_ASC) {
                    return database.animes.where({ status: listType }).filter(filter).sortBy(sortBy);
                } else {
                    return database.animes.where({ status: listType }).filter(filter).reverse().sortBy(sortBy);
                }
            } else {
                if (orderBy === Constants.ORDER_BY_ASC) {
                    return database.mangas.where({ status: listType }).filter(filter).sortBy(sortBy);
                } else {
                    return database.mangas.where({ status: listType }).filter(filter).reverse().sortBy(sortBy);
                }
            }
        }
    );

    const [width, setWidth] = useState(window.innerWidth);
    const [columns, setColumns] = useState(1);
    const [layout, setLayout] = useState(get_layout);

    const parentRef = useRef();

    const rowVirtualizer = useVirtualizer({
        count: animes ? (Math.floor(animes.length / columns) + (animes.length % columns)) : 0,
        //count: animes ? animes.length / columns : 0,
        getScrollElement: () => parentRef.current,
        //estimateSize: () => layout === Constants.LAYOUT_LIST ? (width < 768 ? 96 : 72) : 146+32,
        estimateSize: () => layout === Constants.LAYOUT_LIST ? (width < 768 ? 96 : 72) : 178,
        enableSmoothScroll: true,
        paddingStart: 8,
        paddingEnd: 8,
        overscan: 12,
    })

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columns,
        getScrollElement: () => parentRef.current,
        estimateSize: () => {
            if (layout === Constants.LAYOUT_LIST) {
                return (width - (width < 1280 ? width < 768 ? 0 : 16 : 0));
            } else {
                return ((width < 768 ? width : width / 2) - (width < 1280 ? width < 768 ? 0 : 8 : 0));
            }
        },
    })

    useEffect(() => {
        function handleResize() {
            let width = window.innerWidth;
            width = width >= 1280 ? 1280 : width;

            if (layout === Constants.LAYOUT_GRID)
                setColumns(width < 768 ? 1 : 2);

            setWidth(width);

            rowVirtualizer.measure();
            columnVirtualizer.measure();
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // TODO:- Testing useLayoutEffect to solve glitch, Use inisiated state if not solved
    useLayoutEffect(() => {
        if (animes) {
            const tempObject = JSON.parse(sessionStorage.getItem(location.pathname) || JSON.stringify({ scrollOffset: 0, listType: listType, sortBy: sortBy, orderBy: orderBy, nsfw: nsfw }));
            if (tempObject.listType === listType && tempObject.sortBy === sortBy && tempObject.orderBy === orderBy && tempObject.nsfw === nsfw) {
                rowVirtualizer.scrollToOffset(tempObject.scrollOffset);
            }
            sessionStorage.removeItem(location.pathname);
        }
    }, [animes]);

    function getIndex(virtualRow, virtualColumn) {
        return columns === 1 ? virtualRow.index : (virtualColumn.index % 2 ? virtualRow.index * 2 : virtualRow.index * 2 - 1) + 1
    }

    function getData(index) {
        return animes[index];
    }

    function onClick(index) {
        sessionStorage.setItem(location.pathname, JSON.stringify({
            scrollOffset: rowVirtualizer.scrollOffset,
            listType: listType,
            sortBy: sortBy,
            orderBy: orderBy,
            nsfw: nsfw
        }));
        openPage(() => {
            navigate(is_anime ? `../anime/${index}` : `../manga/${index}`, { state: location.pathname });
        });
    }

    return (
        <div ref={parentRef} className="md:px-2 xl:px-0 w-full h-full overflow-auto">
            <div className="relative max-w-screen-xl w-full h-full mx-auto" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                    <React.Fragment key={virtualRow.index}>
                        {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
                            <div
                                key={virtualColumn.index}
                                className={(columns === 1 ? "px-2s" : (virtualColumn.index % 2 ? "pl-0 xl:pl-2 xl:pr-0" : "xl:pl-0 pr-0 xl:pr-2")) + " px-2s md:px-2 xl:px-0 py-2"}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: `${virtualColumn.size}px`,
                                    height: `${virtualRow.size}px`,
                                    transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                                }}
                            >
                                {/* <div className="w-full h-full bg-white shadow rounded-md">{data[columns === 1 ? virtualRow.index : (virtualColumn.index % 2 ? virtualRow.index * 2 : virtualRow.index * 2 - 1) + 1]}</div> */}
                                {is_anime ? (
                                    layout === Constants.LAYOUT_LIST ? <AnimeListView data={getData(getIndex(virtualRow, virtualColumn))} onClick={onClick} /> : <AnimeView data={getData(getIndex(virtualRow, virtualColumn))} onClick={onClick} />
                                ) : (
                                    layout === Constants.LAYOUT_LIST ? <MangaListView data={getData(getIndex(virtualRow, virtualColumn))} onClick={onClick} /> : <MangaView data={getData(getIndex(virtualRow, virtualColumn))} onClick={onClick} />
                                )}
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

const variants = {
    enter: (direction) => {
        return {
            x: direction === 0 ? 0 : direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction === 0 ? 0 : direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

export default function MyList() {
    const canSwipe = get_can_swipe();

    const location = useLocation();
    const navigate = useNavigate();

    const [initiated, setInitiated] = useState(false);
    const [isAnime, setIsAnime] = useState(true);
    const [listType, setListType] = useState(0);
    const [sortBy, setSortBy] = useState(2);
    const [orderBy, setOrderBy] = useState(Constants.ORDER_BY_DESC);
    const [nsfw, setNsfw] = useState(get_nsfw());

    const [direction, setDirection] = useState(0);
    //const willChange = useWillChange();
    const [isOpen, setOpen] = useState(false);
    //const ref = useRef();
    const swipeHandlers = useSwipe({
        onSwipedLeft: () => {
            if (canSwipe && is_mobile()) {
                if (listType < 4)
                    setListType(listType + 1);
                setDirection(1);
            }
        }, onSwipedRight: () => {
            if (canSwipe && is_mobile()) {
                if (listType > 0)
                    setListType(listType - 1);
                setDirection(-1);
            }
        }
    });
    //const snapTo = (i) => ref.current?.snapTo(i);

    function toggleList() {
        onListSwitched(isAnime ? Constants.LIST_TYPES[1] : Constants.LIST_TYPES[0])
    }

    function onListSwitched(type) {
        setDirection(0);
        setIsAnime(type === Constants.LIST_TYPES[0]);
    }

    /*function onListTypeChanged(type) {
        if (isAnime) {
            const index = Constants.ANIME_LIST_NAMES.indexOf(type);
            if (listType !== index)
                setDirection(listType > index ? -1 : 1);
            setListType(index);
        } else {
            const index = Constants.MANGA_LIST_NAMES.indexOf(type);
            if (listType !== index)
                setDirection(listType > index ? -1 : 1);
            setListType(index);
        }
    }

    function onSortByChanged(type) {
        setSortBy(Constants.SORT_BY_NAMES.indexOf(type));
        setOpen(false);
    }*/

    const onListTypeChanged = useCallback((type) => {
        if (isAnime) {
            const index = Constants.ANIME_LIST_NAMES.indexOf(type);
            if (is_mobile() && listType !== index) {
                setDirection(listType > index ? -1 : 1);
            } else {
                setDirection(0);
            }
            setListType(index);
        } else {
            const index = Constants.MANGA_LIST_NAMES.indexOf(type);
            if (is_mobile() && listType !== index) {
                setDirection(listType > index ? -1 : 1);
            } else {
                setDirection(0);
            }
            setListType(index);
        }
    }, [isAnime, listType, direction]);

    const onSortByChanged = useCallback((type, order) => {
        setSortBy(Constants.SORT_BY_NAMES.indexOf(type));
        setOrderBy(order);
        setOpen(false);
    }, [sortBy, listType]);

    useLayoutEffect(() => {
        // useEffect(() => {
        const tempObject = JSON.parse(localStorage.getItem(location.pathname) || JSON.stringify({ type: isAnime ? "anime" : "manga", listType: listType, sortBy: sortBy, orderBy: orderBy }));

        if ((tempObject.type === "anime") !== isAnime) {
            setIsAnime(tempObject.type === "anime");
        }

        if (tempObject.listType !== listType) {
            setListType(tempObject.listType);
        }

        if (tempObject.sortBy !== sortBy) {
            setSortBy(tempObject.sortBy);
        }

        if (tempObject.orderBy !== orderBy) {
            setOrderBy(tempObject.orderBy);
        }

        setInitiated(true); // Prevent scroll reset

        /*const changed = (event) => {
            log(event.matches);
            if (event.matches) {

            } else {

            }
        }
        const matchMedia = window.matchMedia("(max-width: 768px)");
        matchMedia.addEventListener("change", changed);*/

        updateThemeMeta();

        /*return () => {
            matchMedia.removeEventListener("change", changed);
        };*/
    }, []);

    useEffect(() => {
        localStorage.setItem(location.pathname, JSON.stringify({ type: isAnime ? "anime" : "manga", listType: listType, sortBy: sortBy, orderBy: orderBy }));
    }, [isAnime, listType, sortBy, orderBy]);

    useEffect(() => {
        const hash = location.hash;
        setOpen(hash === "#sort");
    }, [location]);

    const openSortDialog = () => {
        navigate(`../my-list#sort`);
    }

    const closeSortDialog = () => {
        navigate(-1);
    }

    return (
        <div
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative flex flex-col w-full h-full bg-blue-50 dark:bg-gray-900">
            <div className="sticky top-0 flex-none text-white md:text-gray-600 dark:md:text-white bg-blue-600 md:bg-white dark:md:bg-gray-800 md:border-b border-gray-200 dark:border-gray-700 shadow z-20">
                <div className="flex items-center h-14 max-w-screen-xl mx-auto">
                    <h2 className="flex-1 pl-4 xl:pl-0 font-bold text-xl4">My {isAnime ? "Anime" : "Manga"} List</h2>
                    <div className="hidden md:flex">
                        {/* <Select
                            options={Constants.LIST_TYPES}
                            selected={isAnime ? 0 : 1}
                            onSelect={onListSwitched}
                            className="hidden w-44 mr-2 rounded-md"
                        /> */}
                        <Select
                            options={isAnime ? Constants.ANIME_LIST_NAMES : Constants.MANGA_LIST_NAMES}
                            selected={listType}
                            onSelect={onListTypeChanged}
                            className="w-44 mr-2 rounded-md"
                        />
                        {/* <Select
                            options={Constants.SORT_BY_NAMES}
                            selected={sortBy}
                            onSelect={onSortByChanged}
                            className="hidden w-44 mr-4 xl:mr-0 rounded-md"
                        /> */}
                        <div className="w-32 mr-2 rounded-md relative flex items-center h-10 pl-4 pr-1.5 font-medium text-white bg-blue-600 border border-blue-600 cursor-pointer"
                            onClick={toggleList}>
                            <p className="flex-1">{isAnime ? "Manga" : "Anime"}</p>
                            <IconSwitchVertical className="w-10 h-10 p-2.5" />
                        </div>
                        <div className="w-32 mr-4 xl:mr-0 rounded-md relative flex items-center h-10 pl-4 pr-1.5 font-medium text-white bg-blue-600 border border-blue-600 cursor-pointer"
                            onClick={openSortDialog}>
                            <p className="flex-1">Sort</p>
                            <IconFilter className="w-10 h-10 p-2.5" />
                        </div>
                        {/* <div className="w-32 mr-4 xl:mr-0 rounded-md relative flex items-center h-10 pl-4 pr-1.5 text-white bg-blue-600 border border-blue-600 cursor-pointer"
                            onClick={() => { setOpen(true) }}>
                            <p className="flex-1">Filters</p>
                            <IconFilter className="w-10 h-10 p-2.5" />
                        </div> */}
                        {/* <IconFilter className="w-10 h-10 p-2 mr-4 xl:mr-0 bg-blue-600 border border-gray-300 dark:border-gray-700 rounded-md" onClick={() => { }} /> */}
                    </div>
                    <IconFilter className="block md:hidden w-14 h-14 p-4" onClick={openSortDialog} />
                    <IconSwitchVertical className="block md:hidden w-14 h-14 p-4" onClick={() => { toggleList() }} />
                </div>
            </div>
            <div className="sticky top-0 flex-none block md:hidden w-full bg-white dark:bg-gray-800 shadow z-10">
                <Tabs
                    tabs={isAnime ? Constants.ANIME_LIST_NAMES : Constants.MANGA_LIST_NAMES}
                    selected={listType}
                    onSelect={onListTypeChanged}
                    className="max-w-screen-xl mx-auto"
                />
            </div>
            <div {...swipeHandlers} className="flex-1 overflow-y-scroll">
                {/* TODO:- Check and remove AnimatePresence */}
                {/* <AnimatePresence> */}
                {initiated && <AnimatePresence initial={false} custom={direction} mode="popLayout">
                    <motion.div
                        key={`${isAnime}${listType}${sortBy}${orderBy}`}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                        //style={{ willChange }}
                        className="w-full h-full">
                        <ListView is_anime={isAnime} listType={isAnime ? Constants.ANIME_LIST_CODES[listType] : Constants.MANGA_LIST_CODES[listType]} sortBy={Constants.SORT_BY_CODES[sortBy]} orderBy={orderBy} nsfw={nsfw} />
                    </motion.div>
                </AnimatePresence>}
                {/* </AnimatePresence> */}
            </div>
            <Dialog isOpen={isOpen} onClose={closeSortDialog}>
                <div className="h-fit px-2 pt-4 pb-3">
                    <p className="px-4 mb-2 font-bold text-lg text-blue-600">Sort By</p>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[0], Constants.ORDER_BY_ASC); }}>
                        <IconSortAZ className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Title - Ascending</p>
                        {sortBy === 0 && orderBy === Constants.ORDER_BY_ASC ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[0], Constants.ORDER_BY_DESC); }}>
                        <IconSortZA className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Title - Descending</p>
                        {sortBy === 0 && orderBy === Constants.ORDER_BY_DESC ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[1], Constants.ORDER_BY_ASC); }}>
                        <IconSort09 className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Score - Ascending</p>
                        {sortBy === 1 && orderBy === Constants.ORDER_BY_ASC ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[1], Constants.ORDER_BY_DESC); }}>
                        <IconSort90 className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Score - Descending</p>
                        {sortBy === 1 && orderBy === Constants.ORDER_BY_DESC ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[2], Constants.ORDER_BY_ASC); }}>
                        <IconCalendar className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Last Updated - Ascending</p>
                        {sortBy === 2 && orderBy === Constants.ORDER_BY_ASC ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                    <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[2], Constants.ORDER_BY_DESC); }}>
                        <IconCalendar className="w-5 h-5 mr-4" />
                        <p className="flex-1 py-1 font-medium">Last Updated - Descending</p>
                        {sortBy === 2 && orderBy === Constants.ORDER_BY_DESC ? <IconCircleCheckFilled className="w-5 h-5" /> : <IconCircle className="w-5 h-5" />}
                    </div>
                </div>
            </Dialog>
            {/* <Sheet
                ref={ref}
                isOpen={false}
                onClose={() => setOpen(false)}
                detent="content-height">
                <Sheet.Container>
                    <Sheet.Header>
                        <div className="w-8 h-1 mx-auto my-4 bg-gray-200 rounded-full"></div>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="h-full px-2 pb-3">
                            <p className="px-4 mb-1 font-bold text-lg">Sort By</p>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[0]); }}>
                                <IconSortAZ className="w-5 h-5 mr-4 text-blue-600" />
                                <p className="flex-1 py-1 font-medium">Title</p>
                                {sortBy === 0 ? <IconCheck className="w-5 h-5 text-blue-600" /> : ""}
                            </div>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[1]); }}>
                                <IconSort09 className="w-5 h-5 mr-4 text-blue-600" />
                                <p className="flex-1 py-1 font-medium">Score</p>
                                {sortBy === 1 ? <IconCheck className="w-5 h-5 text-blue-600" /> : ""}
                            </div>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(Constants.SORT_BY_NAMES[2]); }}>
                                <IconCalendar className="w-5 h-5 mr-4 text-blue-600" />
                                <p className="flex-1 py-1 font-medium">Last Updates</p>
                                {sortBy === 2 ? <IconCheck className="w-5 h-5 text-blue-600" /> : ""}
                            </div>
                        </div>
                        <div className="h-full px-2 pb-3">
                            <p className="px-4 mb-1 font-bold text-lg">Sort By</p>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(sortByOptions[0]); }}>
                                <IconSortAZ className="w-7 h-7 p-1.5 mr-4 text-blue-600 bg-blue-200 rounded-full" />
                                <p className="flex-1 py-1 font-medium">Title</p>
                                {sortBy === 0 ? <IconCheck className="w-5 h-5" /> : ""}
                            </div>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(sortByOptions[1]); }}>
                                <IconSort09 className="w-7 h-7 p-1.5 mr-4 text-blue-600 bg-blue-200 rounded-full" />
                                <p className="flex-1 py-1 font-medium">Score</p>
                                {sortBy === 1 ? <IconCheck className="w-5 h-5" /> : ""}
                            </div>
                            <div className="flex items-center px-4 py-1 text-gray-600 hover:bg-gray-100 rounded-md" onClick={() => { onSortByChanged(sortByOptions[2]); }}>
                                <IconCalendar className="w-7 h-7 p-1.5 mr-4 text-blue-600 bg-blue-200 rounded-full" />
                                <p className="flex-1 py-1 font-medium">Last Updates</p>
                                {sortBy === 2 ? <IconCheck className="w-5 h-5" /> : ""}
                            </div>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => { setOpen(false) }} />
            </Sheet> */}
        </div>
    );
}
