export default function Dialog({ children, isOpen = false, onClose = () => { }, closable = true, style = {} }) {
    return (
        <div
            onClick={() => { if (closable) onClose() }}
            className={"fixed inset-0 flex items-end md:items-center justify-center z-50 duration-300 transition-all " + (isOpen ? "visible opacity-100 bg-black/[0.15] backdrop-blur-sm" : "collapse opacity-0 pointer-events-none")}>
            <div
                onClick={(e) => { e.stopPropagation() }}
                style={style}
                //style={{ maxHeight: "calc(80% - 112px)" }}
                className={"flex flex-col w-full md:w-96 h-fit max-h-[36rem] md:max-h-[36rem] bg-white rounded-t-xl rounded-b-none md:rounded-xl shadow-xl duration-300 transition-transform" + (isOpen ? " scale-100" : " md:scale-50 translate-y-full md:translate-y-0")}>
                {children}
            </div>
        </div>
    );
}